import Swal from "sweetalert2";
import alertConfig from "../constants/alertConfig";

export const successMessage = (title = "") => {
  Swal.fire({
    ...alertConfig,
    title: title ? title : alertConfig.title,
  });
};

export const errorMessage = (title = "") => {
  Swal.fire({
    ...alertConfig,
    icon: "error",
    title: title ? title : "Something went wrong, please try again later.",
  });
};

export const warningMessage = (title = "") => {
  Swal.fire({
    ...alertConfig,
    icon: "warning",
    title: title ? title : "Warning",
  });
};

export const ResetPasswordMessage = (title = "") => {
let timerInterval;
Swal.fire({
  title: "Password Reset Successfully",
  html: "Please Login Again.",
  timer: 4000,
  timerProgressBar: true,
  didOpen: () => {
    Swal.showLoading();
  },
  willClose: () => {
    clearInterval(timerInterval);
  },
}).then((result) => {
  if (result.dismiss === Swal.DismissReason.timer) {
    console.log("I was closed by the timer");
  }
});
}