import React from "react";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { Stack } from "@mui/system";
import moment from "moment/moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import image from "../../pgBannerBackground.png";
import PrintIcon from "@mui/icons-material/Print";

const StudentReportsData = ({
  successPaymentStatus,
  userDetail,
  filterStudentPdfReport,
}) => {
  const { name, mobile, email } = userDetail;
  const themeStudentData = createTheme();

  themeStudentData.typography.h3 = {
    fontSize: "16px",
    "@media (max-width:600px)": {
      fontSize: "16px",
    },

    fontFamily: ["Poppins", "sans-serif"].join(","),
  };
  themeStudentData.typography.p = {
    fontSize: "16px",
    "@media (max-width:600px)": {
      fontSize: "16px",
    },
    fontFamily: ["Poppins", "sans-serif"].join(","),
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={themeStudentData}>
        <Container maxWidth="xl" sx={{ backgroundImage: `url(${image})` }}>
          <Grid
            container
            spacing={3}
            sx={{ marginBottom: "20px", flexGrow: 1 }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              sx={{
                position: {
                  lg: "sticky",
                  md: "sticky",
                },
                top: 0,
                height: "calc(100%)",
              }}
            >
              <Card
                sx={{
                  borderBottom: "2px solid #1366ce",
                  borderRight: "2px solid #1366ce",
                }}
              >
                <Typography
                  variant="h3"
                  component="h2"
                  lineHeight="27px"
                  textAlign="center"
                  p="15px 10px 5px 10px"
                  sx={{
                    fontSize: {
                      lg: "16px",
                      md: "16px",
                      sm: "14px",
                      xs: "14px",
                    },
                    fontWeight: 600,
                  }}
                >
                  {name}
                </Typography>
                <Typography
                  variant="h3"
                  component="h2"
                  lineHeight="20px"
                  textAlign="center"
                  p="5px 10px"
                  sx={{
                    fontSize: {
                      lg: "14px",
                      md: "14px",
                      sm: "12px",
                      xs: "12px",
                    },
                    fontWeight: 500,
                  }}
                >
                  {mobile}
                </Typography>
                <Typography
                  variant="h3"
                  component="h2"
                  lineHeight="20px"
                  textAlign="center"
                  p="5px 10px"
                  sx={{
                    fontSize: {
                      lg: "14px",
                      md: "14px",
                      sm: "12px",
                      xs: "12px",
                    },
                    wordWrap: "break-word",
                    fontWeight: 500,
                  }}
                >
                  {email}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} md={8} sm={12} lg={8}>
              <Card sx={{ padding: "15px 15px" }}>
                <Typography
                  variant="h3"
                  component="h3"
                  color="#1366ce"
                  align="center"
                  mb={3}
                  sx={{
                    fontSize: { lg: "30px", md: "30px", xs: "20px" },
                    display: "inline-block",
                    borderBottom: "3px solid #ff5501",
                  }}
                >
                  Your Reports
                </Typography>
                {filterStudentPdfReport.map(
                  ({ name, createdAt, marks, rank }) => (
                    <Card
                      key={createdAt}
                      sx={{
                        padding: "15px",
                        marginBottom: "20px",
                        borderTop: "2px solid #1366ce",
                        borderLeft: "2px solid #1366ce",
                      }}
                    >
                      <Stack flexDirection={"row"}>
                        <Grid
                          item
                          xs={2}
                          sm={2}
                          md={1}
                          lg={1}
                          sx={{ marginRight: "20px" }}
                        >
                          <a
                            href={"https://api.neetnavigator.com/" + name}
                            // href={"http://localhost:8000/" + name}
                            download="Example-PDF-document.pdf"
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none" }}
                          >
                            <PrintIcon
                              sx={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                                color: "#000",
                              }}
                            />
                            <Typography
                              variant="p"
                              component="p"
                              color="red"
                              align="left"
                              mb={1}
                              sx={{
                                fontSize: {
                                  lg: "10px",
                                  md: "10px",
                                  xs: "10px",
                                },
                              }}
                            >
                              Download
                            </Typography>
                          </a>
                        </Grid>
                        <Grid container spacing={2} mt="1px" mb={1}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            sx={{ paddingTop: "8px !important" }}
                          >
                            <Typography
                              variant="p"
                              component="p"
                              color="#17a2b8"
                              align="left"
                              mb={1}
                              sx={{
                                fontSize: {
                                  lg: "16px",
                                  md: "16px",
                                  xs: "14px",
                                },
                              }}
                            >
                              <strong
                                style={{
                                  fontSize: { md: "48px", xs: "21px" },
                                  fontWeight: 600,
                                  color: "#1366ce",
                                }}
                              >
                                {marks > 0 ? "Marks :" : "Rank :"}
                              </strong>{" "}
                              {marks > 0 ? marks : rank}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            sx={{ paddingTop: "8px !important" }}
                          >
                            <Typography
                              variant="p"
                              component="p"
                              color="#17a2b8"
                              align="left"
                              mb={1}
                              sx={{
                                fontSize: {
                                  lg: "16px",
                                  md: "16px",
                                  xs: "14px",
                                },
                              }}
                            >
                              <strong
                                style={{
                                  fontSize: { md: "48px", xs: "21px" },
                                  fontWeight: 600,
                                  color: "#1366ce",
                                }}
                              >
                                Date{" "}
                              </strong>
                              {moment(createdAt).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Stack>
                      {/*<TableContainer>
                    <DataTable
                      columns={columns}
                      data={successPaymentStatus}
                      customStyles={customStyles}
                    />
                  </TableContainer>*/}
                    </Card>
                  )
                )}

                {filterStudentPdfReport.length === 0 && (
                  <Stack flexDirection={"row"}>
                    <Grid container spacing={2} mt="1px" mb={1}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        sx={{ paddingTop: "8px !important" }}
                      >
                        <Typography
                          variant="p"
                          component="p"
                          color="#FF0000"
                          align="left"
                          mb={1}
                          sx={{
                            fontSize: {
                              lg: "16px",
                              md: "16px",
                              xs: "14px",
                            },
                          }}
                        >
                          No Report to Display
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                )}
              </Card>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default StudentReportsData;
