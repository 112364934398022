import axios from "axios";
import { BASE_URL } from "../constants";
import  getHeaders from "../constants/headers";

export const createUserEnquiry = ({ name, mobile, email, enquiryType }) => {
  return axios({
    method: "post",
    url: BASE_URL + "user-enquiry",
    headers:  getHeaders(),
    data: JSON.stringify({ name, mobile, email, enquiryType }),
  });
};
