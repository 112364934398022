import { getAccessToken } from "../services/serviceToken"

const getHeaders = () => {
  const accessToken = getAccessToken();
  return {
    "app-access-token": 'Bearer ' + accessToken,
    "Content-Type": "application/json",
  }
};

export default getHeaders;
