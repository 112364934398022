import React from 'react'
import { Stack, Typography } from "@mui/material";
import Slider from "react-slick";

const SlickSlider = () => {
    var settings = {
      dots: false,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
    };

    const sliderMover = {
      padding: "0 0 0 25px",
    };

  return (
    <Slider {...settings}>
      <Stack>
        <Stack style={sliderMover}>
          <Typography>Comprehensive : </Typography>
          <Typography>
            Get an all India view of your MBBS admission possibility
          </Typography>
        </Stack>
      </Stack>
      <Stack>
        <Stack style={sliderMover}>
          <Typography>Data visualization :</Typography>
          <Typography>
            Easy-to-understand visualizations and report formats
          </Typography>
        </Stack>
      </Stack>
      <Stack>
        <Stack style={sliderMover}>
          <Typography>Predictive analytics :</Typography>
          <Typography>
            Advanced algorithms to offer most accurate prediction
          </Typography>
        </Stack>
      </Stack>
      <Stack>
        <Stack style={sliderMover}>
          <Typography>Cost Effective :</Typography>
          <Typography>
            Get started for FREE and pay only for specific reports
          </Typography>
        </Stack>
      </Stack>
    </Slider>
  );
}

export default SlickSlider