import {useState} from "react";
import image from "../../assets/sliderImg/3.svg";

let shortText = `Stop searching Google and YouTube for data and information on medical colleges, medical college cut offs and medical college fee structures. Despite wasting hours, there is >99% possibility that you may get incorrect, incomplete or outdated data.
</br></br>MBBS Lighthouse is one stop solution for all your data and information needs to expand your horizon of MBBS admission. MBBS Lighthouse offers most authentic and updated data about medical colleges, medical college cut offs and medical college fee structure.`

let longText = shortText + 
`</br></br>MBBS Lighthouse offers your MBBS admission possibility in 702 medical colleges of India. You will get a personalized MBBS admission possibility report on basis of your Domicile, Category, Quota and NEET UG Marks and NEET UG All India Rank.
</br></br>MBBS Lighthouse has in-built algorithm to map your domicile eligibility on basis of your educational details and residence address. MBBS Lighthouse estimates your MBBS admission on basis of the 2023 cut offs mapped to your NEET and State category.`

export function Expandable() {
    let [expanded, setExpanded] = useState(false);

    let text = expanded ? longText : shortText;

    return (
        <>
            <div className="px-6">
                <p className="text-sm md:text-base text-gray-600 text-left" dangerouslySetInnerHTML={{__html:text}}></p>
                <button className="text-white text-sm md:text-base lg:text-lg mt-4 border-solid rounded bg-[#1366CF] px-4 py-3 md:py-2 hover:bg-blue-600 hover:ring-2 hover:ring-blue-600 hover:ring-offset-2"
                    onClick={() => setExpanded(!expanded)}>
                        {expanded ? "Read Less ↑" : "Read More →" }
                </button>
            </div>
        </>
    )
}
export default function WhatMBBSLightHouseDo() {
    return (
        <>
            <div className="flex flex-col items-center mb-8">
                <h2 className="text-xl lg:text-4xl md:text-2xl font-semibold my-8 ml-4">MBBS Lighthouse: India’s Most Comprehensive <span className="text-[#1366CF]">MBBS College Predictor</span></h2>
                <div className="flex flex-col items-center space-y-4 lg:flex-row lg:space-x-4  lg:mx-16 mx-4">
                    <img src={image} alt="Lighthouse Banner" className="md:w-1/2"/>
                    <div className="flex items-center">
                        <Expandable />
                    </div>
                </div>
            </div>
        </>
    )
}

