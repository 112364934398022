import React, { useState } from "react";
import { getStudentId, setStudentId, getUserId, removeSelectedStateStore, removeStudentFindRankStore, removeStudentMarksStore, removeStudentRankStore, removeLoginData } from "../services/serviceToken";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  Button,
  Card,
  Container,
  Grid,
  Typography,
  List,
  ListItem,
} from "@mui/material";
import UserReview from "../components/UserReview";
import ComingSoon from "../components/ComingSoon";
import image from "../pgBannerBackground.png"; 
import VideoSection from "../components/HomePageComponents/VideoSection";
import RankReport from "../MBBS LH Sachin chaudhary Feature Medical Colleges_rank.pdf";
import MarksReport from "../MBBS LH Sachin chaudhary Feature Medical Colleges_marks.pdf"; 
import ServiceEnquiryModel from "../components/home/ServiceEnquiryModel";
import { findUser } from "../services/user";
import ProgressBar from "../components/ProgressBar/ProgressBar";

const Home = ({ populateStudentId }) => {
  const navigate = useNavigate();

  const [tcModalOpen, setTcModalOpen] = useState(false);
  const handleTcModalOpen = () => setTcModalOpen(true);

  const [eCounsellingModelOpen, setECounsellingModelOpen] = useState(false);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    var studentId = getStudentId();
    if (!studentId && populateStudentId === true) {
      const userId = getUserId();
      findUser(userId)
      .then((response) => {
        if (response.data.error || !response.data.result) {
          console.log("UserId is not valid: " + userId);
          // logout
          removeLoginData();
          navigate("/register", { replace: true });
        }
        else {
          if (response.data.result.student) {
            studentId = response.data.result.student.id;
            setStudentId(studentId);
          }
          if (!studentId) {
            navigate("/domicile", { replace: true });
          } else {
            // setECounsellingModelOpen(true);
          }
        }
      })
      .catch(() => {
        removeLoginData();
        navigate("/register", { replace: true });
      });
    } else if (!studentId) {
      navigate("/domicile", { replace: true });
    } else {
      // setECounsellingModelOpen(true);
    }
  }, []);

  const studentRank = () => {
    navigate("/student-rank");
  };
  
  const studentMarks = () => {
    navigate("/student-marks");
  };

  removeStudentMarksStore();
  removeStudentRankStore();
  removeSelectedStateStore();
  removeStudentFindRankStore();

  return (
    <Container maxWidth="xl">
      <ProgressBar/>
      <Grid
        container
        spacing={3}
        sx={{
          width: "calc(100% + 48px)",
          marginTop: "0px",
          paddingBottom: "30px",
          flexGrow: 1,
          backgroundImage: `url(${image})`,
        }}
      >
        <Grid item xs={12} md={12} textAlign="center">
          <Typography
            variant="h3"
            component="h2"
            m="15px 0px 25px 15px"
            sx={{
              fontSize: {
                lg: "30px",
                md: "30px",
                sm: "22px",
                xs: "22px",
              },
              color: "#1e439b",
              lineHeight: 1.5,
              display: "inline-block",
              borderBottom: "3px solid #ff5501",
            }}
          >
            NEET UG {new Date().getFullYear()} All India Counselling College Predictor
          </Typography>
        </Grid>
        <Grid
          sx={{
            maxWidth: "930px",
            display: {
              lg: "flex",
              md: "flex",
              sm: "content",
              xs: "content",
            },
            overflow: "hidden",
            margin: "0 auto",
            paddingBottom: "20px",
          }}
        >
          <Card
            sx={{
              margin: "15px",
              padding: "30px 30px 30px",
              float: "left",
              width: {
                lg: "465px",
                md: "465px",
              },
            }}
          >
            <Typography
              variant="h3"
              component="h2"
              lineHeight="27px"
              textAlign="center"
              m="0 0 15px"
              sx={{
                fontSize: {
                  lg: "16px",
                  md: "16px",
                  sm: "14px",
                  xs: "14px",
                },
                border: "#e5e5e5",
                backgroundColor: "#f2f2f2",
                padding: "16px",
                color: "#333333",
                fontWeight: 600,
              }}
            >
              RANK BASED PREDICTOR
            </Typography>
            <Typography
              variant="p"
              component="p"
              sx={{
                fontSize: {
                  lg: "16px",
                  md: "16px",
                  sm: "14px",
                  xs: "14px",
                },
              }}
            >
              Ideal for:
            </Typography>
            <List
              sx={{
                fontFamily: ["Poppins", "sans-serif"].join(","),
                fontSize: {
                  lg: "14px",
                  md: "14px",
                  sm: "12px",
                  xs: "12px",
                },
                listStyleType: "disc",
                pl: 1,
                ml: 1,
                "& .MuiListItem-root": {
                  display: "list-item",
                },
              }}
            >
              <ListItem sx={{ textAlign: "justify" }}>
                After NEET results are available
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Highly specific MBBS college predictor
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Includes college details such as round wise cut off and fee
                structure
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Includes data from MCC and 32 state counselling
              </ListItem>
              <br></br>
            </List>
            <Grid mt={1} textAlign="center">
              <Button
                variant="contained"
                onClick={studentRank}
                className="transform hover:scale-125 hover:z-10" 
                sx={{
                  fontSize: {
                    lg: "16px",
                    md: "16px",
                    sm: "12px",
                    xs: "12px",
                  },
                }}
              >
                SELECT RANK BASED PREDICTOR
              </Button>
            </Grid>
            <Grid mt={1} textAlign="center">
              <a
                href={RankReport}
                target="_blank"
                variant="contained"
                className="text-[#1366CF]"
                sx={{
                  fontSize: {
                    lg: "16px",
                    md: "16px",
                    sm: "12px",
                    xs: "12px",
                  },
                }}
              >
                View Sample Report
              </a>
            </Grid>
          </Card>

          <Card
            sx={{
              margin: "15px",
              padding: "30px 30px 30px",
              float: "left",
              width: {
                lg: "465px",
                md: "465px",
              },
            }}
          >
            <Typography
              variant="h3"
              component="h2"
              lineHeight="27px"
              textAlign="center"
              m="0 0 15px"
              sx={{
                fontSize: {
                  lg: "16px",
                  md: "16px",
                  sm: "14px",
                  xs: "14px",
                },
                border: "#e5e5e5",
                backgroundColor: "#f2f2f2",
                padding: "16px",
                color: "#333333",
                fontWeight: 600,
              }}
            >
              MARKS BASED PREDICTOR🔥
            </Typography>
            <Typography
              variant="p"
              component="p"
              sx={{
                fontSize: {
                  lg: "16px",
                  md: "16px",
                  sm: "14px",
                  xs: "14px",
                },
              }}
            >
              Ideal for:
            </Typography>
            <List
              sx={{
                fontFamily: ["Poppins", "sans-serif"].join(","),
                fontSize: {
                  lg: "14px",
                  md: "14px",
                  sm: "12px",
                  xs: "12px",
                },
                listStyleType: "disc",
                pl: 1,
                ml: 1,
                "& .MuiListItem-root": {
                  display: "list-item",
                },
              }}
            >
              <ListItem sx={{ textAlign: "justify" }}>
                Pre and Post NEET MBBS admission possibility estimation
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Includes NEET rank predictor
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Includes option to try possibility for multiple marks
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Not as specific and comprehensive as Rank based predictor
              </ListItem>
            </List>
            <Grid item mt={1} textAlign="center">
              <Button
              disabled
                variant="contained"
                onClick={studentMarks}
                // onClick={studentRank}
                className="transform hover:scale-125 hover:z-10" 
                sx={{
                  fontSize: {
                    lg: "16px",
                    md: "16px",
                    sm: "12px",
                    xs: "12px",
                  },
                }}
              >
                SELECT MARKS BASED PREDICTOR
              </Button>
            </Grid>
            <Grid mt={1} textAlign="center">
              <a
                href={MarksReport}
                target="_blank"
                variant="contained"
                className="text-[#1366CF]"
                sx={{
                  fontSize: {
                    lg: "16px",
                    md: "16px",
                    sm: "12px",
                    xs: "12px",
                  },
                }}
              >
                View Sample Report
              </a>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        lg={8}
        sx={{
          margin: {
            lg: "48px",
            md: "48px",
            sm: "30px",
            xs: "30px",
          },
        }}
      >
        <Typography
          variant="h3"
          component="h2"
          lineHeight="27px"
          mb={4}
          sx={{
            fontSize: {
              lg: "30px",
              md: "30px",
              sm: "19px",
              xs: "19px",
            },
          }}
        >
          <span
            style={{
              fontSize: { md: "48px", xs: "21px" },
              fontWeight: 500,
              color: "#1366ce",
            }}
          >
            Myopia{" "}
          </span>
          in Medical Admissions
        </Typography>
        <Typography
          variant="h3"
          component="h2"
          textAlign="justify"
          lineHeight="33px"
          mb={4}
          sx={{
            fontSize: {
              lg: "18px",
              md: "18px",
              sm: "14px",
              xs: "14px",
            },
          }}
        >
          In India, there are more than 700 medical colleges, however students
          and parents have a narrow perspective on their medical admissions.
          People underestimate their chances of admission to medical schools
          outside of their city or state.
        </Typography>
        <Typography
          variant="h3"
          component="h2"
          textAlign="justify"
          lineHeight="33px"
          sx={{
            fontSize: {
              lg: "18px",
              md: "18px",
              sm: "14px",
              xs: "14px",
            },
          }}
        >
          {" "}
          In order for a student to get admitted to The Best Medical College
          based on AIR, Domicile, Category, and Fee Budget, MBBS Lighthouse
          delivers the most thorough and authentic intelligence on medical
          admissions.
        </Typography>
      </Grid>
      <VideoSection />
      <UserReview />
      <ComingSoon tcModalOpen={tcModalOpen} setTcModalOpen={setTcModalOpen} />      
      {eCounsellingModelOpen && (<ServiceEnquiryModel
                modalOpen={eCounsellingModelOpen}
                setModalOpen={setECounsellingModelOpen}
                price={"9,999"}
                packageName={"Interested in E-Counselling Services, Starting "}
                successPath={"/"}
                noRedirect={true}
            />)}
    </Container>
  );
};

export default Home;
