import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useEffect, useState } from "react";
import CollegesHeading from "../components/HomePageComponents/CollegesHeading";
import CollegeGraph from "./CollegeGraph";
import { getUserId } from "../services/serviceToken";
import { findUser } from "../services/user";
import { Link, useNavigate } from "react-router-dom";
import { getCollegeCountByInstitutionType } from "../services/colleges";
import image from "../pgBannerBackground.png"; 
import { getCountByRank } from "../services/colleges";
import ProgressBar from "../components/ProgressBar/ProgressBar";

const StudentRank = () => {
  const navigate = useNavigate();
  const [rank, setRank] = useState("");
  const [collegeDataCount, setCollegeDataCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    mobile: "",
    student: {
      gender: "",
      stateId12th: "",
      stateId10th: "",
      isPhCandidate: "",
      areaId: "",
      quotaId: "",
      armedForceId: "",
      minorityId: "",
      neetCategoryId: "",
      stateCategoryId: "",
      area: { area: "" },
      quota: { quota: "" },
      state10th: { name: "" },
      state12th: { name: "" },
      minority: { minority: "" },
      armed_force: { armedForce: "" },
      neet_category: { category: "" },
      state_category: { category: "" },
      address: {
        address: "",
        pincode: "",
        stateId: "",
        cityId: "",
        state: { name: "" },
        city: { name: "" },
      },
    },
  });

   const [errorRank, setErrorRank] = useState(false);
   const [errorHelperTextRank, setErrorHelperTextRank] = useState("");

  const StudentName =
    userData.name.charAt(0).toUpperCase() + userData.name.slice(1);

  const userId = getUserId();
  useEffect(() => {
    async function getUserData() {
      findUser(userId)
        .then((response) => {
          setUserData(response.data.result);
        })
        .catch((err) => {
          console.error("error", err);
        });
    }
    getUserData();
  }, [userId]);

  let isPhCandidate = "";
  if (
    userData.student.isPhCandidate &&
    userData.student.isPhCandidate === true
  ) {
    isPhCandidate = "1";
  } else if (userData.student.isPhCandidate === false) {
    isPhCandidate = "0";
  }

  const [collegeDomicileCounts, setCollegeDomicileCounts] = useState({
    govtColleges: 0,
    privateColleges: 0,
    deemedColleges: 0,
  });

  async function getCollegeGraphData() {
    setLoader(true);
    getCollegeCountByInstitutionType()
      .then((response) => {
        if (!response.data.error) {
          const totalColleges =  handleCollegeGraphDomicile(response.data.result);
          setCollegeDataCount(totalColleges);
        }
      })
      .catch((err) => {
        console.error("error", err);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  useEffect(() => {
     window.scrollTo(0, 0);
    getCollegeGraphData();
  }, []);

  const handleCollegeGraphDomicile = (collegeCountByInstituteType) => {
    const updatedCollegeCounts = {
      govtColleges: 0,
      privateColleges: 0,
      deemedColleges: 0,
    };
    collegeCountByInstituteType.forEach((item) => {
      switch (item.institutionTypeId) {
        case 1:
          updatedCollegeCounts.govtColleges += item.count;
          break;
        case 2:
          updatedCollegeCounts.privateColleges += item.count;
          break;
        case 3:
          updatedCollegeCounts.deemedColleges += item.count;
          break;
        default:
          break;
      }
    });
    setCollegeDomicileCounts(updatedCollegeCounts);
    return updatedCollegeCounts.govtColleges + updatedCollegeCounts.privateColleges + updatedCollegeCounts.deemedColleges;
  };

  const quotaValue =
    userData.student.quota === null ? "NA" : userData.student.quota.quota;
  const minorityValue =
    userData.student.minority === null
      ? "NA"
      : userData.student.minority.minority;

  const validateRank = () => {
    if (!rank || rank <=0) {
      setErrorRank(true);
      setErrorHelperTextRank("Please Enter your All India Rank");
    } else if (rank > 1350000) {
      setErrorRank(true);
      setErrorHelperTextRank("Enter Rank is more than NEET Limit");
    } else if (
      rank > 1117071 &&
      /^(General|EWS)$/.test(userData.student.neet_category.category) &&
      userData.student.isPhCandidate === true
    ) {
      setErrorRank(true);
      setErrorHelperTextRank(
        // "As per your NEET Category, Rank should be less than 962533"
        "Rank entered are greater than your category cut off for " + (new Date().getFullYear()-1)
      );
    } else if (
      rank > 1014372 &&
      /^(General|EWS)$/.test(userData.student.neet_category.category) &&
      userData.student.isPhCandidate === false
    ) {
      setErrorRank(true);
      setErrorHelperTextRank(
        // "As per your NEET Category, Rank should be less than 881058"
        "Rank entered are greater than your category cut off for " + (new Date().getFullYear()-1)
      );
    } else if (
      rank > 1222175 &&
      /^(OBC|SC|ST)$/.test(userData.student.neet_category.category)
    ) {
      setErrorRank(true);
      setErrorHelperTextRank(
        // "As per your NEET Category Rank should be less than 1100000"
        "Rank entered are greater than your category cut off for " + (new Date().getFullYear()-1)
      );
    } else {
      setErrorRank(false);
      setErrorHelperTextRank("");
      return true;
    }
    return false;
  }

  const handleRankEntered = async () => {
    if (validateRank() === true) {
      getCountByRank(rank)
      .then((response) => {
        if (!response.data.error) {
          const totalColleges =  handleCollegeGraph(response.data.result);
          setCollegeDataCount(totalColleges);
        }
      })
      .catch((err) => {
        console.log(err.errors.message);
      })
      .finally(() => {
        setLoader(false);
      })
    }
  };

  const handleCollegeGraph = (collegeCountByInstituteType) => {
    const updatedCollegeCounts = {
      govtColleges: 0,
      privateColleges: 0,
      deemedColleges: 0,
    };
    collegeCountByInstituteType.forEach((item) => {
      switch (item.college.institutionTypeId) {
        case 1:
          updatedCollegeCounts.govtColleges += item.count;
          break;
        case 2:
          updatedCollegeCounts.privateColleges += item.count;
          break;
        case 3:
          updatedCollegeCounts.deemedColleges += item.count;
          break;
        default:
          break;
      }
    });
    setCollegeDomicileCounts(updatedCollegeCounts);
    return updatedCollegeCounts.govtColleges + updatedCollegeCounts.privateColleges + updatedCollegeCounts.deemedColleges;
  };

  const handleRankSubmit = async (event) => {
    event.preventDefault();
    if (validateRank() === true) {
      navigate("/student-profile", { state: { rank: rank } });
    }
  };

  return (
    <Container maxWidth="xl" sx={{ backgroundImage: `url(${image})` }}>
   <ProgressBar/>
      <Grid container spacing={3} sx={{ flexGrow: 1 }}>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            paddingLeft: "0px",
          }}
        >
          <CollegesHeading collegeDataCount={collegeDataCount} />
          <Grid
            item
            xs={0}
            md={4}
            sx={{ position: "sticky", top: 0, height: 530 }}
          >
            {loader ? (
              <Box
                component="img"
                src="/image/ezgif.com-resize.gif"
                alt=""
                sx={{
                  height: {
                    lg: "500px",
                    md: "500px",
                    sm: "400px",
                    xs: "400px",
                  },
                  width: "auto",
                }}
              />
            ) : (
              <CollegeGraph
                graphData={[
                  collegeDomicileCounts.govtColleges,
                  collegeDomicileCounts.privateColleges,
                  collegeDomicileCounts.deemedColleges,
                ]}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <Card
            sx={{
              minWidth: 275,
              borderTop: "2px solid #1366ce",
              borderLeft: "2px solid #1366ce",
            }}
          >
            <CardContent>
              <Stack
                className="college-form-heading"
                display="inline-block"
                spacing={2}
                mb={2}
              >
                <Typography
                  variant="h3"
                  component="h2"
                  sx={{
                    fontSize: { md: "30px", xs: "20px" },
                    fontWeight: 500,
                    color: "#1e439b",
                    lineHeight: 1.5,
                    display: "inline-block",
                    borderBottom: "3px solid #ff5501",
                  }}
                >
                  Your Domicile
                </Typography>
              </Stack>
              <Grid container spacing={2} mt={1} mb={1}>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    {StudentName}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    12th Passing State
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    {userData.student.state12th.name}
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    10th Passing State
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    {userData.student.state10th.name}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Domicile State
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    {userData.student.address.state.name}
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    NEET Category
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    {userData.student.neet_category.category}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    State Category
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    {userData.student.state_category.category}
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Minority
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    {minorityValue}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Quota
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    {quotaValue}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card
            sx={{
              minWidth: 275,
              marginTop: 3,
              borderBottom: "2px solid #1366ce",
              borderRight: "2px solid #1366ce",
            }}
          >
            <CardContent>
              <Stack
                className="college-form-heading"
                display="inline-block"
                spacing={2}
                mb={2}
              >
                <Typography
                  variant="h3"
                  component="h2"
                  sx={{
                    fontSize: { md: "30px", xs: "20px" },
                    fontWeight: 500,
                    color: "#1e439b",
                    lineHeight: 1.5,
                    display: "inline-block",
                    borderBottom: "3px solid #ff5501",
                  }}
                >
                  Enter your All India Rank
                </Typography>
              </Stack>
              <Box
                component="form"
                noValidate
                onSubmit={handleRankSubmit}
                sx={{ mt: 3 }}
              >
                <Stack mt={1} mb={2}>
                  <TextField
                    autoComplete="given-name"
                    name="studentMarks"
                    id="studentMarks"
                    label="Enter your All India Rank"
                    type="number"
                    fullWidth
                    required
                    value={rank}
                    error={errorRank}
                    helperText={errorHelperTextRank}
                    onChange={(e) => setRank(e.target.value)}
                    onBlur={handleRankEntered}
                    variant="outlined"
                    inputProps={{ maxLength: 3 }}
                  />
                </Stack>
                <Stack md={12} sm={12} lg={12} xs={12}>
                  <Button
                    type="submit"
                    className="search-btn"
                    sx={{
                      fontSize: "25px",
                      padding: "22px",
                      border: "1px solid #1366ce",
                      outline: "none",
                      cursor: "pointer",
                      mt: 2,
                      mb: 4,
                      color: "#fff",
                      backgroundColor: "#1366ce",
                      height: "50px",
                    }}
                  >
                    Next
                  </Button>
                </Stack>
              </Box>
            </CardContent>
          </Card>
          <Typography
            variant="p"
            component="p"
            mt={3}
            mb={1}
            sx={{ fontSize: "16px", fontWeight: "500", fontStyle: "italic" }}
          >
            To Edit Your Domicile And Category In Student Section{" "}
            <Link
              style={{ color: "#1f75cb", textDecoration: "none" }}
              to={"/student-profile"}
            >
              Click Here
            </Link>
          </Typography>
        </Grid>
      </Grid>
      {/*<Grid container spacing={3} sx={{ flexGrow: 1 }}>
        <CollegeList />
        <CheckoutForm />
              </Grid>*/}
    </Container>
  );
};

export default StudentRank;
