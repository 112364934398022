import React from 'react'
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";

  const tiers = [
    {
      title: "Expanded Horizon",
      content: "MBBS admission possibility estimation for 702 medical colleges",
      img: "/image/expand your horizion.png",
    },
    {
      title: "Enhanced Possibilities",
      content:
        "MBBS Lighthouse eliminates the guess work from MBBS admission planning",
      img: "/image/enhanced posibilities.png",
    },
    {
      title: "Competitive Advantage",
      content:
        "Puts you in control of the medical admission counselling process",
      img: "/image/compatitive advantanges.png",
    },
    {
      title: "Stress Reduction",
      content:
        "MBBS Lighthouse offers right motivation and psychological safety",
      img: "/image/safety.png",
    },
  ];

const FourImageCard = () => {
  return (
    <Container maxWidth="lx" component="main">
      <Typography
        variant="h4"
        color="inherit"
        sx={{
          textAlign: "center",
          fontSize: { lg: "33px", md: "33px", xs: "20px" },
          fontWeight: 700,
        }}
        mt={5}
      >
        MBBS Lighthouse Benefits
      </Typography>
      <Grid container spacing={5} alignItems="flex-end" mt={2} mb={10}>
        {tiers.map((tier) => (
          // Enterprise card is full width at sm breakpoint
          <Grid
            item
            key={tier.title}
            // mt={5}
            // mb={15}
            xs={12}
            sm={tier.title === "Enterprise" ? 12 : 6}
            md={3}
          >
            <Card sx={{ boxShadow: "none" }}>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    mb: 2,
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      // 16:9
                      pt: "10px",
                    }}
                    image={tier.img}
                    alt="random"
                  />
                </Box>
              </CardContent>
              <CardActions>
                <Typography
                  sx={{ color: "#052F5F", fontSize: "18px" }}
                  variant="h6"
                  component="h6"
                >
                  {tier.title}
                </Typography>
              </CardActions>
              <CardActions>
                <Typography
                  sx={{ fontSize: "14px" }}
                  textAlign="justify"
                  variant="p"
                >
                  {tier.content}
                </Typography>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default FourImageCard