import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';

const CollegesHeading = ({collegeDataCount}) => {

  return (
    <>
      <Grid item xs={12} md={12}>
        <Stack sx={{ textAlign: "center" }}>
          <Typography
            className="CollegeCount"
            variant="h4"
            component="h1"
            mt={1}
            sx={{
              fontSize: { md: "42px", xs: "21px" },
              fontWeight: 600,
              color: "red",
            }}
          >
            {collegeDataCount}
          </Typography>
          <Typography
            variant="h4"
            component="h2"
            mt={1}
            sx={{ fontSize: { lg: "38px", md: "38px", xs: "21px", sm: "21px" }, fontWeight: 600 }}
          >
            Medical Colleges in{" "}
            <strong
              sx={{
                fontSize: { md: "48px", xs: "21px" },
                fontWeight: 600,
                color: "#1366ce",
              }}
            >
              India
            </strong>
          </Typography>
        </Stack>
      </Grid>
      {/*<Grid item xs={12} md={12}>
        <Stack className="college-heading" spacing={2}>
          <Typography
            variant="h2"
            component="h1"
            mt={1}
            sx={{
              fontSize: { md: "26px", xs: "16px" },
              fontWeight: 600,
              color: "#858484",
            }}
          >
            Service - Free predictor
          </Typography>
          <Typography
            variant="h2"
            component="h2"
            mt={1}
            sx={{ fontSize: { md: "25px", xs: "16px" }, fontWeight: 600 }}
          >
            You need to make sure that your CRS score is above the minimum
            points score of your <Link href="#">round of invitations</Link>.
          </Typography>
        </Stack>
          </Grid>*/}
    </>
  );
};

export default CollegesHeading