import horizon from "../../assets/benefitsOfMBBS/expandHorizon.svg";
import possibilities from "../../assets/benefitsOfMBBS/enhancedPossibilities.svg";
import competitive from "../../assets/benefitsOfMBBS/competitiveAdvantage.svg";
import stress from "../../assets/benefitsOfMBBS/stressReduction.svg";

const BenefitCard = ({svgImage, title, description}) => {
    return (
        <div
            className="w-64 bg-white rounded-xl shadow-md p-4 flex flex-wrap flex-col items-center transition-transform duration-300 transform hover:scale-125 hover:z-10">
            <img src={svgImage} alt={title}/>
            <h3 className="text-lg md:text-xl font-semibold my-2 text-pretty text-center">{title}</h3>
            <p className={`text-sm md:text-base text-gray-600 text-center ${title=="Notices and Updates"? "pt-7":""}`}>{description}</p>
        </div>
    );
};

const BenefitsOfMBBSCounselling = () => {
    const benefits = [
        {svgImage: horizon, title: 'Personalized Admission Strategy', description: 'Based on domicile, category, quota, fee budget, state/college preference and NEET UG score.'},
        {svgImage: possibilities, title: 'Notices and Updates', description: 'You won’t miss any counselling notification and at the same time, you will be secured from irrelevant notices.'},
        {svgImage: competitive, title: 'Digital Document Library', description: 'A secure and personal online document library to allow you freedom to participate in counselling from anywhere.'},
        {svgImage: stress, title: 'Decision Support System', description: 'With or without mentors, you can always be sure of most authentic, accurate and ethical decision support system.'},
    ];

    return (
        <div className="bg-[#eaf1ff] flex flex-col items-center pb-14 md:px-6 lg:px-16">
            <h2 className="text-xl lg:text-4xl md:text-2xl font-semibold mt-8 mb-12 ml-4">Benefits of <span
                className="text-[#1366CF]">MBBS Admission Counselling</span></h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
                {benefits.map((benefit, index) => (
                    <BenefitCard key={index} svgImage={benefit.svgImage} title={benefit.title}
                                 description={benefit.description}/>
                ))}
            </div>
        </div>
    );
};

export default BenefitsOfMBBSCounselling;
