import React from 'react'
import { Box, Button, Fade, Grid, List, ListItem, Modal, styled, Typography } from "@mui/material";
import { Stack } from '@mui/system';

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "600px", md: "600px", sm: "300px", xs: "300px" },
  height: { lg: "400px", md: "400px", sm: "550px", xs: "550px" },
  overflow: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 1.5,
 
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "0em"
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent"
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: "1em"
  }
};

const TermConditionModal = ({ tcModalOpen, setTcModalOpen }) => {
  const handleTcModalClose = () => setTcModalOpen(false);

  return (
    <React.Fragment>
      {
        <StyledModal
          open={tcModalOpen}
          onClose={() => {}}
          closeAfterTransition={false}
        >
          <Fade in={tcModalOpen}>
            <Box sx={style}>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="h3"
                  component="h2"
                  m="15px 0px 0px 15px"
                  sx={{
                    fontSize: {
                      lg: "22px",
                      md: "22px",
                      sm: "17px",
                      xs: "17px",
                    },
                    color: "#1e439b",
                    lineHeight: 1.5,
                    textAlign: "center",
                    display: "inline-block",
                    borderBottom: "3px solid #ff5501",
                  }}
                >
                  Terms & Conditions
                </Typography>

                <List
                  sx={{
                    fontFamily: ["Poppins", "sans-serif"].join(","),
                    fontSize: {
                      lg: "14px",
                      md: "14px",
                      sm: "12px",
                      xs: "12px",
                    },
                    listStyleType: "disc",
                    pl: 1,
                    ml: 1,
                    "& .MuiListItem-root": {
                      display: "list-item",
                    },
                  }}
                >
                  <ListItem sx={{ textAlign: "justify" }}>
                    NEET Navigator Educational Services is a private business
                    entity incorporated as sole proprietor firm in 2022
                    registered at New Delhi. NEET Navigator is in service
                    business of providing consultancy and advisory services
                    mainly related to education, counselling and medical
                    admissions.
                  </ListItem>
                  <ListItem sx={{ textAlign: "justify" }}>
                    NEET Navigator Educational Services has NO liaison with any
                    medical college or management of any medical college. NEET
                    Navigator Educational Services is NOT a government agency
                    and doesn’t make any claim of influence on any counselling
                    process.
                  </ListItem>
                  <ListItem sx={{ textAlign: "justify" }}>
                    NEET Navigator Educational Services is represented in market
                    place through two service names, NEET Navigator and MBBS
                    Lighthouse. NEET Navigator Educational Services has already
                    filed for trademark registration of these services.
                  </ListItem>
                  <ListItem sx={{ textAlign: "justify" }}>
                    NEET Navigator owns an online web application MBBS
                    Lighthouse which is an advanced data analytics based medical
                    admission possibility predictor. MBBS Lighthouse is an
                    online service offering of NEET Navigator.
                  </ListItem>
                  <ListItem sx={{ textAlign: "justify" }}>
                    MBBS Lighthouse is designed on historical medical admission
                    data. For {new Date().getFullYear()} predictions, MBBS
                    Lighthouse analyzes counselling data of year{" "}
                    {new Date().getFullYear() - 1}. All the analytical reports
                    are technology driven and based on entered user data.
                  </ListItem>
                  <ListItem sx={{ textAlign: "justify" }}>
                    MBBS Lighthouse is currently developed on admission
                    counselling data of MCC and 32 Indian states. The analytics
                    and reports are auto-calculated on the basis of user data
                    including NEET score, domicile, category and fee budget etc.
                  </ListItem>
                  <ListItem sx={{ textAlign: "justify" }}>
                    The user data is stored in the encrypted formats and no
                    person or agency including NEET Navigator has access to
                    modify or delete the user’s data. The user data and
                    credentials are stored on secured Google cloud servers.
                  </ListItem>
                  <ListItem sx={{ textAlign: "justify" }}>
                    MBBS Lighthouse offers customized reports and analytics
                    based on a specific user’s credentials such as domicile,
                    category and NEET score. The reports and analytics are not
                    generalized. Therefore, generated report content is not
                    applicable to any other applicant.
                  </ListItem>
                  <ListItem sx={{ textAlign: "justify" }}>
                    The reports and analytics of MBBS Lighthouse shall be used
                    only as one input for planning of admission strategy. MBBS
                    Lighthouse does not claim or offer any directions for
                    medical admission counselling process.
                  </ListItem>
                  <ListItem sx={{ textAlign: "justify" }}>
                    MBBS Lighthouse or NEET Navigator shall not be responsible
                    for any loss of opportunity due to any of the reports and
                    analytics. The user is advised to apply due diligence on the
                    analysis of reports generated by MBBS Lighthouse.
                  </ListItem>
                  <ListItem sx={{ textAlign: "justify" }}>
                    MBBS Lighthouse, at present, estimates the admission
                    possibility in MBBS for 702 medical colleges. NEET Navigator
                    is making continuous efforts to incorporate the admission
                    data of remaining medical colleges and other courses as
                    well.
                  </ListItem>
                  <ListItem sx={{ textAlign: "justify" }}>
                    MBBS Lighthouse is designed on curated data of medical
                    admissions. It is practically impossible to access and
                    incorporate all the data entries into the application. MBBS
                    Lighthouse is curated to include approx 90% of admission
                    counselling data for {new Date().getFullYear() - 1}.
                  </ListItem>
                  <ListItem sx={{ textAlign: "justify" }}>
                    MBBS Lighthouse reports and predictions shall be considered
                    as indicative and not final. The reports are directional in
                    nature. NEET Navigator or MBBS Lighthouse claims no
                    guarantee or assurance of medical admission.
                  </ListItem>
                  <ListItem sx={{ textAlign: "justify" }}>
                    Use of NEET Navigator and MBBS Lighthouse is completely
                    voluntary in nature. NEET Navigator Educational Services
                    ensures due diligence in all the promotional communications.
                    However, any reference to threat, fear or coercion is
                    unintentional and inadvertent. NEET Navigator Educational
                    Services shall not be liable for any such action.
                  </ListItem>
                  <ListItem sx={{ textAlign: "justify" }}>
                    The material and information contained on this website is
                    for general information purposes only. You should not rely
                    upon the material or information on the website as a basis
                    for making any business, legal or any other decisions.
                    Whilst we endeavour to keep the information up to date and
                    correct. Neet Navigator makes no representation or
                    warranties of any kind, express or implied about the
                    completeness, accuracy, reliability, suitability or
                    availability with respect to the website or the information,
                    product, service or related graphics/ data contained on the
                    website for any purpose. Any reliance you place on such
                    material is therefore strictly at your own risk.
                  </ListItem>
                </List>
              </Grid>
              <Stack sx={{ float: "right" }}>
                <Button variant="contained" onClick={handleTcModalClose}>
                  Agree
                </Button>
              </Stack>
            </Box>
          </Fade>
        </StyledModal>
      }
    </React.Fragment>
  );
};

export default TermConditionModal