import { createSlice } from '@reduxjs/toolkit'
import { getAccessToken } from '../services/serviceToken'

const initialState = {
    accessToken: getAccessToken(),
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, action) => {
            state.accessToken = action.payload
        },
        clearToken: (state) => {
            state.accessToken = null
        },
    },
});

export const { setToken, clearToken } = authSlice.actions;

export default authSlice.reducer

