import React from 'react'
import { Button, Card, Container, Grid, List, ListItem, Typography } from '@mui/material';
import image from "../pgBannerBackground.png"; 
import RankReport from "../MBBS LH Sachin chaudhary Feature Medical Colleges_rank.pdf";
import MarksReport from "../MBBS LH Sachin chaudhary Feature Medical Colleges_marks.pdf"; 

const SampleReport = () => {
  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
        sx={{
          width: "calc(100% + 48px)",
          marginTop: "0px",
          paddingBottom: "30px",
          flexGrow: 1,
          backgroundImage: `url(${image})`,
        }}
      >
        <Grid item xs={12} md={12} textAlign="center">
          <Typography
            variant="h3"
            component="h2"
            m="15px 0px 25px 15px"
            sx={{
              fontSize: {
                lg: "30px",
                md: "30px",
                sm: "22px",
                xs: "22px",
              },
              color: "#1e439b",
              lineHeight: 1.5,
              display: "inline-block",
              borderBottom: "3px solid #ff5501",
            }}
          >
            Sample Report
          </Typography>
        </Grid>
        <Grid
          sx={{
            maxWidth: "930px",
            display: {
              lg: "flex",
              md: "flex",
              sm: "content",
              xs: "content",
            },
            overflow: "hidden",
            margin: "0 auto",
            paddingBottom: "20px",
          }}
        >
          <Card
            sx={{
              margin: "15px",
              padding: "30px 30px 30px",
              float: "left",
              width: {
                lg: "465px",
                md: "465px",
              },
            }}
          >
            <Typography
              variant="h3"
              component="h2"
              lineHeight="27px"
              textAlign="center"
              m="0 0 15px"
              sx={{
                fontSize: {
                  lg: "16px",
                  md: "16px",
                  sm: "14px",
                  xs: "14px",
                },
                border: "#e5e5e5",
                backgroundColor: "#f2f2f2",
                padding: "16px",
                color: "#333333",
                fontWeight: 600,
              }}
            >
              NEET UG {new Date().getFullYear()} All India Counselling College
              Predictor
            </Typography>
            <Typography
              variant="p"
              component="p"
              sx={{
                fontSize: {
                  lg: "16px",
                  md: "16px",
                  sm: "14px",
                  xs: "14px",
                },
              }}
            >
              Ideal for:
            </Typography>

            <List
              sx={{
                fontFamily: ["Poppins", "sans-serif"].join(","),
                fontSize: {
                  lg: "14px",
                  md: "14px",
                  sm: "12px",
                  xs: "12px",
                },
                listStyleType: "disc",
                pl: 1,
                ml: 1,
                "& .MuiListItem-root": {
                  display: "list-item",
                },
              }}
            >
              <ListItem sx={{ textAlign: "justify" }}>
                After NEET results are available
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Highly specific MBBS college predictor
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Includes college details such as round wise cut off and fee
                structure
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Includes data from MCC and 32 state counselling
              </ListItem>
              <br></br>
            </List>
            <Grid mt={1} textAlign="center">
              <Button
                href={RankReport}
                target="_blank"
                variant="contained"
                sx={{
                  fontSize: {
                    lg: "16px",
                    md: "16px",
                    sm: "12px",
                    xs: "12px",
                  },
                }}
              >
                Rank Based Predictor Sample Report
              </Button>
            </Grid>
          </Card>

          <Card
            sx={{
              margin: "15px",
              padding: "30px 30px 30px",
              float: "left",
              width: {
                lg: "465px",
                md: "465px",
              },
            }}
          >
            <Typography
              variant="h3"
              component="h2"
              lineHeight="27px"
              textAlign="center"
              m="0 0 15px"
              sx={{
                fontSize: {
                  lg: "16px",
                  md: "16px",
                  sm: "14px",
                  xs: "14px",
                },
                border: "#e5e5e5",
                backgroundColor: "#f2f2f2",
                padding: "16px",
                color: "#333333",
                fontWeight: 600,
              }}
            >
              NEET UG {new Date().getFullYear()} All India Counselling College
              Predictor
            </Typography>
            <Typography
              variant="p"
              component="p"
              sx={{
                fontSize: {
                  lg: "16px",
                  md: "16px",
                  sm: "14px",
                  xs: "14px",
                },
              }}
            >
              Ideal for:
            </Typography>
            <List
              sx={{
                fontFamily: ["Poppins", "sans-serif"].join(","),
                fontSize: {
                  lg: "14px",
                  md: "14px",
                  sm: "12px",
                  xs: "12px",
                },
                listStyleType: "disc",
                pl: 1,
                ml: 1,
                "& .MuiListItem-root": {
                  display: "list-item",
                },
              }}
            >
              <ListItem sx={{ textAlign: "justify" }}>
                Pre and Post NEET MBBS admission possibility estimation
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Includes NEET rank predictor
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Includes option to try possibility for multiple marks
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Not as specific and comprehensive as Rank based predictor
              </ListItem>
            </List>
            <Grid item mt={1} textAlign="center">
              <Button
                href={MarksReport}
                target="_blank"
                variant="contained"
                sx={{
                  fontSize: {
                    lg: "16px",
                    md: "16px",
                    sm: "12px",
                    xs: "12px",
                  },
                }}
              >
                Marks Based PRedictor Sample Report
              </Button>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SampleReport