import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { clearToken } from "../redux/authSlice";
import { removeUserData } from "../utils/localStorage";

export default function Logout() {
    const accessToken = useSelector((state) => state.auth.accessToken);
    const dispatch = useDispatch();
    
    useEffect(() => {
        removeUserData();
        dispatch(clearToken());
    }, []);

    return !accessToken ? (
        <Navigate to={'/login'} replace />
    ) : <p>You are being logout</p>;
};
