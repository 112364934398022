import React from 'react'
import { Popover, Typography } from '@mui/material';
import InfoIcon from "@mui/icons-material/Info";

export const FullNameInfo = () => {
    const [fullNameInfo, setFullNameInfo] = React.useState(null);
    const handlePopoverOpen = (event) => {
      setFullNameInfo(event.currentTarget);
    };
    const handlePopoverClose = () => {
      setFullNameInfo(null);
    };
    const open = Boolean(fullNameInfo);
  return (
    <div className="info-tag">
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <InfoIcon color="primary" />
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={fullNameInfo}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography
          sx={{
            padding: `5px`,
            fontSize: 12,
            color: "#419df2",
            bgcolor: "#FFFAFB",
          }}
          variant="p"
          component="p"
        >
          FullName Instructions
          <li>
            Full Name must be greater than 2 alphabets and less than 40
            alphabets
          </li>
          <li>Number is Not Applicable</li>
        </Typography>
      </Popover>
    </div>
  );
}

export const MobileInfo = () => {
  const [mobileInfo, setMobileInfo] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setMobileInfo(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setMobileInfo(null);
  };
  const open = Boolean(mobileInfo);
  return (
    <div className="info-tag">
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <InfoIcon color="primary" />
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={mobileInfo}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography
          sx={{
            padding: `5px`,
            fontSize: 12,
            color: "#419df2",
            bgcolor: "#FFFAFB",
          }}
          variant="p"
          component="p"
        >
          Mobile Number Instructions
          <li>Number should be 10 digit</li>
          <li>No alphabet is allow</li>
          <li>Don't Enter country code</li>
        </Typography>
      </Popover>
    </div>
  );
};

export const EmailInfo = () => {
  const [emailInfo, setEmailInfo] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setEmailInfo(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setEmailInfo(null);
  };
  const open = Boolean(emailInfo);
  return (
    <div className="info-tag">
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <InfoIcon color="primary" />
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={emailInfo}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography
          sx={{
            padding: `5px`,
            fontSize: 12,
            color: "#419df2",
            bgcolor: "#FFFAFB",
          }}
          variant="p"
          component="p"
        >
          Email Id Instructions
          <li>Email Must has @</li>
        </Typography>
      </Popover>
    </div>
  );
};

export const PasswordInfo = () => {
  const [passwordInfo, setPasswordInfo] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setPasswordInfo(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setPasswordInfo(null);
  };
  const open = Boolean(passwordInfo);
  return (
    <div className="info-tag">
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <InfoIcon color="primary" />
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={passwordInfo}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography
          sx={{
            padding: `5px`,
            fontSize: 12,
            color: "#419df2",
            bgcolor: "#FFFAFB",
          }}
          variant="p"
          component="p"
        >
          Password Instructions
          <li>Password Must has be 6 letter long</li>
        </Typography>
      </Popover>
    </div>
  );
};

export const OtpInfo = () => {
  const [otpInfo, setOtpInfo] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setOtpInfo(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setOtpInfo(null);
  };
  const open = Boolean(otpInfo);
  return (
    <div className="info-tag">
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <InfoIcon color="primary" />
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={otpInfo}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography
          sx={{
            padding: `5px`,
            fontSize: 12,
            color: "#419df2",
            bgcolor: "#FFFAFB",
          }}
          variant="p"
          component="p"
        >
          OTP Number Instructions
          <li>Number should be 6 digit</li>
          <li>No alphabet is allow</li>
          <li>Don't Enter Invalid Otp</li>
        </Typography>
      </Popover>
    </div>
  );
};

export const UsernameInfo = () => {
  const [usernameInfo, setUsernameInfo] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setUsernameInfo(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setUsernameInfo(null);
  };
  const open = Boolean(usernameInfo);
  return (
    <div className="info-tag">
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <InfoIcon color="primary" />
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={usernameInfo}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography
          sx={{
            padding: `5px`,
            fontSize: 12,
            color: "#419df2",
            bgcolor: "#FFFAFB",
          }}
          variant="p"
          component="p"
        >
          UserName Instructions
          <li>UserName is your 10 Digit Mobile Number Or</li>
          <li>UserName is your Email Address</li>
        </Typography>
      </Popover>
    </div>
  );
};