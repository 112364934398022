
import logo from "../assets/logo-round.png";
import React, {useState} from 'react'
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
    validatePhone,
    validateFullName,
    validEmail,
} from "../components/Regex";
import TermConditionModal from "../components/TermConditionModal";
import {createUser} from '../services/user';
import {sendOTP, verifyOTP} from '../services/auth';
import {Link} from 'react-router-dom';
import {Stack} from '@mui/material';
import {EmailInfo, FullNameInfo, MobileInfo, OtpInfo, PasswordInfo} from '../components/PopOverInfo/RegistrationInfo';
import {useDispatch} from 'react-redux';
import {setAccessToken, setUserId} from '../services/serviceToken';
import {setToken} from '../redux/authSlice';
import {useEffect} from 'react';
import {getCollegeCount} from '../services/colleges';
import HeroBanner from "../components/home/HeroBanner";
import HeroBannerSlider from "../components/home/HeroBannerSlider";
import ExpandYourHorizon from "../components/home/ExpandYourHorizon";
import heroPattern from "../pgBannerBackground.png";
import WhatMBBSLightHouseDo from "../components/home/WhatMBBSLightHouseDo";
import BenefitsOfMBBSLightHouse from "../components/home/BenefitsOfMBBSLightHouse";
import ServicesPackage from "../components/home/ServicesPackage";
import ServicesList from "../components/home/ServicesList";
import Testimonial from "../components/home/Testimonial";
import FAQ from "../components/home/FAQ";
import WhatMBBSCounseling from "../components/home/WhatMBBSCounseling";
import BenefitsOfMBBSCounseling from "../components/home/BenefitsOfMBBSCounseling";
import MessagePopupModel from "../components/home/MessagePopupModel";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import ReactGA from 'react-ga4';
import {IS_DEV_ENV, SIGNUP_LABEL, ENQUIRY_LABEL} from '../constants'

const Register = ({ isServiceEnquired }) => {
    const dispatch = useDispatch();
    const [inputs, setInputs] = useState({
        name: "",
        email: "",
        mobile: "",
        otp: "",
        status: false,
        password: "",
    });

    const [isMobile, setIsMobile] = useState(false);
    const [errorFullName, setErrorFullName] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
    const [errorMobileNumber, setErrorMobileNumber] = useState(false);
    const [errorOtp, setErrorOtp] = useState(false);
    const [errorHelperTextFullName, setErrorHelperTextFullName] = useState("");
    const [errorHelperTextEmail, setErrorHelperTextEmail] = useState("");
    const [errorHelperTextPassword, setErrorHelperTextPassword] = useState("");
    const [errorHelperTextMobileNumber, setErrorHelperTextMobileNumber] = useState("");
    const [errorHelperTextOtp, setErrorHelperTextOtp] = useState("");
    const [checked, setChecked] = useState(false);
    const [checkBoxErr, setCheckBoxErr] = useState(false);
    const [checkBoxErrText, setCheckBoxErrText] = useState("");

    const handleChangeCheck = (event) => {
        setChecked(event.target.checked);
        if (!checked) {
            handleTcModalOpen();
            setCheckBoxErr(false);
            setCheckBoxErrText("");
        }
    };

    const [tcModalOpen, setTcModalOpen] = useState(false);
    const handleTcModalOpen = () => setTcModalOpen(true);

    const handleChange = (e) => {
        setInputs((prev) => ({...prev, [e.target.name]: e.target.value}));
    };

    const handleRegistrationSendOTP = async (event) => {
        event.preventDefault();
        if (!inputs.name) {
            setErrorFullName(true);
            setErrorHelperTextFullName("Name cannot be empty");
        } else if (!validateFullName.test(inputs.name)) {
            setErrorFullName(true);
            setErrorHelperTextFullName(
                "Full Name must be greater than 2 alphabets and less than 40 alphabets"
            );
        } else if (!inputs.email) {
            setErrorEmail(true);
            setErrorHelperTextEmail("Email cannot be empty");
            setErrorFullName(false);
            setErrorHelperTextFullName("");
        } else if (!validEmail.test(inputs.email)) {
            setErrorEmail(true);
            setErrorHelperTextEmail("Email must be valid");
            setErrorFullName(false);
            setErrorHelperTextFullName("");
        } else if (!inputs.password) {
            setErrorPassword(true);
            setErrorHelperTextPassword("Password cannot be empty");
        } else if (inputs.password.length < 6) {
            setErrorPassword(true);
            setErrorHelperTextPassword("Password should be greater than 6 digit");
        } else if (!inputs.mobile) {
            setErrorMobileNumber(true);
            setErrorHelperTextMobileNumber("Mobile Number cannot be empty");
            setErrorEmail(false);
            setErrorHelperTextEmail("");
        } else if (!validatePhone.test(inputs.mobile)) {
            setErrorMobileNumber(true);
            setErrorHelperTextMobileNumber("Mobile Number must have 10 digits");
            setErrorEmail(false);
            setErrorHelperTextEmail("");
        } else if (!checked) {
            setCheckBoxErr(true);
            setCheckBoxErrText("Please Accept Terms & Conditions");
        } else {
            setErrorFullName(false);
            setErrorEmail(false);
            setErrorMobileNumber(false);
            setErrorHelperTextFullName("");
            setErrorHelperTextEmail("");
            setErrorHelperTextMobileNumber("");

            createUser(inputs)
                .then((response) => {
                    if (!response.data.error) {
                        sendOTP({mobile: inputs.mobile})
                            .then((response) => {
                                if (!response.data.error) {
                                    setIsMobile(true);
                                } else {
                                    setErrorMobileNumber(true);
                                    setErrorHelperTextMobileNumber(
                                        response.data.message + " Mobile Number is not valid"
                                    );
                                }
                            })
                            .catch((err) => {
                                console.error("error", err);
                                setErrorMobileNumber(true);
                                setErrorHelperTextMobileNumber(err);
                            })
                            .finally(() => {
                            });
                    } else {
                        console.error("error", response.data.errors.errors[0].message);
                        if (
                            response.data.errors.errors[0].message === "email must be unique"
                        ) {
                            setErrorEmail(true);
                            setErrorHelperTextEmail(response.data.errors.errors[0].message);
                        } else {
                            setErrorMobileNumber(true);
                            setErrorHelperTextMobileNumber(
                                response.data.errors.errors[0].message
                            );
                        }
                    }
                })
                .catch((err) => {
                    console.error("error 3", err.data.errors.errors[0].message + 3);
                    setErrorMobileNumber(true);
                    setErrorHelperTextMobileNumber(err.data.errors.errors[0].message);
                })
                .finally(() => {
                });
        }
    };

    const verifyRegistrationSendOTP = async (event) => {
        event.preventDefault();
        if (!inputs.otp) {
            setErrorOtp(true);
            setErrorHelperTextOtp("OTP cannot be empty");
        } else if (inputs.otp.length !== 6) {
            setErrorOtp(true);
            setErrorHelperTextOtp("OTP should be of 6 digit");
        } else {
            setErrorOtp(false);
            setErrorHelperTextOtp("");
            verifyOTP({mobile: inputs.mobile, otp: inputs.otp})
                .then((response) => {
                    if (!response.data.error) {
                        const token = response.data.result.token;
                        const id = response.data.result.id;
                        setAccessToken(token);
                        if(IS_DEV_ENV != true) {
                            ReactGA.event({
                                category: 'event',
                                action: 'conversion',
                                label: {send_to: SIGNUP_LABEL},
                        });}
                        dispatch(setToken(token));
                        setUserId(id);
                    } else {
                        setErrorOtp(true);
                        setErrorHelperTextOtp(response.data.message);
                    }
                })
                .catch((err) => {
                    console.error("error", err);
                    setErrorOtp(true);
                    setErrorHelperTextOtp(err.errors.message);
                })
                .finally(() => {
                });
        }
    };

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const [collegeDataCount, setCollegeDataCount] = useState(0);

    const [popupOpen, setPopupOpen] = useState(false);
    const [popupTitle, setPopupTitle] = useState('');
    const [popupMessage, setPopupMessage] = useState('');

    useEffect(() => {
        if (isServiceEnquired === true)
        {   
            if(IS_DEV_ENV != true) {
              ReactGA.event({
                category: 'event',
                action: 'conversion',
                label: {send_to: ENQUIRY_LABEL},
            });}
            setPopupTitle('Enquiry Submitted');
            setPopupMessage('Thankyou for your enquiry. Our team will reach you shortly.');
            setPopupOpen(true);
        }
    }, [isServiceEnquired]);

    useEffect(() => {
        window.scrollTo(0, 0);

        async function CollegeCount() {
            getCollegeCount()
                .then((response) => {
                    if (!response.data.error) {
                        setCollegeDataCount(response.data.result);
                    }
                })
                .catch((err) => {
                    console.error("error", err);
                });
        }

        CollegeCount();
    }, []);

    return (
        <>
            {popupOpen && (
            <MessagePopupModel
                modalOpen={popupOpen}
                setModalOpen={setPopupOpen}
                title={popupTitle}
                message={popupMessage}
            />)}
            <div style={{backgroundImage: `url(${heroPattern}`}}>
                <HeroBanner collegeDataCount={collegeDataCount}/>
                <div className="w-[70%] m-auto">
                    <HeroBannerSlider/>
                </div>
            </div>
            <div id="overview">
            <WhatMBBSCounseling />
            </div>
            <BenefitsOfMBBSCounseling />
            <ServicesList />
            <WhatMBBSLightHouseDo/>
            <BenefitsOfMBBSLightHouse />
            <div id="service">
            {/* <ServicesMobile/> */}
            <ServicesPackage />     
            </div>
            <ServicesList />
            <div id="signup" className="bg-[#eaf1ff] flex flex-col items-center pb-10 pt-10">
                <Container maxWidth="xl">
                    <Grid
                        container
                        spacing={3}
                        sx={{flexGrow: 1}}
                    >
                        <Grid
                            item
                            xs={12}
                            md={5}
                        >
                            <Stack
                                sx={{
                                    padding: {
                                        lg: "0px 30px 10px 20px",
                                        md: "0px 30px 10px 20px",
                                        xs: "0px 30px 10px 20px",
                                    },
                                }}
                            >
                                <ExpandYourHorizon/>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={7}>
                            <Stack
                                sx={{
                                    backgroundColor: '#ffffff',
                                    padding: {
                                        lg: "15px 100px",
                                        md: "15px 100px",
                                        xs: "10px 10px",
                                    },
                                    borderRadius: '20px',
                                }}
                            >

                                <Stack
                                    className="college-form-heading"
                                    display="inline-block"
                                    spacing={2}
                                    mt={2}
                                    mb={2}
                                >
                                    <Typography
                                        variant="h3"
                                        component="h2"
                                        sx={{
                                            fontSize: {md: "30px", xs: "20px"},
                                            fontWeight: 500,
                                            color: "#1e439b",
                                            lineHeight: 1.5,
                                            display: "inline-block",
                                            borderBottom: "3px solid #ff5501",
                                        }}
                                    >
                                        Student Registration
                                    </Typography>
                                </Stack>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                    component="form"
                                    noValidate
                                    onSubmit={
                                        !isMobile
                                            ? handleRegistrationSendOTP
                                            : verifyRegistrationSendOTP
                                    }
                                    mt={1}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Stack direction="row-reverse">
                                                <Box sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    width: "5%"
                                                }}><FullNameInfo/></Box>
                                                <Box sx={{width: "95%"}}>
                                                    <TextField
                                                        autoComplete="given-name"
                                                        name="name"
                                                        required
                                                        fullWidth
                                                        id="name"
                                                        label="Full Name"
                                                        error={errorFullName}
                                                        helperText={errorHelperTextFullName}
                                                        onChange={handleChange}
                                                        autoFocus
                                                    />
                                                </Box>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack direction="row-reverse">
                                                <Box sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    width: "5%"
                                                }}><EmailInfo/></Box>
                                                <Box sx={{width: "95%"}}>
                                                    <TextField
                                                        autoComplete="given-name"
                                                        name="email"
                                                        required
                                                        fullWidth
                                                        id="email"
                                                        label="Email ID"
                                                        error={errorEmail}
                                                        helperText={errorHelperTextEmail}
                                                        onChange={handleChange}
                                                    />
                                                </Box>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack direction="row-reverse">
                                                <Box sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    width: "5%"
                                                }}><PasswordInfo/></Box>
                                                <Box sx={{width: "95%"}}>
                                                    <FormControl
                                                        variant="outlined"
                                                        fullWidth
                                                        autoComplete="given-name"
                                                        required
                                                        name="password"
                                                        error={errorPassword}
                                                        // helperText={errorHelperTextPassword}
                                                        onChange={handleChange}
                                                    >
                                                        <InputLabel htmlFor="password">
                                                            Create Your Password{" "}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            name="password"
                                                            id="password"
                                                            type={showPassword ? "text" : "password"}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        onMouseDown={handleMouseDownPassword}
                                                                        edge="end"
                                                                    >
                                                                        {showPassword ? (
                                                                            <VisibilityOff/>
                                                                        ) : (
                                                                            <Visibility/>
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            label="Create Your Password"
                                                        />
                                                    </FormControl>
                                                </Box>

                                                {/*<TextField
                        autoComplete="given-name"
                        name="password"
                        required
                        fullWidth
                        id="password"
                        label="Password"
                        error={errorPassword}
                        helperText={errorHelperTextPassword}
                        onChange={handleChange}
                     />*/}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack direction="row-reverse">
                                                <Box sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    width: "5%"
                                                }}><MobileInfo/></Box>
                                                <Box sx={{width: "95%"}}>
                                                    <TextField
                                                        label="Mobile Number"
                                                        required
                                                        fullWidth
                                                        variant="outlined"
                                                        id="mobile"
                                                        name="mobile"
                                                        error={errorMobileNumber}
                                                        helperText={errorHelperTextMobileNumber}
                                                        onChange={handleChange}
                                                    />
                                                </Box>
                                            </Stack>
                                            {!isMobile && (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checked}
                                                            onChange={handleChangeCheck}
                                                            color="success"
                                                        />
                                                    }
                                                    className={
                                                        checkBoxErr && checkBoxErr
                                                            ? "term-condition"
                                                            : "term-condition-accept"
                                                    }
                                                    label="Agree to Terms & Conditions"
                                                />
                                            )}
                                            {checkBoxErr && (
                                                <p className="term-condition-error-msg">
                                                    {checkBoxErrText}
                                                </p>
                                            )}
                                        </Grid>
                                        {isMobile && (
                                            <Grid item xs={12}>
                                                <Stack direction="row-reverse">
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            width: "5%"
                                                        }}><OtpInfo/></Box>
                                                    <Box sx={{width: "95%"}}>
                                                        <TextField
                                                            label="OTP"
                                                            required
                                                            fullWidth
                                                            variant="outlined"
                                                            id="otp"
                                                            name="otp"
                                                            autoFocus
                                                            error={errorOtp}
                                                            value={inputs.otp}
                                                            onChange={handleChange}
                                                            helperText={errorHelperTextOtp}
                                                        />
                                                    </Box>
                                                </Stack>
                                            </Grid>
                                        )}

                                        <Grid item md={12} sm={12} lg={12} xs={12}>
                                            <Stack md={12} sm={12} lg={12} xs={12}>
                                                <Box sx={{width: "95%"}}>
                                                    <Button
                                                        type="submit"
                                                        className="search-btn"
                                                        size={"small"}
                                                        fullWidth
                                                        sx={{
                                                            fontSize: {lg: "25px", md: "25px", xs: "16px"},
                                                            border: "1px solid #1366ce",
                                                            outline: "none",
                                                            cursor: "pointer",
                                                            mt: 1,
                                                            mb: 1,
                                                            color: "#fff",
                                                            backgroundColor: "#1366ce",
                                                        }}
                                                    >
                                                        {!isMobile ? "Get OTP" : "Submit OTP"}
                                                    </Button>
                                                </Box>
                                                <Box sx={{width: "5%"}}></Box>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "flex-end",
                                            width: "100%"
                                        }}>
                                        <Box sx={{width: "95%"}}>
                                            <Grid container justifyContent="flex-end">
                                                <Grid
                                                    item
                                                    sx={{
                                                        color: "black",
                                                        fontFamily: ["Poppins", "sans-serif"].join(","),
                                                    }}
                                                >
                                                    Already have an account?{" "}
                                                    <Link
                                                        to={"/login"}
                                                        style={{color: "#1f75cb", textDecoration: "none"}}
                                                    >
                                                        Login
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box sx={{width: "5%"}}></Box>
                                    </Box>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>

                    <TermConditionModal
                        tcModalOpen={tcModalOpen}
                        setTcModalOpen={setTcModalOpen}
                    />
                </Container>
            </div>

            <Testimonial />
            <FAQ />
            <FloatingWhatsApp
                phoneNumber="+91 99103 75900"
                accountName="MBBS Lighthouse"
                avatar={logo}
                statusMessage="online" 
                chatMessage="Hi, Welcome to MBBS Lighthouse. How can we help you?"
                allowClickAway="true"
                allowEsc="true" />
        </>
    );
}

export default Register