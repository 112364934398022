import React from "react";
import { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import CollegeList from "./CollegeList";
import CollegesHeading from "../components/HomePageComponents/CollegesHeading";
import CollegeGraph from "./CollegeGraph";
import { getUserId } from "../services/serviceToken";
import { findUser } from "../services/user";
import { getCountByMarks, getCountByMarksGroupByState, getCountByRank } from "../services/colleges";
import { Link, useLocation } from "react-router-dom";
import { getRank } from "../services/rank";
import CheckoutFormRank from "./CheckoutFormRank";
import image from "../pgBannerBackground.png"; 
import ProgressBar from "../components/ProgressBar/ProgressBar";


const StudentServices = () => {
  const College = useLocation().state;
  const CollegeRank = useLocation().state.rank;

  const [collegeDataCount, setCollegeDataCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    mobile: "",
    student: {
      gender: "",
      stateId12th: "",
      stateId10th: "",
      isPhCandidate: "",
      areaId: "",
      quotaId: "",
      armedForceId: "",
      minorityId: "",
      neetCategoryId: "",
      stateCategoryId: "",
      area: { area: "" },
      quota: { quota: "" },
      state10th: { name: "" },
      state12th: { name: "" },
      minority: { minority: "" },
      armed_force: { armedForce: "" },
      neet_category: { category: "" },
      state_category: { category: "" },
      address: {
        address: "",
        pincode: "",
        stateId: "",
        cityId: "",
        state: { name: "" },
        city: { name: "" },
      },
    },
  });

  const [findMarks, setFindMarks] = useState([]);
  const [findRank, setFindRank] = useState({});

  const userId = getUserId();
  useEffect(() => {
    async function getUserData() {
      findUser(userId)
        .then((response) => {
          setUserData(response.data.result);
        })
        .catch((err) => {
          console.error("error", err);
        });
    }
    getUserData();
  }, [userId]);

  let isPhCandidate = "";
  if (
    userData.student.isPhCandidate &&
    userData.student.isPhCandidate === true
  ) {
    isPhCandidate = "1";
  } else if (userData.student.isPhCandidate === false) {
    isPhCandidate = "0";
  }

  const [collegeCounts, setCollegeCounts] = useState({
    govtColleges: 0,
    privateColleges: 0,
    deemedColleges: 0,
  });

  async function getData() {
    setLoader(true);
    (College.marks && getCountByMarksGroupByState(College.marks)
    .then((response) => {
      if (!response.data.error) {
        let updatedData = [];
        response.data.result.forEach((item) => {
          const foundIndex = updatedData.findIndex(
            (childItem) => childItem.stateId === item.college.state.id
          );
          if (foundIndex === -1) {
            updatedData.push({
              stateId: item.college.state.id,
              stateName: item.college.state.name,
              count: item.count,
            });
          } else {
            updatedData[foundIndex].count = updatedData[foundIndex].count + item.count;
          }
          setFindMarks(updatedData);
        });
      }
    })
    .catch((err) => {
      console.log(err.errors.message);
    })
    .finally(() => {
      setLoader(false);
    }));

    (College.marks && getCountByMarks(College.marks)
    .then((response) => {
      if (!response.data.error) {
        const totalColleges =  handleCollegeGraph(response.data.result);
        setCollegeDataCount(totalColleges);
      }
    })
    .catch((err) => {
      console.log(err.errors.message);
    })
    .finally(() => {
      setLoader(false);
    }));

    (CollegeRank && getCountByRank(CollegeRank)
    .then((response) => {
      if (!response.data.error) {
        const totalColleges =  handleCollegeGraph(response.data.result);
        setCollegeDataCount(totalColleges);
      }
    })
    .catch((err) => {
      console.log(err.errors.message);
    })
    .finally(() => {
      setLoader(false);
    }));

    (College.marks && getRank(College.marks)
    .then((response) => {
      if (!response.data.error) {
        setFindRank(response.data.result);
      }
    })
    .catch((err) => {
      console.log(err.errors.message);
    }));
  }

  useEffect(() => {
     window.scrollTo(0, 0);
    getData();
  }, []);

  const handleCollegeGraph = (collegeCountByInstituteType) => {
    const updatedCollegeCounts = {
      govtColleges: 0,
      privateColleges: 0,
      deemedColleges: 0,
    };
    collegeCountByInstituteType.forEach((item) => {
      switch (item.college.institutionTypeId) {
        case 1:
          updatedCollegeCounts.govtColleges += item.count;
          break;
        case 2:
          updatedCollegeCounts.privateColleges += item.count;
          break;
        case 3:
          updatedCollegeCounts.deemedColleges += item.count;
          break;
        default:
          break;
      }
    });
    setCollegeCounts(updatedCollegeCounts);
    return updatedCollegeCounts.govtColleges + updatedCollegeCounts.privateColleges + updatedCollegeCounts.deemedColleges;
  };

  return (
    <React.Fragment>
      
    <Container maxWidth="xl" sx={{  padding: `16px`, backgroundImage: `url(${image})` }}>
        <ProgressBar/>
      <Grid container spacing={3} sx={{ flexGrow: 1 }}>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            paddingLeft: "0px",
          }}
        >
          <CollegesHeading collegeDataCount={collegeDataCount} />
          <Grid
            item
            xs={0}
            md={4}
            sx={{ position: "sticky", top: 0, height: 530 }}
          >
            {loader ? (
              <Box
                component="img"
                src="/image/ezgif.com-resize.gif"
                alt=""
                sx={{
                  height: {
                    lg: "500px",
                    md: "500px",
                    sm: "400px",
                    xs: "400px",
                  },
                  width: "auto",
                }}
              />
            ) : (
              <CollegeGraph
                graphData={[
                  collegeCounts.govtColleges,
                  collegeCounts.privateColleges,
                  collegeCounts.deemedColleges,
                ]}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          {College.marks && (
            <CollegeList
              marks={College.marks ? College.marks : 90}
              findMarks={findMarks}
              findRank={findRank}
              userData={userData}
            />
          )}
          {CollegeRank && (
            <CheckoutFormRank
              rank={CollegeRank}
              findRank={findRank}
              userData={userData}
            />
          )}

          <Typography
            variant="p"
            component="p"
            mb={1}
            sx={{ fontSize: "20px", fontWeight: "500", fontStyle: "italic" }}
          >
            To View Sample Report{" "}
            <Link
              style={{ color: "#1f75cb", textDecoration: "none" }}
              to={"/sample-report"}
            >
              Click Here
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Container>
    </React.Fragment>
  );
};

export default StudentServices;
