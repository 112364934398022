import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link, useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Typography variant="h1">
        404
      </Typography>
      <Typography variant="h6">
        {error.statusText}
      </Typography>
      <Typography variant="h6">
        Uh oh! Looks like you got lost.
      </Typography>
      <Link to="/">Home</Link>
    </Box>
  );
}