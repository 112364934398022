import logo from "../../assets/logo.png";
import { RxHamburgerMenu } from "react-icons/rx";
import { useLocation } from 'react-router-dom';

import React, { useState } from "react";
import { FaXmark } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import YoutubeVideoModal from "../YoutubeVideoModal";

const settings = [
  {
    link: "logout",
    label: "Sign Out",
  },
];

const subMenu = [
  {
    link: "/student-profile",
    label: "PROFILE",
  },
  {
    link: "/student-orders",
    label: "ORDERS",
  },
  {
    link: "/student-reports",
    label: "REPORTS",
  },
];

export default function Nav() {
  const [isMobileMenu, setMobileMenu] = useState(false);
  const accessToken = useSelector((state) => state.auth.accessToken);
  
  const pathname = useLocation().pathname;
  const isRegister = pathname === '/' || pathname === '/register' || pathname === '/enquiry-sent';
  const isLogin = pathname === '/login';
  const isRegisterOrLogin = isRegister || isLogin;

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const toggleMobileMenu = () => {
    setMobileMenu(!isMobileMenu);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [ytModalOpen, setYtModalOpen] = useState(false);
  const handleYtModalOpen = () => setYtModalOpen(true);

  return (
    <>
      <nav className={accessToken?`top-0 left-0 right-0 z-50 bg-white shadow-md`:`fixed top-0 left-0 right-0 z-50 bg-white shadow-md`}>
        {ytModalOpen && (
          <YoutubeVideoModal
            ytModalOpen={ytModalOpen}
            setYtModalOpen={setYtModalOpen}
          />
        )}
        <div className="container mx-auto px-4 py-2 flex items-center justify-between">
          <a href="/">
            <img src={logo} width={140} alt="logo" />
          </a>
          <div className="hidden lg:flex space-x-8 font-medium text-lg border-2 px-8 py-3 rounded-lg border-blue-500">
            <ul className="flex flex-col md:flex-row md:space-x-8 md:mt-0">
              <li>
                {accessToken || !isRegisterOrLogin?
                <Link to={"/"} className="text-gray-600 hover:text-[#1366cf]">
                  HOME
                </Link>
                : 
                <a href="#" className="text-gray-600 hover:text-[#1366cf]" >
                HOME
                </a>
                }
              </li>

              <li>
                {accessToken || !isRegisterOrLogin?
                <Link
                  to={"/overview"}
                  className="text-gray-600 hover:text-[#1366cf]"
                >
                    OVERVIEW
                </Link>
                :
                <a
                  href="#overview"
                  className="text-gray-600 hover:text-[#1366cf]"
                  style={{scrollBehavior:"smooth"}}
                >
                OVERVIEW
                </a>
                }
              </li>

              <li>
                {accessToken || !isRegisterOrLogin?
                <Link
                  to={"/services"}
                  className="text-gray-600 hover:text-[#1366cf]"
                >                
                  SERVICES
                </Link>
                :
                <a
                  href="#service"
                  className="text-gray-600 hover:text-[#1366cf]"
                > 
                  SERVICES
                 </a> 
                 }
              </li>

              {accessToken?<li>
                <MenuItem
                  onClick={handleClick}
                  className="text-gray-600 text-lg hover:text-[#1366cf] hover:bg-white" 
                  style={{paddingTop:"0.5px", fontSize:"1.125rem", fontWeight:"500", color: "rgb(75 85 99 1)",}}                 
                >
                  {/* <Typography
                  onClick={handleClick}
                  textAlign="center"
                  color="#000"
                > */}
                  STUDENT ACCOUNT
                  {/* </Typography> */}
                </MenuItem>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {subMenu.map((setting) => (
                    <MenuItem key={setting.label} onClick={handleClose}>
                      <Typography textAlign="center">
                        <Link
                          style={{ color: "#1f75cb", textDecoration: "none" }}
                          to={setting.link}
                        >
                          {setting.label}
                        </Link>
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </li>
              :null}

              <li>
                <a
                  target="_blank"
                  href="https://neetnavigator.com/contactUs"
                  className="text-gray-600 hover:text-[#1366cf]"
                >
                  CONTACT
                </a>
              </li>
            </ul>
          </div>
          <div className="hidden lg:flex items-center space-x-4">
            {accessToken || !isRegisterOrLogin? null
            :
            <a
              onClick={handleYtModalOpen}
              className="text-base cursor-pointer md:text-xl font-medium text-[#1366cf] block border-2 border-[#1366cf] px-5 py-3 rounded hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
            >
              DEMO
            </a>
            }            

            {/*-------- signout --------------*/}
            {accessToken ? (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Sign Out">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <AccountCircleIcon
                      style={{ fontSize: 40, color: "#1e439b" }}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting.label} onClick={handleCloseUserMenu}>
                      {/* <Typography textAlign="center"> */}
                      <Link
                        style={{ color: "#1f75cb", textDecoration: "none" }}
                        to={setting.link}
                      >
                        {setting.label}
                      </Link>
                      {/* </Typography> */}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            ) : (
              <a
                href={"#signup"}
                className="text-base cursor-pointer md:text-xl font-medium text-white bg-[#1366cf] block px-5 py-3 rounded hover:bg-blue-700 hover:ring-2 hover:ring-blue-600 hover:ring-offset-2"
              >
                {isLogin? "LOGIN" : "SIGNUP"}
              </a>
            )}
            {/* ---------- */}
          </div>

          <div className="lg:hidden">
            <div className="flex items-center space-x-4">
            {accessToken ? (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Sign Out">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <AccountCircleIcon
                      style={{ fontSize: 40, color: "#1e439b" }}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting.label} onClick={handleCloseUserMenu}>
                      {/* <Typography textAlign="center"> */}
                      <Link
                        style={{ color: "#1f75cb", textDecoration: "none" }}
                        to={setting.link}
                      >
                        {setting.label}
                      </Link>
                      {/* </Typography> */}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            ) : (
              <a
                href={"#signup"}
                className="text-base cursor-pointer md:text-xl font-medium text-white bg-[#1366cf] block px-5 py-3 rounded hover:bg-blue-700 hover:ring-2 hover:ring-blue-600 hover:ring-offset-2"
              >
                {isLogin? "LOGIN" : "SIGNUP"}
              </a>
            )}
              {/* <a
                href={"#signup"}
                className="text-base cursor-pointer md:text-xl font-medium text-white bg-[#1366cf] block px-5 py-3 rounded hover:bg-blue-700 hover:ring-2 hover:ring-blue-600 hover:ring-offset-2"
              >
                SIGNUP
              </a> */}
              <RxHamburgerMenu
                className="text-gray-700 hover:text-[#1366cf] text-3xl cursor-pointer mr-2"
                onClick={toggleMobileMenu}
              />
            </div>
          </div>

          {isMobileMenu && (
            <div className="fixed top-0 right-0 z-40 h-[400px] w-[200px] bg-white shadow-md drop-shadow-xl lg:hidden">
              <div className="flex justify-end p-5">
                <button onClick={toggleMobileMenu}>
                  <FaXmark className="h-5 w-5 right-0 text-black hover:text-[#1366cf]" />
                </button>
              </div>
              <div className="flex flex-col">
                <div className="ml-[30%] flex flex-col gap-4 text-lg">

                  {accessToken || !isRegisterOrLogin?
                  <Link to={"/"} className="text-gray-600 hover:text-[#1366cf]"  onClick={toggleMobileMenu}>
                    HOME
                  </Link>
                  : 
                  <a href={"#"} className="text-gray-600 hover:text-[#1366cf]" onClick={toggleMobileMenu}>
                    HOME
                  </a>
                  }

                  {accessToken || !isRegisterOrLogin?
                  <Link to={"/overview"} className="text-gray-600 hover:text-[#1366cf]" onClick={toggleMobileMenu}>
                  OVERVIEW
                  </Link>
                  :
                  <a href="#overview" className="text-gray-600 hover:text-[#1366cf]" style={{scrollBehavior:"smooth"}} onClick={toggleMobileMenu}>
                  OVERVIEW
                  </a>
                  }

                  {accessToken || !isRegisterOrLogin?
                  <Link to={"/services"} className="text-gray-600 hover:text-[#1366cf]" onClick={toggleMobileMenu}>                
                    SERVICES
                  </Link>
                  :
                  <a href="#service" className="text-gray-600 hover:text-[#1366cf]" onClick={toggleMobileMenu}> 
                    SERVICES
                  </a> 
                  }

                  {accessToken && 
                  <Link to={"/student-profile"} className="text-gray-600 hover:text-[#1366cf]" onClick={toggleMobileMenu}>                
                    PROFILE
                  </Link>
                  }
                  {accessToken && 
                  <Link to={"/student-orders"} className="text-gray-600 hover:text-[#1366cf]" onClick={toggleMobileMenu}>                
                    ORDERS
                  </Link>
                  }
                  {accessToken && 
                  <Link to={"/student-reports"} className="text-gray-600 hover:text-[#1366cf]" onClick={toggleMobileMenu}>                
                    REPORTS
                  </Link>
                  }

                  <a
                    target="_blank"
                    href={"https://neetnavigator.com/contactUs"}
                    className="text-gray-600 hover:text-[#1366cf]"
                    onClick={toggleMobileMenu}
                  >
                    CONTACT
                  </a>

                  {accessToken || !isRegisterOrLogin? null
                  :
                  <a
                    onClick={handleYtModalOpen}
                    className="text-gray-600 hover:text-[#1366cf]"
                  >
                    DEMO
                  </a>
                  }
                </div>
              </div>
            </div>
          )}
        </div>
      </nav>
      
    </>
  );
}
