import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import YoutubeVideoModal from "./YoutubeVideoModal";



const pages = [
  {
    link: "/",
    label: "HOME",
  },
  {
    link: "/overview",
    label: "OVERVIEW",
  },
  {
    link: "/services",
    label: "SERVICES",
  },
  // {
  //   link: "/student-account",
  //   label: "STUDENT ACCOUNT",
  // },
];
const subMenu = [
  {
    link: "/student-profile",
    label: "PROFILE",
  },
  {
    link: "/student-orders",
    label: "ORDERS",
  },
  {
    link: "/student-reports",
    label: "REPORTS",
  },
];
const settings = [
  {
    link: "logout",
    label: "Sign Out",
  },
];

function Navbar() {

  const accessToken = useSelector((state) => state.auth.accessToken);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [ytModalOpen, setYtModalOpen] = useState(false);
  const handleYtModalOpen = () => setYtModalOpen(true);

  return (
    <AppBar position="relative" sx={{ background: "#fff" }} elevation={0}>
      {ytModalOpen && (
        <YoutubeVideoModal
          ytModalOpen={ytModalOpen}
          setYtModalOpen={setYtModalOpen}
        />
      )}
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Link to={"/"}>
              <img style={{ width: "216px" }} src="/image/2.png" alt="logo" />
            </Link>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="black"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">
                    <Link
                      style={{ color: "#000", textDecoration: "none" }}
                      to={page.link}
                    >
                      {page.label}
                    </Link>
                  </Typography>
                </MenuItem>
              ))}
              <MenuItem>
                <Typography
                  onClick={handleClick}
                  textAlign="center"
                  color="#000"
                >
                  STUDENT ACCOUNT
                </Typography>
              </MenuItem>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {subMenu.map((setting) => (
                  <MenuItem key={setting.label} onClick={handleClose}>
                    <Typography textAlign="center">
                      <Link
                        style={{ color: "#1f75cb", textDecoration: "none" }}
                        to={setting.link}
                      >
                        {setting.label}
                      </Link>
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
              <Typography
                variant="h5"
                noWrap
                component="a"
                target="_blank"
                style={{
                  color: "#000",
                  textDecoration: "none",
                  fontSize: "16px",
                  paddingTop: "6px",
                  lineHeight: "2.334",
                  paddingBottom: "6px",
                  boxSizing: "borderBox",
                  whiteSpace: "nowrap",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
                href="https://neetnavigator.com/contactUs.php"
              >
                CONTACT
              </Typography>
              <br></br>
              {!accessToken ? (
                <Typography
                  variant="h5"
                  noWrap
                  component="a"
                  style={{
                    color: "#000",
                    textDecoration: "none",
                    fontSize: "17px",
                    paddingTop: "6px",
                    paddingBottom: "6px",
                    boxSizing: "borderBox",
                    whiteSpace: "nowrap",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    cursor: "pointer",
                  }}
                  onClick={handleYtModalOpen}
                >
                  DEMO
                </Typography>
              ) : (
                ""
              )}
            </Menu>
          </Box>
          {/* desktop view */}
          <Typography
            variant="h5"
            noWrap
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Link to={"/"}>
              <img style={{ width: "150px" }} src="/image/2.png" alt="logo" />
            </Link>
          </Typography>
          <Box sx={{ flexGrow: 2 }} />
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  <Link
                    style={{ color: "#000", textDecoration: "none" }}
                    to={page.link}
                  >
                    {page.label}
                  </Link>
                </Typography>
              </MenuItem>
            ))}
            <MenuItem>
              <Typography onClick={handleClick} textAlign="center" color="#000">
                STUDENT ACCOUNT
              </Typography>
            </MenuItem>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {subMenu.map((setting) => (
                <MenuItem key={setting.label} onClick={handleClose}>
                  <Typography textAlign="center">
                    <Link
                      style={{ color: "#1f75cb", textDecoration: "none" }}
                      to={setting.link}
                    >
                      {setting.label}
                    </Link>
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
            <Typography
              variant="h5"
              noWrap
              component="a"
              target="_blank"
              style={{
                color: "#000",
                textDecoration: "none",
                fontSize: "17px",
                paddingTop: "6px",
                paddingBottom: "6px",
                boxSizing: "borderBox",
                whiteSpace: "nowrap",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
              href="https://neetnavigator.com/contactUs"
            >
              CONTACT
            </Typography>
            {!accessToken ? (
              <Typography
                variant="h5"
                noWrap
                component="a"
                style={{
                  color: "#000",
                  textDecoration: "none",
                  fontSize: "17px",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  boxSizing: "borderBox",
                  whiteSpace: "nowrap",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  cursor: "pointer",
                }}
                onClick={handleYtModalOpen}
              >
                DEMO
              </Typography>
            ) : (
              ""
            )}
          </Box>
          {accessToken ? (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Sign Out">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <AccountCircleIcon
                    style={{ fontSize: 40, color: "#1e439b" }}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting.label} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">
                      <Link
                        style={{ color: "#1f75cb", textDecoration: "none" }}
                        to={setting.link}
                      >
                        {setting.label}
                      </Link>
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          ) : (
            ""
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
