import Cost from "../../assets/expandYourHorizon/cost.svg";
import Comprehensive from "../../assets/expandYourHorizon/comprehensive.svg";
import Data from "../../assets/expandYourHorizon/data.svg";
import Predictive from "../../assets/expandYourHorizon/predictive.svg";

const Pointer = ({src, title, description}) => {
    return (
        <div className="flex flex-col items-center space-y-2">
            <img src={src} alt={title} />
            <p className="text-lg md:text-xl font-semibold my-2">{title}</p>
            <p className="text-sm md:text-base text-gray-600 text-center">{description}</p>
        </div>
    )
}

export default function ExpandYourHorizon() {
    return (

        <div className="max-w-xl">
            <div>
                <p className="text-xl lg:text-4xl md:text-2xl font-semibold my-8 ml-4">Expand Your Horizon of <span className="text-[#1366CF] ">MBBS Admission </span></p>

            <p className="text-sm md:text-base text-gray-600 text-center mt-4">NEET Navigator offers India's Most advanced data analytics based Medical Counselling Services</p>
            </div>

            <div className="flex flex-col space-y-6 mt-6">
                <div className="flex justify-between space-x-16">
                    <Pointer src={Predictive} title="Comprehensive" description="Get an all India view of your MBBS admission possibility"/>
                    <Pointer src={Comprehensive} title="Data visualization" description="Easy-to-understand visualizations and report formats"/>
                </div>
                <div className="flex justify-between  space-x-16">
                    <Pointer src={Data} title="Predictive analytics" description="Advanced algorithms to offer most accurate prediction"/>
                    <Pointer src={Cost} title="Cost Effective" description="Get started for FREE and pay only for specific reports"/>
                </div>
            </div>
        </div>
    )
}