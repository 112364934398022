import { RouterProvider } from "react-router-dom";
import router from "./routes/router";

import "./App.css";
import ScrollToTop from "./routes/ScrollToTop";

// const App = () => <RouterProvider router={router} />;


function App() {
  return (
    <RouterProvider router={router}>
      <ScrollToTop />
    </RouterProvider>
  );
}

export default App;

