import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";

export const getAreaOfState = () => {
  return axios({
    method: "get",
    url: BASE_URL + "area-of-state",
    headers: getHeaders(),
  });
};
