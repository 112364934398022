import comprehensiveReport from  "../../assets/sliderImg/comprehensive-report.svg";
import bestCounseling from  "../../assets/sliderImg/best-counselling.svg";

import Carousel from 'react-material-ui-carousel'
import "./style.css";


let slider = [
    {
        id: 1,
        image: bestCounseling,
        title: "1"
    },
    {
        id: 2,
        image: comprehensiveReport,
        title: "2"
    }
]

function Item({item}) {
    return (
        <div>
            <img src={item.image} alt={item.title}/>
        </div>
    )
}
export default function HeroBannerSlider() {
    return (
        <Carousel className="custom-slider">
            {
                slider.map( item => <Item key={item.id} item={item} /> )
            }
        </Carousel>
    )
}