import axios from "axios";
import { BASE_URL } from "../constants";
import  getHeaders from "../constants/headers";

export const createUser = ({ name, mobile, email, status, password }) => {
  return axios({
    method: "post",
    url: BASE_URL + "user",
    headers:  getHeaders(),
    data: JSON.stringify({ name, mobile, email, status, password }),
  });
};

export const getUser = () => {
  return axios({
    method: "get",
    url: BASE_URL + "user",
    headers:  getHeaders(),
  });
} 

export const updateUser = (userId, { name, email }) => {
  return axios({
    method: "put",
    url: BASE_URL + "user/" + userId,
    data: JSON.stringify({ name, email }),
  });
};

export const findUser = (userId) => {
  return axios({
    method: "get",
    url: BASE_URL + "user/" + userId,
    headers: getHeaders(),
  });
}

