import React from "react";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import moment from "moment/moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import image from "../../pgBannerBackground.png";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const StudentOrderData = ({
  successPaymentStatus,
  userDetail,
}) => {
  const { name, mobile, email } = userDetail;

  const themeStudentData = createTheme();

  themeStudentData.typography.h3 = {
    fontSize: "16px",
    "@media (max-width:600px)": {
      fontSize: "16px",
    },

    fontFamily: ["Poppins", "sans-serif"].join(","),
  };
  themeStudentData.typography.p = {
    fontSize: "16px",
    "@media (max-width:600px)": {
      fontSize: "16px",
    },
    fontFamily: ["Poppins", "sans-serif"].join(","),
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={themeStudentData}>
        <Container maxWidth="xl" sx={{ backgroundImage: `url(${image})` }}>
          <Grid
            container
            spacing={3}
            sx={{ marginBottom: "20px", flexGrow: 1 }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              sx={{
                position: {
                  lg: "sticky",
                  md: "sticky",
                },
                top: 0,
                height: "calc(100%)",
              }}
            >
              <Card
                sx={{
                  borderBottom: "2px solid #1366ce",
                  borderRight: "2px solid #1366ce",
                }}
              >
                <Typography
                  variant="h3"
                  component="h2"
                  lineHeight="27px"
                  textAlign="center"
                  p="15px 10px 5px 10px"
                  sx={{
                    fontSize: {
                      lg: "16px",
                      md: "16px",
                      sm: "14px",
                      xs: "14px",
                    },
                    fontWeight: 600,
                  }}
                >
                  {name}
                </Typography>
                <Typography
                  variant="h3"
                  component="h2"
                  lineHeight="20px"
                  textAlign="center"
                  p="5px 10px"
                  sx={{
                    fontSize: {
                      lg: "14px",
                      md: "14px",
                      sm: "12px",
                      xs: "12px",
                    },
                    fontWeight: 500,
                  }}
                >
                  {mobile}
                </Typography>
                <Typography
                  variant="h3"
                  component="h2"
                  lineHeight="20px"
                  textAlign="center"
                  p="5px 10px"
                  sx={{
                    fontSize: {
                      lg: "14px",
                      md: "14px",
                      sm: "12px",
                      xs: "12px",
                    },
                    wordWrap: "break-word",
                    fontWeight: 500,
                  }}
                >
                  {email}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} md={8} sm={12} lg={8}>
              <Card sx={{ padding: "15px 15px" }}>
                <Grid item>
                  <Typography
                    variant="h3"
                    component="h3"
                    color="#1366ce"
                    align="center"
                    mb={3}
                    sx={{
                      fontSize: { lg: "26px", md: "26px", xs: "16px" },
                      display: "inline-block",
                      borderBottom: "3px solid #ff5501",
                    }}
                  >
                    Your Transaction History
                  </Typography>
                  <Stack
                    sx={{
                      float: "right",
                      marginTop: { lg: "12px", md: "12px" },
                    }}
                  >
                    <Link to={"/student-reports"} variant="contained">
                      <PictureAsPdfIcon
                        sx={{
                          color: "#1366ce",
                          fontSize: "26px",
                        }}
                      />
                    </Link>
                  </Stack>
                </Grid>
                {successPaymentStatus.map(
                  ({
                    createdAt,
                    tracking_id,
                    order_status,
                    amount,
                    payment_mode,
                  }) => (
                    <Card
                      key={createdAt}
                      sx={{
                        padding: "15px",
                        marginBottom: "20px",
                        borderTop: "2px solid #1366ce",
                        borderLeft: "2px solid #1366ce",
                      }}
                    >
                      <Stack flexDirection={"row"}>
                        <Grid container spacing={2} mt="1px" mb={1}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            sx={{ paddingTop: "8px !important" }}
                          >
                            <Typography
                              variant="p"
                              component="p"
                              color="#17a2b8"
                              align="left"
                              mb={1}
                              sx={{
                                fontSize: {
                                  lg: "16px",
                                  md: "16px",
                                  xs: "14px",
                                },
                              }}
                            >
                              <strong
                                style={{
                                  fontSize: { md: "48px", xs: "21px" },
                                  fontWeight: 600,
                                  color: "#1366ce",
                                }}
                              >
                                Order Id :
                              </strong>{" "}
                              {tracking_id}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            sx={{ paddingTop: "8px !important" }}
                          >
                            <Typography
                              variant="p"
                              component="p"
                              color="#17a2b8"
                              align="left"
                              mb={1}
                              sx={{
                                fontSize: {
                                  lg: "16px",
                                  md: "16px",
                                  xs: "14px",
                                },
                              }}
                            >
                              <strong
                                style={{
                                  fontSize: { md: "48px", xs: "21px" },
                                  fontWeight: 600,
                                  color: "#1366ce",
                                }}
                              >
                                Order Date{" "}
                              </strong>
                              {moment(createdAt).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ paddingTop: "8px !important" }}
                          >
                            <Typography
                              variant="p"
                              component="p"
                              color="#17a2b8"
                              align="left"
                              mb={1}
                              sx={{
                                fontSize: {
                                  lg: "16px",
                                  md: "16px",
                                  xs: "14px",
                                },
                              }}
                            >
                              <strong
                                style={{
                                  fontSize: { md: "48px", xs: "21px" },
                                  fontWeight: 600,
                                  color: "#1366ce",
                                }}
                              >
                                Order Status :
                              </strong>{" "}
                              {order_status}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ paddingTop: "8px !important" }}
                          >
                            <Typography
                              variant="p"
                              component="p"
                              color="#17a2b8"
                              align="left"
                              mb={1}
                              sx={{
                                fontSize: {
                                  lg: "16px",
                                  md: "16px",
                                  xs: "14px",
                                },
                              }}
                            >
                              <strong
                                style={{
                                  fontSize: { md: "48px", xs: "21px" },
                                  fontWeight: 600,
                                  color: "#1366ce",
                                }}
                              >
                                Payment Method :
                              </strong>{" "}
                              {payment_mode === "null" ? "NA" : payment_mode}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            sx={{ paddingTop: "8px !important" }}
                          >
                            <Typography
                              variant="p"
                              component="p"
                              color="#FF0000"
                              align="left"
                              mb={1}
                              sx={{
                                fontSize: {
                                  lg: "16px",
                                  md: "16px",
                                  xs: "13px",
                                },
                              }}
                            >
                              <strong
                                style={{
                                  fontSize: { md: "48px", xs: "21px" },
                                  fontWeight: 600,
                                  color: "#1366ce",
                                }}
                              >
                                Order Amount/ Service :
                              </strong>{" "}
                              ₹ {amount} /-
                            </Typography>
                          </Grid>
                        </Grid>
                      </Stack>
                      {/*<TableContainer>
                    <DataTable
                      columns={columns}
                      data={successPaymentStatus}
                      customStyles={customStyles}
                    />
                  </TableContainer>*/}
                    </Card>
                  )
                )}

                {successPaymentStatus.length === 0 && (
                  <Stack flexDirection={"row"}>
                    <Grid container spacing={2} mt="1px" mb={1}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        sx={{ paddingTop: "8px !important" }}
                      >
                        <Typography
                          variant="p"
                          component="p"
                          color="#FF0000"
                          align="left"
                          mb={1}
                          sx={{
                            fontSize: {
                              lg: "16px",
                              md: "16px",
                              xs: "14px",
                            },
                          }}
                        >
                          Looks like you haven't placed any order
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                )}
              </Card>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default StudentOrderData;
