import React from "react";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const SelectedCollegeInfo = () => {
const themeStudentData = createTheme();

themeStudentData.typography.h3 = {
  fontSize: "16px",
  "@media (max-width:600px)": {
    fontSize: "16px",
  },

  fontFamily: ["Poppins", "sans-serif"].join(","),
};
themeStudentData.typography.p = {
  fontSize: "16px",
  "@media (max-width:600px)": {
    fontSize: "16px",
  },
  fontFamily: ["Poppins", "sans-serif"].join(","),
};

return (
  <>
    <ThemeProvider theme={themeStudentData}>
      <Container maxWidth="xl" sx={{ padding: `16px` }}>
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          display="inline-block"
        >
          <Typography
            variant="h3"
            component="h3"
            color="#1366ce"
            align="center"
            mb={3}
            sx={{
              fontSize: { lg: "40px", md: "40px", xs: "25px" },
              display: "inline-block",
              borderBottom: "3px solid #ff5501",
            }}
          >
            COLLEGE INFO
          </Typography>
        </Stack>
        <Card
          sx={{
            borderBottom: "2px solid #1366ce",
            borderRight: "2px solid #1366ce",
            padding: {
              lg: "10px 20px",
              md: "10px 20px",
              xs: "10px 20px",
            },
          }}
        >
          <Grid container spacing={2} mt={1} mb={1}>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                College Name
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                Aarupadai Veedu Medical College and Hospital, Puducherry
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Year
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                2022
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Year of Inception
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                1998
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                City
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                Puducherry
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                State
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                Puducherry
              </Typography>
            </Grid>
          </Grid>
        </Card>

        <Card
          sx={{
            margin: "20px 0px",
            borderBottom: "2px solid #1366ce",
            borderRight: "2px solid #1366ce",
            padding: {
              lg: "10px 20px",
              md: "10px 20px",
              xs: "10px 20px",
            },
          }}
        >
          <Grid container spacing={2} mt={1} mb={1}>
            {/** address */}
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Pincode
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                607402
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Region
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                Center
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Area
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                All
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Course
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                MBBS
              </Typography>
            </Grid>

            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Institution Type
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                Deemed
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Eligibility
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                open
              </Typography>
            </Grid>

            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Counselling Seat
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                MCC
              </Typography>
            </Grid>
          </Grid>
        </Card>

        <Card
          sx={{
            margin: "20px 0px",
            borderBottom: "2px solid #1366ce",
            borderRight: "2px solid #1366ce",
            padding: {
              lg: "10px 20px",
              md: "10px 20px",
              xs: "10px 20px",
            },
          }}
        >
          <Grid container spacing={2} mt={1} mb={1}>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Category
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                open
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Seat type
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                Management
              </Typography>
            </Grid>

            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Gender
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                Female
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Ph Candidate
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                No
              </Typography>
            </Grid>
          </Grid>
        </Card>

        <Card
          sx={{
            margin: "20px 0px",
            borderBottom: "2px solid #1366ce",
            borderRight: "2px solid #1366ce",
            padding: {
              lg: "10px 20px",
              md: "10px 20px",
              xs: "10px 20px",
            },
          }}
        >
          <Grid container spacing={2} mt={1} mb={1}>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Armed Force
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                None
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Minority
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                None
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Quota
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                None
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Fee Range
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                Greater than 1 Cr.
              </Typography>
            </Grid>

            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Hostel Fee
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                335000
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Total Fee
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                10644725
              </Typography>
            </Grid>

            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Total Fee Payable
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                2370550
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Bond Year
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                0
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="h3" component="h3">
                Bond Amount
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Typography variant="p" component="p">
                0
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </ThemeProvider>
  </>
);
};

export default SelectedCollegeInfo;
