import React from 'react'
import { Box, Button, Card, CardMedia, Fade, Grid, Modal, Stack, styled, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "700px", md: "700px", sm: "340px", xs: "340px" },
  height: { lg: "394px", md: "394px", sm: "194px", xs: "194px" },
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,

  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "0em",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: "1em",
  },
};

const YoutubeVideoModal = ({ ytModalOpen, setYtModalOpen }) => {
  const handleTcModalClose = () => setYtModalOpen(false);

  return (
    <>
      {
        <StyledModal
          open={ytModalOpen}
          onClose={handleTcModalClose}
          closeAfterTransition={false}
        >
          <Fade in={ytModalOpen}>
            <Box sx={style}>
              <Stack
                sx={{
                  right: 5,
                  top: 10,
                  position: "absolute",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{ color: "red", fontSize: 20 }}
                  onClick={handleTcModalClose}
                >
                  <CloseIcon />
                </span>
              </Stack>
              <Grid item xs={12} md={12}>
                <Card
                  sx={{
                    width: { lg: 700, md: 700, sm: 340, xs: 340 },
                  }}
                >
                  <CardMedia
                    style={{ border: "0px solid"}}
                    component="iframe"
                    className="MuiCardMedia-media"
                    sx={{ height: { lg: 394, md: 394, sm: 194, xs: 194 } }}
                    image="https://www.youtube.com/embed/A-xbNTwAdGA?&autoplay=1&mute=1"
                    allow="autoplay"
                    title="MBBS Lighthouse"
                  />
                </Card>
              </Grid>
            </Box>
          </Fade>
        </StyledModal>
      }
    </>
  );
};

export default YoutubeVideoModal