import axios from "axios";
import { BASE_URL } from "../constants";
import  getHeaders from "../constants/headers";

export const getRank = (marks) => {
  return axios({
    method: "get",
    url: BASE_URL + "/rank/find-by-marks?marks=" + marks,
    headers: getHeaders(),
  });
};
