import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import MainLayout from './MainLayout';
import {setAccessToken, setUserId} from '../services/serviceToken';
import {setToken} from '../redux/authSlice';

const Authentication = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    var accessToken = useSelector((state) => state.auth.accessToken);
    if (!accessToken && location.pathname === "/home") {
        const params = Object.fromEntries(
            new URLSearchParams(location.search)
        );
        if(params.token && params.userId) {
            accessToken = params.token;
            setAccessToken(accessToken);
            setUserId(params.userId);
            dispatch(setToken(accessToken));
        }
    }
    return accessToken ? <MainLayout /> : <Navigate to={'register'} replace />;
};

export default Authentication;