import pointer from "../../assets/pointer.svg";
import { useState } from "react";
import ServiceEnquiryModel from "./ServiceEnquiryModel";

const keypoints = [
    [
        "NEET UG Rank Predictor",
        "MBBS Admission Predictor",
        "MBBS College Predictor",
        "Medical College Information",
        "Medical College Admission Cut Offs",
        "MBBS Course Fee Structure",
        "Check sample report here",
    ],
    [
        "MBBS Admission Possibility Estimation",
        "MBBS Admission Strategy Formulation",
        "Guidance on Documentation",
        "Counselling Process Guidance (through webinars)",
        "Customized College Choice Lists",
        "Counselling Notices and Updates",
        "3 doubt clearing group sessions",
    ],
    [
        "MBBS Admission Possibility Estimation",
        "MBBS Admission Strategy Formulation",
        "Guidance on Documentation",
        "Counselling Process Guidance (through mentors)",
        "Customized College Choice Lists",
        "Counselling Notices and Updates",
        "Mentoring support during form filling",
        "5 doubt clearing sessions with mentor",
    ],
    [
        "MBBS Admission Possibility Estimation",
        "MBBS Admission Strategy Formulation",
        "Documents Guidance & Support",
        "Counselling Process Guidance (through mentors)",
        "Customized College Choice Lists",
        "Counselling Notices and Updates",
        "Mentoring support during entire admission process",
        "8 doubt clearing sessions with mentor",
    ],
]
const KeyPoint = ({text}) => {
    return (
        <div className="flex space-x-4 items-center">
            <img src={pointer} alt="check" className="w-70%"/>
            <p className="">{text}</p>
        </div>
    )
}

const PriceCard = ({title, price, description, bg, keyPoints, cta}) => {
    let bgColor = ""
    let btn;

    if(bg === "blue"){
        btn = "text-base cursor-pointer md:text-xl font-medium text-white bg-[#1366cf] block px-5 py-3 rounded hover:bg-blue-700 hover:ring-2 hover:ring-blue-600 hover:ring-offset-2";
        bgColor = "bg-[#eaf1ff] rounded-lg";
    }else{
        btn = "text-base cursor-pointer md:text-xl font-medium text-[#1366cf] block border-2 border-[#1366cf] px-4 py-2 rounded hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2";
        bgColor = "bg-white rounded-lg";
    }

    return (
        <div className={`flex flex-col w-72 text-wrap p-5 ${bgColor}`}>
            <div className="space-y-3 mb-auto">
                <p className="text-black text-xl font-medium text-center">{title}</p>
                <p className="text-[#1366cf] font-bold text-4xl text-center">{price}</p>
                {/* <p className="text-base text-black font-medium">{description}</p> */}
            </div>
            <div className="space-y-4 my-6 mb-auto">
                {keyPoints.map((point, index) => (
                    <KeyPoint key={index} text={point}/>
                ))}
            </div>
            <div className="flex items-center justify-center mt-5">
                {cta(btn)}
            </div>
        </div>
    )
}

export default function ServicesPackage() {
    const ctas = [
        (btn) => <a href={'#signup'} className={btn}>SIGN UP</a>,
        (btn) => <a target="_blank" onClick={() => setModel1Open(true)} className={btn}>ENQUIRE</a>,
        (btn) => <a target="_blank" onClick={() => setModel2Open(true)} className={btn}>ENQUIRE</a>,
        (btn) => <a target="_blank" onClick={() => setModel3Open(true)} className={btn}>ENQUIRE</a>,
    ];

    const [model1Open, setModel1Open] = useState(false);
    const [model2Open, setModel2Open] = useState(false);
    const [model3Open, setModel3Open] = useState(false);

    return (
        <div id="services" className="bg-[#eaf1ff] flex flex-col items-center justify-center pb-10">
            {model1Open && (<ServiceEnquiryModel
                modalOpen={model1Open}
                setModalOpen={setModel1Open}
                price={"9,999"}
                packageName={"Advance: E-Counselling Service"}
                successPath={"/enquiry-sent"}
            />)}
            {model2Open && (<ServiceEnquiryModel
                modalOpen={model2Open}
                setModalOpen={setModel2Open}
                price={"14,999"}
                packageName={"Premier: E-Mentoring Service"}
                successPath={"/enquiry-sent"}
            />)}
            {model3Open && (<ServiceEnquiryModel
                modalOpen={model3Open}
                setModalOpen={setModel3Open}
                price={"29,999"}
                packageName={"Exclusive: Premium Mentoring Service"}
                successPath={"/enquiry-sent"}
            />)}
            <h2 className="text-xl lg:text-4xl md:text-2xl font-semibold my-8 ml-4">Our <span
                className="text-[#1366CF]">E-Counselling</span> Services</h2>
            <div className=" flex flex-col items-center p-8 lg:p-10 bg-white rounded-2xl drop-shadow-md">
                <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-14">
                    {keypoints.map((points, index) => (
                        <PriceCard
                            key={index}
                            title={["Basic", "Advance", "Premier🔥", "Exclusive"][index]}
                            price={["₹999", "₹9,999", "₹14,999", "₹29,999"][index]}
                            description={["Comprehensive reports", "E-Counselling", "E-Mentoring", "NRI & Special Needs"][index]}
                            bg={index === 2 ? "blue" : ""}
                            keyPoints={points}
                            cta={ctas[index]}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}
