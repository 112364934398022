import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { AppBar, Grid, List, ListItem } from "@mui/material";
import { Stack } from "@mui/system";

function Copyright() {
  return (
    <Typography
      variant="body2"
      sx={{ color: "#fff", fontSize: "18px" }}
      align="center"
    >
      {"Copyright © NEET Navigator Educational Services "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const Footer = () => {
  return (
    <Box>
      <AppBar
        position="static"
        elevation={0}
        component="footer"
        sx={{ backgroundColor: "#2b2b81" }}
      >
        <Container maxWidth="xl">
          <Stack className="mainfooter">
            <Grid item container direction="row" sm={12} xs={12} mb={5}>
              <Grid item sm={5} xs={12} className="footer1">
                <img
                  src="/image/LH.png"
                  alt="Mbbslighthouse"
                  style={{ width: "300px", marginBottom: "20px" }}
                />
                <Typography textAlign="justify" paragraph>
                  NEET Navigator is India's first advanced data analytics based
                  medical admission counselling services. NEET Navigator offers
                  medical admission counselling services for NEET UG, NEET PG
                  for all over India.
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  sx={{ fontWeight: 600, lineHeight: "60px" }}
                >
                  Address
                </Typography>
                <Typography paragraph>
                  NEET Navigator Educational Services B-114, North Ex Mall,
                  Sector-9, Rohini, Delhi (INDIA) 110085
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  sx={{ fontWeight: 600, lineHeight: "60px" }}
                >
                  Connect with us
                </Typography>
                <List className="socialMedia">
                  <ListItem
                    className="emailicon"
                    sx={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    <Link
                      target="_blank"
                      href={"mailto:Info@neetnavigator.com"}
                    >
                      <img
                        src="/image/mail.png"
                        alt="mail"
                        style={{ width: "25px" }}
                      />
                    </Link>
                  </ListItem>
                  <ListItem
                    className="whatsappicon"
                    sx={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    <Link target="_blank" href={"https://wa.me/+919910375900"}>
                      <img
                        src="/image/whatsapp.png"
                        alt="whatsapp"
                        style={{ width: "25px" }}
                      />
                    </Link>
                  </ListItem>
                  <ListItem
                    className="facebookicon"
                    sx={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    <Link
                      target="_blank"
                      href={"https://www.facebook.com/NavigatorNeet"}
                    >
                      <img
                        src="/image/facebook.png"
                        alt="facebook"
                        style={{ width: "25px" }}
                      />
                    </Link>
                  </ListItem>
                  <ListItem
                    className="linkednicon"
                    sx={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    <Link
                      target="_blank"
                      href={"https://www.linkedin.com/company/neet-navigator/"}
                    >
                      <img
                        src="/image/linkedin.png"
                        alt="linkedin"
                        style={{ width: "25px" }}
                      />
                    </Link>
                  </ListItem>
                  <ListItem
                    className="instaicon"
                    sx={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    <Link
                      target="_blank"
                      href={"https://www.instagram.com/NavigatorNeet/"}
                    >
                      <img
                        src="/image/instagram.png"
                        alt="instagram"
                        style={{ width: "25px" }}
                      />
                    </Link>
                  </ListItem>
                  <ListItem
                    className="twittericon"
                    sx={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    <Link
                      target="_blank"
                      href={"https://twitter.com/NavigatorNeet"}
                    >
                      <img
                        src="/image/twitter.png"
                        alt="twitter"
                        style={{ width: "25px" }}
                      />
                    </Link>
                  </ListItem>
                </List>
              </Grid>
              <Grid item sm={4} xs={11} mt={3} className="footer2">
                <Typography
                  variant="h5"
                  component="h2"
                  sx={{ fontWeight: 600, lineHeight: "60px" }}
                >
                  Important Links
                </Typography>
                <List>
                  <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Link
                      target="_blank"
                      href={"https://neetnavigator.com/medical-counselling"}
                    >
                      Medical Admission Counselling
                    </Link>
                  </ListItem>
                  <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Link
                      target="_blank"
                      href={
                        "https://neetnavigator.com/testimonials"
                      }
                    >
                      Success Stories
                    </Link>
                  </ListItem>
                  <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Link
                      target="_blank"
                      href={"https://neetnavigator.com/articles"}
                    >
                      Blogs
                    </Link>
                  </ListItem>
                  <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Link
                      target="_blank"
                      href={"https://neetnavigator.com/aboutUs"}
                    >
                      About Us
                    </Link>
                  </ListItem>
                  <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Link
                      target="_blank"
                      href={"https://neetnavigator.com/contactUs"}
                    >
                      Contact us
                    </Link>
                  </ListItem>
                </List>
              </Grid>
              <Grid item sm={3} xs={11} mt={3} className="footer3">
                <Typography
                  variant="h5"
                  component="h2"
                  sx={{ fontWeight: 600, lineHeight: "60px" }}
                >
                  Our Services
                </Typography>
                <List>
                  <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Link
                      target="_blank"
                      href={"https://neetnavigator.com/lighthouse"}
                    >
                      MBBS Lighthouse
                    </Link>
                  </ListItem>
                  <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Link
                      target="_blank"
                      href={"https://neetnavigator.com/neet-ug"}
                    >
                      NEET UG Services
                    </Link>
                  </ListItem>
                  <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Link
                      target="_blank"
                      href={"https://neetnavigator.com/neet-pg"}
                    >
                      NEET PG Services
                    </Link>
                  </ListItem>
                </List>
                <Typography
                  variant="h5"
                  component="h2"
                  sx={{ fontWeight: 600, lineHeight: "60px" }}
                >
                  Quick Links
                </Typography>
                <List>
                  <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Link
                      target="_blank"
                      href={"https://neetnavigator.com/privacy-policy"}
                    >
                      Privacy Policy
                    </Link>
                  </ListItem>
                  <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Link
                      target="_blank"
                      href={"https://neetnavigator.com/term-and-condition"}
                    >
                      Terms And Conditions
                    </Link>
                  </ListItem>
                  <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Link
                      target="_blank"
                      href={"https://neetnavigator.com/refund-and-cancellation"}
                    >
                      Refund And Cancellation
                    </Link>
                  </ListItem>
                  <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Link
                      target="_blank"
                      href={"https://neetnavigator.com/term-of-use"}
                    >
                      Term of Use
                    </Link>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "static",
          bottom: 0,
          width: "100%",
        }}
      >
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: "auto",
            backgroundColor: "#261555",
          }}
        >
          <Container maxWidth="sm">
            <Copyright />
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
