import React from 'react'
import { Box, Fade, Grid, Modal, styled, Typography } from "@mui/material";

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "400px", md: "400px", sm: "300px", xs: "300px" },
  height: { lg: "100px", md: "100px", sm: "100px", xs: "100px" },
  overflow: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 1.5,

  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "0em",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: "1em",
  },
};

const ComingSoon = ({ tcModalOpen, setTcModalOpen }) => {
  const handleTcModalClose = () => setTcModalOpen(false);

  return (
    <>
      {
        <StyledModal
          open={tcModalOpen}
          onClose={handleTcModalClose}
          closeAfterTransition={false}
        >
          <Fade in={tcModalOpen}>
            <Box sx={style}>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="h3"
                  component="h2"
                  m="5px 0px 10px 0px"
                  sx={{
                    fontSize: {
                      lg: "22px",
                      md: "22px",
                      sm: "17px",
                      xs: "17px",
                    },
                    color: "#1e439b",
                    lineHeight: 1.5,
                    textAlign: "center",
                    display: "inline-block",
                    borderBottom: "3px solid #ff5501",
                  }}
                >
                  Note
                </Typography>

                <Typography
                  variant="p"
                  component="p"
                  textAlign="justify"
                  sx={{ fontSize: "16px", fontWeight: "500", color: "#181818" }}
                >
                  Rank Based Predictor is Coming Soon, Stay Connected.
                </Typography>
              </Grid>
            </Box>
          </Fade>
        </StyledModal>
      }
    </>
  );
};

export default ComingSoon