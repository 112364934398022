import React from "react";
import {
    Button,
  Container,
  Grid,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { tableCellClasses } from "@mui/material/TableCell";
import FaqServices from "../components/HomePageComponents/FaqServices";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";
import image from "../pgBannerBackground.png"; 


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: 0,
    fontSize: 14,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: 0,
    fontSize: 14,
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
    border: 0,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Services = () => {
    const navigate = useNavigate();
  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
        sx={{
          flexGrow: 1,
          width: "calc(100% + 48px)",
          backgroundImage: `url(${image})`,
        }}
        mt={2}
        mb={5}
      >
        <Grid item>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h3"
              component="h2"
              sx={{
                fontSize: { md: "30px", xs: "17px" },
                color: "#1e439b",
                lineHeight: 1.5,
                display: "inline-block",
                borderBottom: "3px solid #ff5501",
              }}
            >
              Illuminating your path to MBBS Admission
            </Typography>
            <Typography
              p={3}
              variant="p"
              component="p"
              textAlign="justify"
              sx={{
                fontSize: { md: "18px", xs: "14px" },
                lineHeight: 1.5,
              }}
            >
              Expand your horizon of MBBS admission with Your GPS to MBBS
              Admission. MBBS admission shall no longer remain limited by poor
              data analysis. MBBS Lighthouse offers most advanced data analytics
              based MBBS admission guidance.
            </Typography>
            <Typography
              pb={3}
              pl={3}
              pr={3}
              variant="p"
              textAlign="justify"
              component="p"
              sx={{
                fontSize: { md: "18px", xs: "14px" },
                lineHeight: 1.5,
              }}
            >
              MBBS Lighthouse is a boon for students and parents who want to
              navigate the MBBS admission counselling process with help of
              technology. MBBS Lighthouse is integrating all India data from MCC
              and 32 state counselling to offer personalized guidance.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h3"
              component="h2"
              sx={{
                fontSize: { md: "30px", xs: "17px" },
                color: "#1e439b",
                lineHeight: 1.5,
                display: "inline-block",
                borderBottom: "3px solid #ff5501",
              }}
            >
              Services
            </Typography>

            <List
              sx={{
                fontFamily: ["Poppins", "sans-serif"].join(","),
                listStyleType: "disc",
                fontSize: { lg: "18px", md: "18px", sm: "14px", xs: "14px" },
                pl: 2,
                ml: 3,
                "& .MuiListItem-root": {
                  display: "list-item",
                },
              }}
            >
              <ListItem sx={{ textAlign: "justify" }}>
                Free NEET Rank Predictor: Estimates All India Rank for a given
                NEET score on basis of {new Date().getFullYear() - 1} NEET
                scores
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Free MBBS possibility estimator: Count of medical colleges where
                MBBS admission is possible for a given NEET score.
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Marks based medical college predictor: Detailed college report
                consisting name, type of seat, fee, service bond and rating of
                college to help the user plan MBBS admission.
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Rank based MBBS college predictor: Most comprehensive report
                focusing on AIR rank, domicile and category of student to assess
                MBBS colleges and counselling types.
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Medical colleges choice list: A curated colleges choice list for
                optimizing MCC and state admission counselling. This feature
                helps in reaching the best medical college.
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Typography
              variant="h3"
              component="h2"
              sx={{
                fontSize: { md: "30px", xs: "17px" },
                color: "#1e439b",
                mb: 5,
                mt: 2,
                lineHeight: 1.5,
                display: "inline-block",
                borderBottom: "3px solid #ff5501",
              }}
            >
              MBBS Lighthouse versus Others
            </Typography>
            <Stack sx={{ paddingRight: "5px" }}>
              <TableContainer component={Paper}>
                <Table
                  aria-label="customized table"
                  sx={{
                    borderBottom: "2px solid #1366ce",
                    borderRight: "2px solid #1366ce",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Feature</StyledTableCell>
                      <StyledTableCell>MBBS Lighthouse</StyledTableCell>
                      <StyledTableCell>Other Applications</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell>Comprehensive</StyledTableCell>
                      <StyledTableCell>Yes</StyledTableCell>
                      <StyledTableCell>Limited</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>Complete</StyledTableCell>
                      <StyledTableCell>Yes</StyledTableCell>
                      <StyledTableCell>No</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>Customized</StyledTableCell>
                      <StyledTableCell>Yes</StyledTableCell>
                      <StyledTableCell>Not possible</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>Curated</StyledTableCell>
                      <StyledTableCell>Yes</StyledTableCell>
                      <StyledTableCell>No</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>Cost effective</StyledTableCell>
                      <StyledTableCell>Yes</StyledTableCell>
                      <StyledTableCell>No or expensive</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>Consistent</StyledTableCell>
                      <StyledTableCell>Yes</StyledTableCell>
                      <StyledTableCell>Inconsistent</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>Cutting edge</StyledTableCell>
                      <StyledTableCell>Yes</StyledTableCell>
                      <StyledTableCell>Outdated technology</StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
            <Typography
              variant="h3"
              component="h2"
              sx={{
                fontSize: { md: "30px", xs: "17px" },
                color: "#1e439b",
                mb: 5,
                mt: 3,
                lineHeight: 1.5,
                display: "inline-block",
                borderBottom: "3px solid #ff5501",
              }}
            >
              Service Packages
            </Typography>
            <Stack sx={{ paddingRight: "5px" }}>
              <TableContainer component={Paper}>
                <Table
                  aria-label="customized table"
                  sx={{
                    borderBottom: "2px solid #1366ce",
                    borderRight: "2px solid #1366ce",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Service Package</StyledTableCell>
                      <StyledTableCell>States</StyledTableCell>
                      <StyledTableCell>Price</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell>1. NEET Rank Predictor</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>Free</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>
                        2. MBBS Possibility Estimator
                      </StyledTableCell>
                      <StyledTableCell>All India</StyledTableCell>
                      <StyledTableCell>Free</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>
                        3. Marks Based Medical College Predictor
                      </StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell rowSpan={4} sx={{ textAlign: "center" }}>
                        1 Mark
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>1 State</StyledTableCell>
                      <StyledTableCell>₹199</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>2 States</StyledTableCell>
                      <StyledTableCell>₹299</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>All India</StyledTableCell>
                      <StyledTableCell>₹499</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell sx={{ textAlign: "center" }}>
                        2 Marks
                      </StyledTableCell>
                      <StyledTableCell>All India</StyledTableCell>
                      <StyledTableCell>₹699</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell sx={{ textAlign: "center" }}>
                        4 Marks
                      </StyledTableCell>
                      <StyledTableCell>All India</StyledTableCell>
                      <StyledTableCell>₹899</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>
                        4. Rank Based MBBS College Predictor
                      </StyledTableCell>
                      <StyledTableCell>All India</StyledTableCell>
                      <StyledTableCell>₹999</StyledTableCell>
                    </StyledTableRow>
                    {/*<StyledTableRow>
                      <StyledTableCell>
                        5. Medical College Choice Report
                      </StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell sx={{ textAlign: "center" }}>
                        Per State
                      </StyledTableCell>
                      <StyledTableCell>Govt/Private</StyledTableCell>
                      <StyledTableCell>₹1499</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell sx={{ textAlign: "center" }}>
                        MCC
                      </StyledTableCell>
                      <StyledTableCell>AIQ/ Deemed</StyledTableCell>
                      <StyledTableCell>₹1999</StyledTableCell>
                </StyledTableRow>*/}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Grid>
        </Grid>
        <Grid item sx={{ paddingRight: "24px" }}>
          <Grid mt={4} mb={3}>
            <FaqServices />
          </Grid>
          <Grid item md={12} sm={12} lg={12} xs={12}>
            <Stack md={12} sm={12} lg={12} xs={12}>
              <Button
                className="search-btn"
                size={"small"}
                fullWidth
                sx={{
                  fontSize: { lg: "25px", md: "25px", xs: "16px" },
                  border: "1px solid #1366ce",
                  outline: "none",
                  cursor: "pointer",
                  mt: 1,
                  mb: 1,
                  color: "#fff",
                  backgroundColor: "#1366ce",
                }}
                onClick={(e) => {
                  navigate("/student-marks");
                }}
              >
                {
                  `Click here to check your MBBS admission possibility in NEET ${new Date().getFullYear()}`
                }
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Services;
