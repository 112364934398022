import React from "react";
import { Stack, Typography } from "@mui/material";
import Faq from "react-faq-component";

const data = {
  // title: "FAQ (How it works)",
  rows: [
    {
      title:
        "How often are the admission probability reports and NEET rank predictions updated?",
      content:
        "MBBS Lighthouse data is updated on yearly basis. NEET Navigator is pioneer in the field of integrating advanced data analytics into the field of medical admission counselling guidance in India.",
    },
    {
      title:
        "Can MBBS Lighthouse predict my chances of getting into a specific medical college?",
      content:
        "You can use MBBS Lighthouse for Free Rank prediction, Marks & NEET Rank based MBBS admission possibility estimation and also use college specific query in student account section to check chances into a specific medical college. ",
    },
    {
      title: "How does MBBS Lighthouse predict NEET rank?",
      content:
        `Rank prediction is based on all rank data of ${new Date().getFullYear()-1} NEET result. This rank data has been derived from machine learning by feeding in about 1,50,000 data entries. The rank predictor has been tested for over 95% accuracy for NEET scores less than 680.`,
    },
    {
      title: "Is MBBS Lighthouse available for use in all states in India?",
      content:
        "Yes, MBBS Lighthouse is designed to include MCC and all state medical admission counselling data. Therefore MBBS Lighthouse can be used by students across India. MBBS Lighthouse is the only such application which offers all India usability. ",
    },
    {
      title: "Can I share my subscription plan with my friends? ",
      content:
        "No. MBBS Lighthouse gives you specific admission possibility estimation for your NEET Score, Domicile & Category. If you are planning to buy multiple marks subscription model, you will be able to change ONLY your marks. You cannot change category and domicile.",
    },
    {
      title:
        "Is there a limit to the number of times I can use MBBS Lighthouse to predict admission probabilities or NEET ranks?",
      content:
        "You can use MBBS Lighthouse as many times as you may wish. All your previous search reports are archived in the student’s account section. Most students use MBBS Lighthouse on 4 occasions: at time of full course practice tests, after NEET UG, after publishing of your OMR and official answer keys and on receipt of their NEET Rank.",
    },
    {
      title:
        "How can I contact customer support if I have any questions or concerns about using MBBS Lighthouse?",
      content:
        "MBBS Lighthouse customer support can be reached at +91-9717366344 on all days between 1000-1800 Hrs. You can also contact customer support through email at lighthouse@neetnavigator.com",
    },
  ],
};


const styles = {
  titleTextColor: "#000",
  rowTitleColor: "#052F5F",
  rowTitleTextSize: "18px",
  rowContentTextSize: "14px",
  rowContentColor: "#1e439b",
  arrowColor: "#052F5F",
  rowContentPaddingTop: "10px",
  rowContentPaddingBottom: "10px",
};

const config = {
  animate: true,
  arrowIcon: "V",
  openOnload: 0,
  expandIcon: "+",
  collapseIcon: "-",
};

const FaqServices = () => {
  return (
    <Stack mb={5}>
      <Typography variant="h4" sx={{ fontWeight: 600, textAlign: "center" }}>
        FAQ's
      </Typography>
      <Stack sx={{ fontFamily: ["Poppins", "sans-serif"].join(",") }}>
        <Faq data={data} styles={styles} config={config} />
      </Stack>
    </Stack>
  );
}

export default FaqServices;
