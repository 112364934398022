import universityIcon from "../../assets/university.svg";
import cashIcon from "../../assets/cash.svg";
import mockTestIcon from "../../assets/mockTest.svg";
import supportIcon from "../../assets/support.svg";
import "./style.css";

export default function ServicesList() {
    return (<>

        <div className="mx-32 my-6 md:my-10 flex flex-row md:flex-row justify-center border-b border-t border-solid border-[#1366cf] custom-cta">
               <a target="_blank" href={"https://neetnavigator.com/about-medical-colleges"} className="p-4 flex flex-col items-center hover:text-[#1366cf]">
                   <img src={universityIcon} alt="Colleges Info" width="62px"/>
                   <p className="mt-4 text-lg md:text-xl font-semibold my-2 text-center">Colleges Info</p>
               </a>
               <a target="_blank" href={"https://neetnavigator.com/mbbs-fee-structure"} className="p-4 cursor-pointer flex flex-col items-center hover:text-[#1366cf]">
                   <img src={cashIcon} alt="College Fees" width="62px"/>
                   <p className="mt-4 text-lg md:text-xl font-semibold my-2 text-center">College Fees</p>
               </a>
               <a target="_blank" href={"https://neetnavigator.com/mbbs-admission-cutoff"} className="p-4 cursor-pointer flex flex-col items-center hover:text-[#1366cf]">
                   <img src={mockTestIcon} alt="College Cut-off" width="62px"/>
                   <p className="mt-4 text-lg md:text-xl font-semibold my-2 text-center">College Cut-off</p>
               </a>
               <a target="_blank" href={"https://neetnavigator.com/neet-ug-rank-predictor"} className="p-4 cursor-pointer flex flex-col items-center hover:text-[#1366cf]">
                   <img src={supportIcon} alt="Rank Predictor" width="62px"/>
                   <p className="mt-4 text-lg md:text-xl font-semibold my-2 text-center">Rank Predictor</p>
               </a>
        </div>
    </>)
}