import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";

export const createStudent = ({
  userId,
  dob,
  gender,
  year12th,
  year10th,
  neetYear,
  isPhCandidate,
  areaId,
  quotaId,
  minorityId,
  stateId10th,
  stateId12th,
  armedForceId,
  neetCategoryId,
  stateCategoryId,
}) => {
  return axios({
    method: "post",
    url: BASE_URL + "student",
    headers: getHeaders(),
    data: JSON.stringify({
      userId,
      dob,
      gender,
      year12th,
      year10th,
      neetYear,
      isPhCandidate,
      areaId,
      quotaId,
      minorityId,
      stateId10th,
      stateId12th,
      armedForceId,
      neetCategoryId,
      stateCategoryId,
    }),
  });
};

export const updateStudent = (
  id,
  {
    dob,
    gender,
    year12th,
    year10th,
    neetYear,
    isPhCandidate,
    areaId,
    quotaId,
    minorityId,
    stateId10th,
    stateId12th,
    armedForceId,
    neetCategoryId,
    stateCategoryId,
  }
) => {
  return axios({
    method: "put",
    url: BASE_URL + "student/" + id,
    headers: getHeaders(),
    data: JSON.stringify({
      dob,
      gender,
      year12th,
      year10th,
      neetYear,
      isPhCandidate,
      areaId,
      quotaId,
      minorityId,
      stateId10th,
      stateId12th,
      armedForceId,
      neetCategoryId,
      stateCategoryId,
    }),
  });
};

export const getStudent = () => {
  return axios({
    method: "get",
    url: BASE_URL + "student",
    headers: getHeaders(),
  });
};

export const findStudent = (id) => {
  return axios({
    method: "get",
    url: BASE_URL + "student/" + id,
    headers: getHeaders(),
  });
};
