import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCoins, faHouse, faStarHalfAlt, faThumbsUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getInitData } from "../services/init-data";
import { getState } from "../services/states";
import { getCities } from "../services/city";
import { getUserId, setStudentId } from "../services/serviceToken";
import { createStudent } from "../services/student";
import { createAddress } from "../services/address";
import { useNavigate } from "react-router-dom";
import { pinCode } from "../components/Regex";
import { gender } from "../constants";
import { getAreaOfState } from "../services/areaOfState";
import { getStateCategoryOfState } from "../services/stateCategoryOfState";
import { findPincode } from "../services/pincodeApi";
import CollegesHeading from "../components/HomePageComponents/CollegesHeading";
import CollegeGraph from "./CollegeGraph";
import { errorMessage } from "../utils/alertMessage";
import { getUserCollegeCount } from "../services/colleges";
import ProgressBar from "../components/ProgressBar/ProgressBar";

const StudentDomicile = () => {
  const [yesNoData, setYesNoData] = useState({
    armedForceYesNo: "",
    minorityYesNo: "",
    quotaYesNo: "",
  });

  const [data, setData] = useState({
    userId: getUserId(),
    areaId: "",
    gender: "",
    cityId: "",
    pincode: "",
    stateId: "",
    quotaId: "",
    minorityId: "",
    stateId12th: "",
    stateId10th: "",
    armedForceId: "",
    isPhCandidate: "",
    neetCategoryId: "",
    stateCategoryId: "",
  });

  const navigate = useNavigate();

  const [state, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [collegeDataCount, setCollegeDataCount] = useState(0);
  // const [collegeStateData, setCollegeStateData] = useState([]);
  const [collegeCounts, setCollegeCounts] = useState({
    govtColleges: 0,
    privateColleges: 0,
    deemedColleges: 0,
  });

  const [pincodeData, setPincodeData] = useState([]);
  const [areaOfState, setAreaOfState] = useState([]);
  const [stateCategoryOfState, setStateCategoryOfState] = useState([]);
  const [searchedCity, setSearchedCity] = useState([]);
  const [searchedStateCategoryOfState, setSearchedStateCategoryOfState] =
    useState([{ state_category: { category: "" } }]);
  const [searchedAreaOfState, setSearchedAreaOfState] = useState([
    { area: { area: "" } },
  ]);
  const [loader, setLoader] = useState(true);

  const [initData, setInitData] = useState({
    area: [],
    neetCategory: [],
    stateCategory: [],
    armedForce: [],
    minority: [],
    quota: [],
  });
  const [error, setError] = useState({
    areaId: false,
    gender: false,
    cityId: false,
    pincode: false,
    stateId: false,
    quotaId: false,
    quotaYesNo: false,
    minorityId: false,
    minorityYesNo: false,
    stateId12th: false,
    stateId10th: false,
    armedForceId: false,
    armedForceYesNo: false,
    isPhCandidate: false,
    neetCategoryId: false,
    stateCategoryId: false,
  });
  const [errorHelperText, setErrorHelperText] = useState({
    areaId: "",
    gender: "",
    cityId: "",
    pincode: "",
    stateId: "",
    quotaId: "",
    quotaYesNo: "",
    minorityId: "",
    minorityYesNo: "",
    stateId12th: "",
    stateId10th: "",
    armedForceId: "",
    armedForceYesNo: "",
    isPhCandidate: "",
    neetCategoryId: "",
    stateCategoryId: "",
  });

  const [tcModalOpen, setTcModalOpen] = useState(false);
  const handleTcModalOpen = () => setTcModalOpen(true);

  const [stateDisabled, setStateDisabled] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    async function getData() {
      getState()
        .then((response) => {
          setState(response.data.result);
        })
        .catch((err) => {
          console.error("error", err);
        });
      getCities()
        .then((response) => {
          setCities(response.data.result);
        })
        .catch((err) => {
          console.error("error", err);
        });
      getAreaOfState().then((response) => {
        if (response.data.error === false) {
          setAreaOfState(response.data.result);
        }
      });
      getStateCategoryOfState().then((response) => {
        if (response.data.error === false) {
          setStateCategoryOfState(response.data.result);
        }
      });
      getInitData()
        .then((response) => {
          if (!response.data.error) {
            setInitData(response.data.result);
          }
        })
        .catch((err) => {
          console.error("error", err);
        });
    }
    getData();
  }, []);

  const handleCollegeGraph = (allColleges) => {
    const updatedCollegeCounts = {
      govtColleges: 0,
      privateColleges: 0,
      deemedColleges: 0,
    };
    allColleges.forEach((item) => {
      switch (item.institutionTypeId) {
        case 1:
          updatedCollegeCounts.govtColleges += item.count;
          break;
        case 2:
          updatedCollegeCounts.privateColleges += item.count;
          break;
        case 3:
          updatedCollegeCounts.deemedColleges += item.count;
          break;
        default:
          break;
      }
    });
    setCollegeCounts(updatedCollegeCounts);
    return updatedCollegeCounts.govtColleges + updatedCollegeCounts.privateColleges + updatedCollegeCounts.deemedColleges;
  };

  function getPincodeData() {
    if (data.pincode.length === 6 && pinCode.test(data.pincode)) {
      findPincode(data.pincode)
        .then((response) => response.json())
        .then((data) => {
          setPincodeData(data[0].PostOffice[0].State);
          setError({ pincode: false });
          setErrorHelperText({
            pincode: "",
          });
        })
        .catch((err) => {
          setStateDisabled(false);
          // console.error("error", err);
          // setError({ pincode: true });
          // setErrorHelperText({
          //   pincode: "Please enter correct pincode",
          // });
        });
    } else {
      setPincodeData([]);
      setData({ ...data, stateId: "", cityId: "", areaId: "" });
      setSearchedCity([]);
    }
  }

  useEffect(() => {
    async function getGraphData(queryData = {}) {
      setLoader(true);
      getUserCollegeCount(queryData)
        .then((response) => {
          if (!response.data.error) {
            const totalColleges = handleCollegeGraph(response.data.result);
            setCollegeDataCount(totalColleges);
          }
        })
        .catch((err) => {
          console.error("error", err);
        })
        .finally(() => setLoader(false));
    }

    const query = {};
    // const {pincode, stateId, ...restData} = data;
    // const myData = {
    //   ...restData,
    //   abc: '123'
    // };
    if (data.stateId10th) {
      query.stateId10th = data.stateId10th;
    }
    if (data.stateId12th) {
      query.stateId12th = data.stateId12th;
    }
    // if (data.pincode && data.pincode.length === 6) {
    //   query.pincode = data.pincode;
    // }
    if (data.stateId) {
      query.stateId = data.stateId;
    }
    if (data.areaId) {
      query.areaId = data.areaId;
    }
    if (data.neetCategoryId) {
      query.neetCategoryId = data.neetCategoryId;
    }
    if (data.stateCategoryId) {
      query.stateCategoryId = data.stateCategoryId;
    }
    if (data.gender) {
      query.gender = data.gender;
    }
    if (data.isPhCandidate) {
      query.isPhCandidate = data.isPhCandidate;
    }
    if (data.armedForceId || yesNoData.armedForceYesNo === "No") {
      query.armedForceId = data.armedForceId;
    }
    if (data.minorityId || yesNoData.minorityYesNo === "No") {
      query.minorityId = data.minorityId;
    }
    if (data.quotaId || yesNoData.quotaYesNo === "No") {
      query.quotaId = data.quotaId;
    }
    getGraphData(query);
  }, [
    data.stateId10th,
    data.stateId12th,
    // data.pincode,
    data.stateId,
    data.areaId,
    data.neetCategoryId,
    data.stateCategoryId,
    data.gender,
    data.isPhCandidate,
    data.armedForceId,
    yesNoData.armedForceYesNo,
    data.minorityId,
    yesNoData.minorityYesNo,
    data.quotaId,
    yesNoData.quotaYesNo,
  ]);

  useEffect(() => {
    getPincodeData();
  }, [data.pincode]);

  useEffect(() => {
    if (yesNoData.armedForceYesNo === "No") {
      setData({ ...data, armedForceId: "" });
    }
  }, [yesNoData.armedForceYesNo]);
  useEffect(() => {
    if (yesNoData.minorityYesNo === "No") {
      setData({ ...data, minorityId: "" });
    }
  }, [yesNoData.minorityYesNo]);
  useEffect(() => {
    if (yesNoData.quotaYesNo === "No") {
      setData({ ...data, quotaId: "" });
    }
  }, [yesNoData.quotaYesNo]);

  useEffect(() => {
    if (pincodeData.length) {
      const availablePincodeState = state.find((s) => s.name === pincodeData);
      if (pincodeData.length && availablePincodeState) {
        setData({ ...data, stateId: availablePincodeState.id });
        setStateDisabled(true);
      } else {
        setData({ ...data, stateId: "" });
      }
      const availableStateOfArea = areaOfState.filter(
        (s) => s.stateId === availablePincodeState.id
      );
      const availableStateCategoryOfState = stateCategoryOfState.filter(
        (s) => s.stateId === availablePincodeState.id
      );
      const filterAvailableStateCategoryOfState =
        availableStateCategoryOfState.filter((element) => {
          return (
            element.state_category.category !== "Unreserved" &&
            element.state_category.category !== "Open"
          );
        });
      if (availablePincodeState) {
        const searchCity = cities.filter(
          (c) => c.stateCode === availablePincodeState.code
        );
        setSearchedCity(searchCity);
      }
      setSearchedAreaOfState(availableStateOfArea);
      setSearchedStateCategoryOfState(filterAvailableStateCategoryOfState);
    }
  }, [pincodeData]);

  useEffect(() => {
    if (data.stateId) {
      const availablePincodeState = state.find((s) => s.id === data.stateId);
      // if (pincodeData.length && availablePincodeState) {
      //   setData({ ...data, stateId: availablePincodeState.id });
      // } else {
      //   setData({ ...data, stateId: "" });
      // }
      const availableStateOfArea = areaOfState.filter(
        (s) => s.stateId === availablePincodeState.id
      );
      const availableStateCategoryOfState = stateCategoryOfState.filter(
        (s) => s.stateId === availablePincodeState.id
      );
      const filterAvailableStateCategoryOfState =
        availableStateCategoryOfState.filter((element) => {
          return (
            element.state_category.category !== "Unreserved" &&
            element.state_category.category !== "Open"
          );
        });
      if (availablePincodeState) {
        const searchCity = cities.filter(
          (c) => c.stateCode === availablePincodeState.code
        );
        setSearchedCity(searchCity);
      }
      setSearchedAreaOfState(availableStateOfArea);
      setSearchedStateCategoryOfState(filterAvailableStateCategoryOfState);
    }
  }, [data.stateId]);

  const filterArea = searchedAreaOfState.filter((element) => {
    return element.area.area !== "All";
  });

  const neetCat = initData.neetCategory.filter((element) => {
    return element.category !== "Unreserved" && element.category !== "Open";
  });

  const handleStudentForm = async (event) => {
    event.preventDefault();
    if (!data.stateId10th) {
      setError({ stateId10th: true });
      setErrorHelperText({
        stateId10th: "Please select your 10th passing state",
      });
    } else if (!data.stateId12th) {
      setError({ stateId12th: true });
      setErrorHelperText({
        stateId12th: "Please enter your 12th passing state",
      });
    } else if (!data.pincode) {
      setError({ pincode: true });
      setErrorHelperText({
        pincode: "Please enter your 6 digit area pincode",
      });
    } else if (!pinCode.test(data.pincode)) {
      setError({ pincode: true });
      setErrorHelperText({
        pincode: "Please enter correct pincode",
      });
      // } else if (!data.areaId) {
      //   setError({ areaId: true });
      //   setErrorHelperText({
      //     areaId: "Please select your area",
      //   });
    } else if (!data.neetCategoryId) {
      setError({ neetCategoryId: true });
      setErrorHelperText({
        neetCategoryId: "Please select your NEET category",
      });
    } else if (!data.stateCategoryId) {
      setError({ stateCategoryId: true });
      setErrorHelperText({
        stateCategoryId: "Please select your state category",
      });
    } else if (!data.stateId) {
      setError({ stateId: true });
      setErrorHelperText({ stateId: "Please select your state" });
    } else if (!data.cityId) {
      setError({ cityId: true });
      setErrorHelperText({ cityId: "Please select your city" });
    } else if (!data.gender) {
      setError({ gender: true });
      setErrorHelperText({ gender: "Please select your gender" });
    } else if (!data.isPhCandidate) {
      setError({ isPhCandidate: true });
      setErrorHelperText({
        isPhCandidate: "Please select any one of these",
      });
    } else if (!yesNoData.armedForceYesNo) {
      setError({ armedForceYesNo: true });
      setErrorHelperText({
        armedForceYesNo: "Please select any one of these",
      });
    } else if (yesNoData.armedForceYesNo === "Yes" && !data.armedForceId) {
      setError({ armedForceId: true });
      setErrorHelperText({
        armedForceId: "Please select any one of these",
      });
    } else if (!yesNoData.minorityYesNo) {
      setError({ minorityYesNo: true });
      setErrorHelperText({
        minorityYesNo: "Please select any one of these",
      });
    } else if (yesNoData.minorityYesNo === "Yes" && !data.minorityId) {
      setError({ minorityId: true });
      setErrorHelperText({
        minorityId: "Please select any one of these",
      });
    } else if (!yesNoData.quotaYesNo) {
      setError({ quotaYesNo: true });
      setErrorHelperText({
        quotaYesNo: "Please select any one of these",
      });
    } else if (yesNoData.quotaYesNo === "Yes" && !data.quotaId) {
      setError({ quotaId: true });
      setErrorHelperText({
        quotaId: "Please select any one of these",
      });
    } else {
      setError({
        areaId: false,
        gender: false,
        cityId: false,
        pincode: false,
        stateId: false,
        quotaId: false,
        quotaYesNo: false,
        minorityId: false,
        minorityYesNo: false,
        stateId12th: false,
        stateId10th: false,
        armedForceId: false,
        armedForceYesNo: false,
        isPhCandidate: false,
        neetCategoryId: false,
        stateCategoryId: false,
      });
      setErrorHelperText({
        areaId: "",
        gender: "",
        cityId: "",
        pincode: "",
        stateId: "",
        quotaId: "",
        quotaYesNo: "",
        minorityId: "",
        minorityYesNo: "",
        stateId12th: "",
        stateId10th: "",
        armedForceId: "",
        armedForceYesNo: "",
        isPhCandidate: "",
        neetCategoryId: "",
        stateCategoryId: "",
      });

      createStudent({
        ...data,
        areaId: data.areaId === "" ? null : data.areaId,
        quotaId: data.quotaId === "" ? null : data.quotaId,
        minorityId: data.minorityId === "" ? null : data.minorityId,
        armedForceId: data.armedForceId === "" ? null : data.armedForceId,
      })
        .then((response) => {
          if (!response.data.error) {
            createAddress({
              studentId: response.data.result.id,
              stateId: data.stateId,
              cityId: data.cityId,
              pincode: data.pincode,
            })
              .then((response) => {
                if (!response.data.error) {
                  const studentId = response.data.result.studentId;
                  setStudentId(studentId);
                  // for Choose your predictor
                  // handleTcModalOpen();
                  //
                  // Navigation of student-marks
                  navigate("/", {
                    replace: true,
                  });
                }
              })
              .catch((err) => {
                errorMessage(err.errors.message);
              });
          } else {
            if (
              response.data.errors.errors[0].message === "userId must be unique"
            ) {
              errorMessage("User Data is already present");
            } else {
              errorMessage();
            }
          }
        })
        .catch((err) => {
          errorMessage(err.errors.message);
        });
    }
  };


    return (
      <Container maxWidth="xl">
          {/* Progres bar  */}
          <ProgressBar/>
          
      <Grid container spacing={3} sx={{ flexGrow: 1 }}>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            paddingLeft: "0px",
          }}
        >
        


          <CollegesHeading collegeDataCount={collegeDataCount} />
          <Grid
            item
            xs={0}
            md={4}
            sx={{ position: "sticky", top: 0, height: 530 }}
          >
            {loader ? (
              <Box
                component="img"
                src="/image/ezgif.com-resize.gif"
                alt=""
                sx={{
                  height: {
                    lg: "500px",
                    md: "500px",
                    sm: "400px",
                    xs: "400px",
                  },
                  width: "auto",
                }}
              />
            ) : (
              <CollegeGraph
                graphData={[
                  collegeCounts.govtColleges,
                  collegeCounts.privateColleges,
                  collegeCounts.deemedColleges,
                ]}
              />
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} md={7}>
          <Stack
            className="college-form-heading"
            display="inline-block"
            spacing={2}
            mb={2}
          >
            <Typography
              variant="h3"
              component="h2"
              sx={{
                fontSize: { md: "30px", xs: "20px" },
                color: "#1e439b",
                lineHeight: 1.5,
                display: "inline-block",
                borderBottom: "3px solid #ff5501",
              }}
            >
              Enter your Domicile
            </Typography>
          </Stack>
          <Box
            component="form"
            noValidate
            onSubmit={handleStudentForm}
            sx={{ mt: 3 }}
          >
            <Stack mt={1} mb={2}>
              <TextField
                autoComplete="given-name"
                id="stateId10th"
                select
                name="stateId10th"
                label="10th Passing State"
                className="input-field"
                required
                fullWidth
                error={error.stateId10th}
                helperText={errorHelperText.stateId10th}
                value={data.stateId10th}
                onChange={(e) =>
                  setData({ ...data, stateId10th: e.target.value })
                }
                variant="outlined"
              >
                {state.map((states) => (
                  <MenuItem key={states.name + states.id} value={states.id}>
                    {states.name}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>

            <Stack mt={1} mb={2}>
              <TextField
                autoComplete="given-name"
                id="stateId12th"
                select
                name="stateId12th"
                className="input-field"
                label="12th Passing State"
                error={error.stateId12th}
                helperText={errorHelperText.stateId12th}
                value={data.stateId12th}
                onChange={(e) =>
                  setData({ ...data, stateId12th: e.target.value })
                }
                variant="outlined"
                required
                fullWidth
              >
                {state.map((states) => (
                  <MenuItem key={states.name + states.id} value={states.id}>
                    {states.name}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>

            <Stack mt={1} mb={2}>
              <TextField
                autoComplete="given-name"
                name="pincode"
                id="pincode"
                label="Pincode Based on Aadhaar"
                className="input-field"
                fullWidth
                type="number"
                required
                error={error.pincode}
                helperText={errorHelperText.pincode}
                value={data.pincode}
                onChange={(e) => {
                  if (e.target.value.toString().length <= 6) {
                    setData({ ...data, pincode: e.target.value });
                  }
                }}
                variant="outlined"
              />
            </Stack>

            <Stack direction="row" spacing={2} mt={1} mb={2}>
              {/*<TextField
                autoComplete="given-name"
                name="stateId"
                id="stateId"
                label="State based on area pincode"
                className="input-field"
                error={error.stateId}
                helperText={errorHelperText.stateId}
                value={pincodeData}
                variant="outlined"
                fullWidth
                readOnly
                required
              ></TextField>*/}
              <TextField
                autoComplete="given-name"
                name="stateId"
                required
                select
                fullWidth
                id="stateId"
                label="Domicile State"
                className="input-field"
                error={error.stateId}
                helperText={errorHelperText.stateId}
                value={data.stateId}
                onChange={(e) => {
                  setData({ ...data, stateId: e.target.value });
                }}
                variant="outlined"
                disabled={stateDisabled}
              >
                {state.length > 0 ? (
                  state.map((states) => (
                    <MenuItem key={states.name + states.id} value={states.id}>
                      {states.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key="a1" value="">
                    No state found
                  </MenuItem>
                )}
              </TextField>
              <TextField
                autoComplete="given-name"
                name="cityId"
                required
                select
                fullWidth
                error={error.cityId}
                helperText={errorHelperText.cityId}
                id="cityId"
                label="Select Your City"
                className="input-field"
                value={data.cityId}
                onChange={(e) => {
                  setData({ ...data, cityId: e.target.value });
                }}
                variant="outlined"
              >
                {searchedCity.length > 0 ? (
                  searchedCity.map((city) => (
                    <MenuItem key={city.name + city.id} value={city.id}>
                      {city.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key="a1" value="">
                    No cities found
                  </MenuItem>
                )}
              </TextField>
            </Stack>
            {filterArea.length !== 0 && (
              <Stack direction="row" spacing={2} mt={1} mb={2}>
                <TextField
                  autoComplete="given-name"
                  id="areaId"
                  select
                  name="areaId"
                  label="Area"
                  className="input-field"
                  required
                  fullWidth
                  error={error.areaId}
                  helperText={errorHelperText.areaId}
                  variant="outlined"
                  value={data.areaId}
                  onChange={(e) => {
                    setData({ ...data, areaId: e.target.value });
                  }}
                >
                  {filterArea.map((item) => (
                    <MenuItem
                      key={item.area.area + item.area.id}
                      value={item.area.id}
                    >
                      {item.area.area}
                    </MenuItem>
                  ))}
                  <MenuItem key="area1" value="12" selected>
                    None
                  </MenuItem>
                </TextField>
              </Stack>
            )}

            <Stack
              className="college-form-heading"
              display="inline-block"
              spacing={2}
              mb={2}
            >
              <Typography
                variant="h3"
                component="h2"
                sx={{
                  fontSize: { md: "30px", xs: "20px" },
                  fontWeight: 500,
                  color: "#1e439b",
                  lineHeight: 1.5,
                  display: "inline-block",
                  borderBottom: "3px solid #ff5501",
                }}
              >
                Enter your Category
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2} mt={1} mb={2}>
              <TextField
                autoComplete="given-name"
                id="neetCategoryId"
                select
                name="neetCategoryId"
                label="NEET Category"
                className="input-field"
                required
                fullWidth
                error={error.neetCategoryId}
                helperText={errorHelperText.neetCategoryId}
                variant="outlined"
                value={data.neetCategoryId}
                onChange={(e) => {
                  setData({ ...data, neetCategoryId: e.target.value });
                }}
              >
                {neetCat.map((neet) => (
                  <MenuItem key={neet.category + neet.id} value={neet.id}>
                    {neet.category}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>

            <Stack direction="row" spacing={2} mt={1} mb={2}>
              <TextField
                autoComplete="given-name"
                id="stateCategoryId"
                select
                name="stateCategoryId"
                label="State Category"
                className="input-field"
                required
                fullWidth
                error={error.stateCategoryId}
                helperText={errorHelperText.stateCategoryId}
                value={data.stateCategoryId}
                onChange={(e) =>
                  setData({ ...data, stateCategoryId: e.target.value })
                }
                variant="outlined"
              >
                {searchedStateCategoryOfState.map((item) => (
                  <MenuItem
                    key={item.state_category.category + item.state_category.id}
                    value={item.state_category.id}
                    selected
                  >
                    {item.state_category.category}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <Stack direction="row" spacing={2} mt={1} mb={2}>
              <TextField
                autoComplete="gender"
                id="gender"
                select
                name="gender"
                label="Gender"
                className="input-field"
                required
                fullWidth
                error={error.gender}
                helperText={errorHelperText.gender}
                value={data.gender}
                onChange={(e) => setData({ ...data, gender: e.target.value })}
                variant="outlined"
              >
                {gender.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <Stack direction="row" spacing={2} mt={1} mb={2}>
              <TextField
                autoComplete="isPhCandidate"
                id="isPhCandidate"
                select
                name="isPhCandidate"
                label="Whether Physically Handicapped Candidate ?"
                className="input-field"
                required
                fullWidth
                error={error.isPhCandidate}
                helperText={errorHelperText.isPhCandidate}
                value={data.isPhCandidate}
                onChange={(e) => {
                  setData({ ...data, isPhCandidate: e.target.value });
                }}
                variant="outlined"
              >
                <MenuItem key="1" value="1">
                  Yes
                </MenuItem>
                <MenuItem key="0" value="0">
                  No
                </MenuItem>
              </TextField>
            </Stack>
            <Stack direction="row" mt={1} mb={2} spacing={2}>
              <TextField
                autoComplete="armedForceYesNo"
                id="armedForceYesNo"
                select
                name="armedForceYesNo"
                label="Whether Belongs To Armed Force ?"
                className="input-field"
                required
                fullWidth
                error={error.armedForceYesNo}
                helperText={errorHelperText.armedForceYesNo}
                value={yesNoData.armedForceYesNo}
                onChange={(e) =>
                  setYesNoData({
                    ...yesNoData,
                    armedForceYesNo: e.target.value,
                  })
                }
                variant="outlined"
              >
                <MenuItem key="1" value="Yes">
                  Yes
                </MenuItem>
                <MenuItem key="2" value="No">
                  No
                </MenuItem>
              </TextField>

              {yesNoData.armedForceYesNo === "Yes" && (
                <TextField
                  autoComplete="armedForceId"
                  id="armedForceId"
                  select
                  name="armedForceId"
                  label="Please select below option *"
                  className="input-field"
                  fullWidth
                  error={error.armedForceId}
                  helperText={errorHelperText.armedForceId}
                  value={data.armedForceId}
                  onChange={(e) => {
                    setData({ ...data, armedForceId: e.target.value });
                  }}
                  variant="outlined"
                >
                  {initData.armedForce.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.armedForce}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Stack>

            <Stack direction="row" mt={1} mb={2} spacing={2}>
              <TextField
                autoComplete="minorityYesNo"
                id="minorityYesNo"
                select
                name="minorityYesNo"
                label="Whether Belongs To Minority ?"
                className="input-field"
                required
                fullWidth
                error={error.minorityYesNo}
                helperText={errorHelperText.minorityYesNo}
                value={yesNoData.minorityYesNo}
                onChange={(e) =>
                  setYesNoData({
                    ...yesNoData,
                    minorityYesNo: e.target.value,
                  })
                }
                variant="outlined"
              >
                <MenuItem key="1" value="Yes">
                  Yes
                </MenuItem>
                <MenuItem key="2" value="No">
                  No
                </MenuItem>
              </TextField>

              {yesNoData.minorityYesNo === "Yes" && (
                <TextField
                  autoComplete="minorityId"
                  id="minorityId"
                  select
                  name="minorityId"
                  label="Please select below option *"
                  className="input-field"
                  fullWidth
                  error={error.minorityId}
                  helperText={errorHelperText.minorityId}
                  value={data.minorityId}
                  onChange={(e) => {
                    setData({ ...data, minorityId: e.target.value });
                  }}
                  variant="outlined"
                >
                  {initData.minority.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.minority}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Stack>
            <Stack direction="row" mt={1} mb={2} spacing={2}>
              <TextField
                autoComplete="quotaYesNo"
                id="quotaYesNo"
                select
                name="quotaYesNo"
                label="Any quota (NRI/ESI/NCC/Sports)?"
                className="input-field"
                required
                fullWidth
                error={error.quotaYesNo}
                helperText={errorHelperText.quotaYesNo}
                value={yesNoData.quotaYesNo}
                onChange={(e) =>
                  setYesNoData({
                    ...yesNoData,
                    quotaYesNo: e.target.value,
                  })
                }
                variant="outlined"
              >
                <MenuItem key="1" value="Yes">
                  Yes
                </MenuItem>
                <MenuItem key="2" value="No">
                  No
                </MenuItem>
              </TextField>

              {yesNoData.quotaYesNo === "Yes" && (
                <TextField
                  autoComplete="quotaId"
                  id="quotaId"
                  select
                  name="quotaId"
                  label="Please select below option *"
                  className="input-field"
                  fullWidth
                  error={error.quotaId}
                  helperText={errorHelperText.quotaId}
                  value={data.quotaId}
                  onChange={(e) => {
                    setData({ ...data, quotaId: e.target.value });
                  }}
                  variant="outlined"
                >
                  {initData.quota.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.quota}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Stack>
            <Stack md={12} sm={12} lg={12} xs={12}>
              <Button
                type="submit"
                className="search-btn"
                sx={{
                  fontSize: "25px",
                  padding: "22px",
                  border: "1px solid #1366ce",
                  outline: "none",
                  cursor: "pointer",
                  mt: 2,
                  mb: 4,
                  color: "#fff",
                  backgroundColor: "#1366ce",
                  height: "50px",
                }}
              >
                Next
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default StudentDomicile;
