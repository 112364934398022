import React from 'react'
import {
  Card,
  Grid,
} from "@mui/material";
import { CardMedia } from "@mui/material";

const VideoSection = () => {
  return (
    <React.Fragment>
      <Grid
        sx={{
          maxWidth: "930px",
          display: {
            lg: "flex",
            md: "flex",
            sm: "content",
            xs: "content",
          },
          textAlign: "center",
          justifyContent: {
            lg: "space-around",
            md: "space-around",
            sm: "content",
            xs: "content",
          },
          overflow: "hidden",
          margin: "0 auto",
          paddingBottom: "20px",
        }}
      >
        <Card
          sx={{
            marginTop: "10px",
            width: { lg: 445, md: 445, sm: 360, xs: 360 },
          }}
        >
          <CardMedia
            component="iframe"
            className="MuiCardMedia-media"
            sx={{ height: { lg: 260, md: 260, sm: 200, xs: 200 } }}
            image="https://www.youtube.com/embed/9FxSEwKP_QM"
            title="MBBS Lighthouse"
          />
        </Card>

        <Card
          sx={{
            marginTop: "10px",
            width: { lg: 445, md: 445, sm: 360, xs: 360 },
          }}
        >
          <CardMedia
            component="iframe"
            className="MuiCardMedia-media"
            sx={{ height: { lg: 260, md: 260, sm: 200, xs: 200 } }}
            image="https://www.youtube.com/embed/Bf4Dg9NmvlM"
            title="MBBS Lighthouse2"
          />
        </Card>
      </Grid>
      <Grid
        sx={{
          maxWidth: "930px",
          display: {
            lg: "flex",
            md: "flex",
            sm: "content",
            xs: "content",
          },
          textAlign: "center",
          justifyContent: {
            lg: "space-around",
            md: "space-around",
            sm: "content",
            xs: "content",
          },
          overflow: "hidden",
          margin: "0 auto",
          paddingBottom: "20px",
        }}
      >
        <Card
          sx={{
            marginTop: "10px",
            width: { lg: 445, md: 445, sm: 360, xs: 360 },
          }}
        >
          <CardMedia
            component="iframe"
            className="MuiCardMedia-media"
            sx={{ height: { lg: 294, md: 294, sm: 200, xs: 200 } }}
            image="https://www.youtube.com/embed/A-xbNTwAdGA"
            title="MBBS Lighthouse2"
          />
        </Card>
      </Grid>
    </React.Fragment>
  );
}

export default VideoSection