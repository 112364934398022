import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";

export const newOrder = ({ amount, billing_name, redirect_url }) => {
  return axios({
    method: "post",
    url: BASE_URL + "payment/new-order",
    headers: getHeaders(),
    data: JSON.stringify({
      amount,
      billing_name,
      redirect_url,
    }),
  });
};

export const updateOrder = (id, { tracking_id
, bank_ref_no
, order_status
, failure_message
, payment_mode
, card_name
, status_code
, status_message
, currency
, billing_name
, billing_address
, billing_city
, billing_state
, billing_zip
, billing_country
, billing_tel
, billing_email
, delivery_name
, delivery_address
, delivery_city
, delivery_state
, delivery_zip
, delivery_country
, delivery_tel
, merchant_param1
, merchant_param2
, merchant_param3
, merchant_param4
, merchant_param5
, vault
, offer_type
, offer_code
, discount_value
, mer_amount
, eci_value
, retry
, response_code
, billing_notes
, trans_date
, bin_country
 }) => {
  return axios({
    method: "put",
    url: BASE_URL + "payment/" + id,
    data: JSON.stringify({ tracking_id
, bank_ref_no
, order_status
, failure_message
, payment_mode
, card_name
, status_code
, status_message
, currency
, billing_name
, billing_address
, billing_city
, billing_state
, billing_zip
, billing_country
, billing_tel
, billing_email
, delivery_name
, delivery_address
, delivery_city
, delivery_state
, delivery_zip
, delivery_country
, delivery_tel
, merchant_param1
, merchant_param2
, merchant_param3
, merchant_param4
, merchant_param5
, vault
, offer_type
, offer_code
, discount_value
, mer_amount
, eci_value
, retry
, response_code
, billing_notes
, trans_date
, bin_country

}),
  });
};

export const getOrder = () => {
  return axios({
    method: "get",
    url: BASE_URL + "payment",
    headers: getHeaders(),
  });
};

export const getSuccessPaymentDetail = () => {
  return axios({
    method: "get",
    url: BASE_URL + "payment/payment-detail?order_status=Success",
    headers: getHeaders(),
  });
};

export const findNewOrder = (id) => {
  return axios({
    method: "get",
    url: BASE_URL + "payment/" + id,
    headers: getHeaders(),
  });
};

export const getPaymentDetail = () => {
  return axios({
    method: "get",
    url: BASE_URL + "payment/payment-detail",
    headers: getHeaders(),
  });
};
