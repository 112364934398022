import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, Fade, Grid, Modal, Stack, styled, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import {EmailInfo, FullNameInfo, MobileInfo, OtpInfo} from '../PopOverInfo/RegistrationInfo';
import {validatePhone, validateFullName, validEmail} from "../Regex";
import {createUserEnquiry} from '../../services/userEnquiry';
import {sendOTP, verifyOTP} from '../../services/auth';
import { getUserId } from "../../services/serviceToken";
import { findUser } from "../../services/user";

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "700px", md: "700px", sm: "340px", xs: "340px" },
  height: { lg: "450px", md: "450px", sm: "250px", xs: "250px" },
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,

  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "0em",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: "1em",
  },
};

const ServiceEnquiryModel = ({ modalOpen, setModalOpen, packageName, price, successPath, noRedirect = false }) => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    mobile: "",
    otp: "",
    enquiryType: packageName,
  });
  
  const [isMobile, setIsMobile] = useState(false);
  const [errorFullName, setErrorFullName] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorMobileNumber, setErrorMobileNumber] = useState(false);
  const [errorOtp, setErrorOtp] = useState(false);
  const [errorHelperTextFullName, setErrorHelperTextFullName] = useState("");
  const [errorHelperTextEmail, setErrorHelperTextEmail] = useState("");
  const [errorHelperTextMobileNumber, setErrorHelperTextMobileNumber] = useState("");
  const [errorHelperTextOtp, setErrorHelperTextOtp] = useState("");
  const [isMobileVerified, setIsMobileVerified] = useState(false);

  useEffect(() => {
    const userId = getUserId();
    if (!!userId)
    {
      findUser(userId)
      .then((response) => {
        if (response.data.result && !response.data.result.error) {
          inputs.name = response.data.result.name;
          inputs.email = response.data.result.email;
          inputs.mobile = response.data.result.mobile;
          setIsMobileVerified(true);
        }
      }); 
    }
  }, []);

  const handleChange = (e) => {
    setInputs((prev) => ({...prev, [e.target.name]: e.target.value}));
  };
  
  const handleRegistrationSendOTP = async (event) => {
    event.preventDefault();
    if (!inputs.name) {
        setErrorFullName(true);
        setErrorHelperTextFullName("Name can not be empty");
    } else if (!validateFullName.test(inputs.name)) {
        setErrorFullName(true);
        setErrorHelperTextFullName(
            "Full Name must be greater than 2 alphabets and less than 40 alphabets"
        );
    } else if (!inputs.email) {
        setErrorEmail(true);
        setErrorHelperTextEmail("Email can not be empty");
        setErrorFullName(false);
        setErrorHelperTextFullName("");
    } else if (!validEmail.test(inputs.email)) {
        setErrorEmail(true);
        setErrorHelperTextEmail("Email must has @gmail.com");
        setErrorFullName(false);
        setErrorHelperTextFullName("");
    } else if (!inputs.mobile) {
        setErrorMobileNumber(true);
        setErrorHelperTextMobileNumber("Mobile Number is mandatory");
        setErrorEmail(false);
        setErrorHelperTextEmail("");
    } else if (!validatePhone.test(inputs.mobile)) {
        setErrorMobileNumber(true);
        setErrorHelperTextMobileNumber("Mobile Number must be 10 digits");
        setErrorEmail(false);
        setErrorHelperTextEmail("");
    } else {
        setErrorFullName(false);
        setErrorEmail(false);
        setErrorMobileNumber(false);
        setErrorHelperTextFullName("");
        setErrorHelperTextEmail("");
        setErrorHelperTextMobileNumber("");
  
        sendOTP({mobile: inputs.mobile})
        .then((response) => {
            if (!response.data.error) {
                setIsMobile(true);
            } else {
                setErrorMobileNumber(true);
                setErrorHelperTextMobileNumber(
                    response.data.message + " Mobile Number is not valid"
                );
            }
        })
        .catch((err) => {
            setErrorMobileNumber(true);
            setErrorHelperTextMobileNumber(err);
        });
    }
  };
  
  const verifyRegistrationSendOTP = async (event) => {
    event.preventDefault();
    if (!inputs.otp) {
        setErrorOtp(true);
        setErrorHelperTextOtp("OTP is required");
    } else if (inputs.otp.length !== 6) {
        setErrorOtp(true);
        setErrorHelperTextOtp("OTP should be of 6 digit");
    } else {
        setErrorOtp(false);
        setErrorHelperTextOtp("");
        verifyOTP({mobile: inputs.mobile, otp: inputs.otp})
        .then((response) => {
            if (!response.data.error) {
              createEnquiry();
            } else {
                setErrorOtp(true);
                setErrorHelperTextOtp(response.data.message);
            }
        })
        .catch((err) => {
            setErrorOtp(true);
            setErrorHelperTextOtp(err.errors.message);
        });
    }
  };

  const createEnquiry = () => {
    createUserEnquiry(inputs)
    .then((response) => {
        if (!response.data.error) {
          if (!noRedirect) {
            navigate(successPath, { service: packageName });
          }
          setModalOpen(false);
        } else {
          reset();
          setErrorFullName(true);
          setErrorHelperTextFullName('Something went wrong. Please try again!');
        }
    })
    .catch((err) => {
      reset();
      setErrorFullName(true);
      setErrorHelperTextFullName('Something went wrong. Please try again!');
    });
  };

  const reset = () => {
    setIsMobile(false);
    setErrorFullName(false);
    setErrorEmail(false);
    setErrorMobileNumber(false);
    setErrorOtp(false);
    setErrorHelperTextFullName("");
    setErrorHelperTextEmail("");
    setErrorHelperTextMobileNumber("");
    setErrorHelperTextOtp("");
  };

  return (
    <>
      {
        <StyledModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          closeAfterTransition={false}
        >
          <Fade in={modalOpen}>
            <Box sx={style}>
              <Stack
                sx={{
                  right: 5,
                  top: 10,
                  position: "absolute",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{ color: "red", fontSize: 20 }}
                  onClick={() => setModalOpen(false)}
                >
                  <CloseIcon />
                </span>
              </Stack>
              <Grid item xs={12} md={12}>
                <Card
                  sx={{
                    width: { lg: 700, md: 700, sm: 340, xs: 340 },
                  }}
                >
                  <Typography
                    variant="h3"
                    component="h2"
                    lineHeight="27px"
                    textAlign="center"
                    m="0 0 10px"
                    sx={{
                      fontSize: {
                        lg: "16px",
                        md: "16px",
                        sm: "14px",
                        xs: "14px",
                      },
                      border: "#e5e5e5",
                      backgroundColor: "#f2f2f2",
                      padding: "16px",
                      color: "#333333",
                      fontWeight: 600,
                    }}
                  >
                    {packageName} @ ₹ {price}
                    {/*<Grid item mt={1} textAlign="center">
                      <Button
                        variant="contained"
                        onClick={() => window.open('https://neetnavigator.com/contactUs')}
                        className="transform hover:scale-125 hover:z-10" 
                        sx={{
                          fontSize: {
                            lg: "16px",
                            md: "16px",
                            sm: "12px",
                            xs: "12px",
                          },
                        }}
                      >
                        SEND ENQUIRY
                      </Button>
                    </Grid>*/}
                  </Typography>
                  <Container maxWidth="xl">
                    <Grid
                        container
                        spacing={3}
                        sx={{
                          flexGrow: 1, 
                          padding: {
                              lg: "15px 100px",
                              md: "15px 100px",
                              xs: "10px 10px",
                          },
                          borderRadius: '20px',
                          margin: 'auto',
                        }}
                        item xs={12} md={18}
                    >
                        <Box
                            sx={{display: "flex", flexDirection: "column", alignItems: "center" }}
                            component="form"
                            noValidate
                            mt={1}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Stack direction="row-reverse">
                                        <Box sx={{ display: "flex", alignItems: "center", width: "5%" }}>
                                          <FullNameInfo/>
                                        </Box>
                                        <Box sx={{width: "95%"}}>
                                            <TextField
                                                autoComplete="given-name"
                                                name="name"
                                                required
                                                fullWidth
                                                id="name"
                                                label="Full Name"
                                                error={errorFullName}
                                                helperText={errorHelperTextFullName}
                                                onChange={handleChange}
                                                autoFocus
                                                value={inputs.name}
                                            />
                                        </Box>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row-reverse">
                                      <Box sx={{ display: "flex", alignItems: "center", width: "5%" }}>
                                        <EmailInfo/>
                                      </Box>
                                      <Box sx={{width: "95%"}}>
                                          <TextField
                                              autoComplete="given-name"
                                              name="email"
                                              required
                                              fullWidth
                                              id="email"
                                              label="Email ID"
                                              error={errorEmail}
                                              helperText={errorHelperTextEmail}
                                              onChange={handleChange}
                                              value={inputs.email}
                                          />
                                      </Box>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row-reverse">
                                      <Box sx={{ display: "flex", alignItems: "center", width: "5%" }}>
                                        <MobileInfo/>
                                      </Box>
                                      <Box sx={{width: "95%"}}>
                                          <TextField
                                              label="Mobile Number"
                                              required
                                              fullWidth
                                              variant="outlined"
                                              id="mobile"
                                              name="mobile"
                                              error={errorMobileNumber}
                                              helperText={errorHelperTextMobileNumber}
                                              onChange={handleChange}
                                              value={inputs.mobile}
                                          />
                                      </Box>
                                    </Stack>
                                </Grid>
                                {isMobile && (
                                <Grid item xs={12}>
                                    <Stack direction="row-reverse">
                                      <Box sx={{ display: "flex", alignItems: "center", width: "5%" }}>
                                        <OtpInfo/>
                                      </Box>
                                      <Box sx={{width: "95%"}}>
                                          <TextField
                                              label="OTP"
                                              required
                                              fullWidth
                                              variant="outlined"
                                              id="otp"
                                              name="otp"
                                              autoFocus
                                              error={errorOtp}
                                              value={inputs.otp}
                                              onChange={handleChange}
                                              helperText={errorHelperTextOtp}
                                          />
                                      </Box>
                                    </Stack>
                                </Grid>)}
                                <Grid item md={12} sm={12} lg={12} xs={12}>
                                    <Stack md={12} sm={12} lg={12} xs={12}>
                                        <Box sx={{width: "95%"}}>
                                            <Button
                                                onClick={ !isMobileVerified ? !isMobile? handleRegistrationSendOTP : verifyRegistrationSendOTP : createEnquiry }
                                                className="search-btn"
                                                size={"small"}
                                                fullWidth
                                                sx={{
                                                    fontSize: {lg: "25px", md: "25px", xs: "16px"},
                                                    border: "1px solid #1366ce",
                                                    outline: "none",
                                                    cursor: "pointer",
                                                    mt: 1,
                                                    mb: 1,
                                                    color: "#fff",
                                                    backgroundColor: "#1366ce",
                                                }}
                                            >
                                                {!isMobileVerified ? !isMobile ? "Get OTP" : "Submit OTP" : "Send Enquiry"}
                                            </Button>
                                        </Box>
                                        <Box sx={{width: "5%"}}></Box>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Container>
                </Card>
                <Grid mt={1} textAlign="center">
                  Submit your enquiry and we will get back to you within 24hrs.
                </Grid>
              </Grid>
            </Box>
          </Fade>
        </StyledModal>
      }
    </>
  );
};

export default ServiceEnquiryModel;