import horizon from "../../assets/benefitsOfMBBS/expandHorizon.svg";
import possibilities from "../../assets/benefitsOfMBBS/enhancedPossibilities.svg";
import competitive from "../../assets/benefitsOfMBBS/competitiveAdvantage.svg";
import stress from "../../assets/benefitsOfMBBS/stressReduction.svg";

const BenefitCard = ({svgImage, title, description}) => {
    return (
        <div
            className="w-64 bg-white rounded-xl shadow-md p-4 flex flex-wrap flex-col items-center transition-transform duration-300 transform hover:scale-125 hover:z-10 ">
            <img src={svgImage} alt="image"/>
            <h3 className="text-lg md:text-xl font-semibold my-2">{title}</h3>
            <p className="text-sm md:text-base text-gray-600 text-center">{description}</p>
        </div>
    );
};

const BenefitsOfMBBSLightHouse = () => {
    const benefits = [
        {svgImage: horizon, title: 'Expanded Horizon', description: 'Know your chances of MBBS admission in 702 medical colleges of India in just 45 seconds.'},
        {svgImage: possibilities, title: 'Enhanced Possibilities', description: 'No separate report for states. Integrated MCC and State Counselling data in one report.'},
        {svgImage: competitive, title: 'Competitive Advantage', description: 'MBBS Lighthouse report offers all critical data such as college information, cut off, fees.'},
        {svgImage: stress, title: 'Stress Reduction', description: 'Eliminate the guess work from your MBBS admission plan and stay focused with right data and information.'},
    ];

    return (
        <div className="bg-[#eaf1ff] flex flex-col items-center pb-14 md:px-6 lg:px-16">
            <h2 className="text-xl lg:text-4xl md:text-2xl font-semibold mt-8 mb-12 ml-4">Benefits of <span
                className="text-[#1366CF]">MBBS Lighthouse</span></h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
                {benefits.map((benefit, index) => (
                    <BenefitCard key={index} svgImage={benefit.svgImage} title={benefit.title}
                                 description={benefit.description}/>
                ))}
            </div>
        </div>
    );
};

export default BenefitsOfMBBSLightHouse;
