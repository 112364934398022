import React, { useMemo, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Paper,
  TableContainer,
  Typography,
} from "@mui/material";
import DataTable from "react-data-table-component";
import CheckoutForm from "./CheckoutForm";
import { useEffect } from "react";
import BetaVersionModal from "../components/BetaVersionModal";
import { setSelectedStateStore, setStudentFindRankStore, setStudentMarksStore } from "../services/serviceToken";

const customStyles = {
  rows: {
    style: { fontFamily: "Poppins, sans-serif" },
  },
  headCells: {
    style: {
      fontFamily: "Poppins, sans-serif",
      justifyContent: "Center",
      fontSize: "18px",
      fontWeight: "500",
      backgroundColor: "#FFFF00",
    },
  },
  cells: {
    style: {
      overflow: "hidden",
      whiteSpace: "wrap",
      textOverflow: "ellipses",
      justifyContent: "Center",
    },
  },
};

const CustomTitle = ({ title }) => (
  <div>
    <div>
      <div
        data-tag="allowRowEvents"
      >
        {title}
      </div>
    </div>
  </div>
);


const CollegeList = ({ marks, findMarks, findRank , userData}) => {
  const [selectedState, setSelectedState] = useState([]);

  const columns = useMemo(
    () => [
      {
        name: "State Name",
        sortable: true,
        width: "300px",
        cell: (row) => <CustomTitle title={row.stateName} />,
        selector: (row) => row.stateName,
      },
      {
        name: "No. of Colleges",
        sortable: true,
        selector: (row) => row.count,
      },
    ],
    []
  );

  const handleChange = (state) => {
    setSelectedState(state.selectedRows);
  };

  const selectedStateValue = JSON.stringify(selectedState);
  const findRankValue = JSON.stringify(findRank);
  setStudentMarksStore(marks);
  setSelectedStateStore(selectedStateValue);
  setStudentFindRankStore(findRankValue);

  useEffect(() => {
     window.scrollTo(0, 0);
   handleTcModalOpen();
  }, [])

  const [tcModalOpen, setTcModalOpen] = useState(false);
  const handleTcModalOpen = () => setTcModalOpen(true);


  return (
    <>
      <Grid item xs={12} md={12} mb={2}>
        <Typography
          variant="h2"
          component="h2"
          sx={{
            textAlign: "center",
            fontSize: "24px",
            fontWeight: 600,
            border: "1px solid #1366ce",
            color: "#fff",
            borderTopLeftRadius: "15px",
            marginBottom: "0",
            padding: "15px 0px",
            borderTopRightRadius: "15px",
            backgroundColor: "#1366ce",
          }}
        >
          Your Predicted Rank
        </Typography>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Grid container spacing={2} mt={1} mb={1}>
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <Typography
                  variant="p"
                  component="p"
                  sx={{ fontSize: "16px", fontWeight: "600" }}
                >
                  Marks
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={9} lg={9}>
                <Typography
                  variant="p"
                  component="p"
                  sx={{ fontSize: "16px", fontWeight: "500" }}
                >
                  {findRank.marks}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <Typography
                  variant="p"
                  component="p"
                  sx={{ fontSize: "16px", fontWeight: "600" }}
                >
                  Minimum Rank
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <Typography
                  variant="p"
                  component="p"
                  sx={{ fontSize: "16px", fontWeight: "500" }}
                >
                  {findRank.minRank}
                </Typography>
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3}>
                <Typography
                  variant="p"
                  component="p"
                  sx={{ fontSize: "16px", fontWeight: "600" }}
                >
                  Maximum Rank
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <Typography
                  variant="p"
                  component="p"
                  sx={{ fontSize: "16px", fontWeight: "500" }}
                >
                  {findRank.maxRank}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography
          variant="h2"
          component="h2"
          sx={{
            textAlign: "center",
            fontSize: "24px",
            fontWeight: 600,
            border: "1px solid #1366ce",
            color: "#fff",
            borderTopLeftRadius: "15px",
            marginBottom: "0",
            padding: "15px 0px",
            borderTopRightRadius: "15px",
            backgroundColor: "#1366ce",
          }}
        >
          Select Your Choice
        </Typography>
        <TableContainer component={Paper} >
          <DataTable
            columns={columns}
            data={findMarks}
            customStyles={customStyles}
            highlightOnHover
            striped
            selectableRows
            persistTableHead
            fixedHeader
            fixedHeaderScrollHeight={"400px"}
            onSelectedRowsChange={handleChange}
          />
        </TableContainer>
        <CheckoutForm
          selectedState={selectedState}
          userData={userData}
        />
      </Grid>
    </>
  );
};

export default CollegeList;
