import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import Root from "../layout/Root";
import AuthLayout from "../layout/AuthLayout";
import Authentication from "../layout/Authentication";

import Home from "../pages/Home";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import Register from "../pages/Register";
import ErrorPage from "../pages/ErrorPage";
import StudentAccount from "../pages/StudentAccount";
import StudentMark from "../pages/StudentMark";
import StudentServices from "../pages/StudentServices";
import PasswordReset from "../pages/PasswordReset";
import SelectedCollegeInfo from "../pages/SelectedCollegeInfo";
import CollegeListInfo from "../pages/CollegeListInfo";
import Services from "../pages/Services";
import Overview from "../pages/Overview";
import StudentDomicile from "../pages/StudentDomicile";
import StudentDomicileEdit from "../pages/StudentDomicileEdit";
import StudentRank from "../pages/StudentRank";
import Success from "../pages/payment/Success";
import Failure from "../pages/payment/Failure";
import SampleReport from "../pages/SampleReport";
import StudentOrders from "../pages/StudentOrders";
import StudentReports from "../pages/StudentReports";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />} errorElement={<ErrorPage />}>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register/>} />
        <Route path="enquiry-sent" element={<Register isServiceEnquired={true}/>} />
        <Route path="password/reset" element={<PasswordReset />} />
      </Route>
      <Route path="/" element={<Authentication />}>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home populateStudentId={true}/>} />
        <Route path="/domicile" element={<StudentDomicile />} />
        <Route path="/domicile-edit" element={<StudentDomicileEdit />} />
        <Route path="/student-marks" element={<StudentMark />} />
        <Route path="/student-rank" element={<StudentRank />} />
        <Route path="/student-services" element={<StudentServices />} />
        <Route path="/student-profile" element={<StudentAccount />} />
        <Route path="/student-orders" element={<StudentOrders />} />
        <Route path="/student-reports" element={<StudentReports />} />
        <Route path="college-list" element={<CollegeListInfo />} />
        <Route path="college-info" element={<SelectedCollegeInfo />} />
        <Route path="payment-success" element={<Success />} />
        <Route path="payment-failed" element={<Failure />} />
      </Route>
      <Route path="overview" element={<Overview />} />
      <Route path="services" element={<Services />} />
      <Route path="sample-report" element={<SampleReport />} />
      <Route path="logout" element={<Logout />} />
    </Route>
  )
);

export default router;
