import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { getPaymentDetail, newOrder } from "../services/paymentOrder";
import {
  getSelectedStateStore,
  getStudentMarksStore,
} from "../services/serviceToken";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: 0,
    fontSize: 24,
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: 0,
    fontSize: 14,
    fontWeight: 500,
  },
  [`&.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: 0,
    fontSize: 16,
    fontWeight: 600,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
    border: 0,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CheckoutForm = ({ selectedState, userData }) => {
  const navigate = useNavigate();
  const {
    name,
    email,
    mobile,
    student: { address },
  } = userData;
  const { city, state, pincode } = address;

  const confirmAddress = address.address !== null ? address.address : "NA";

  const [selected, setSelected] = useState(null);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [promoCode, setPromoCode] = useState("");

  const Count = selectedState.length;
  const handleChange = React.useCallback((value) => {
    setSelected(value);
    setHelperText(" ");
    setError(false);
  }, []);

  React.useMemo(() => {
    if (Count) {
      setSelected(null);
    }
  }, [Count]);

  const redirectUrl =
    "https://neetnavigator.com/CCAvenuePayment/ccavResponseHandler.php";

  const packagePrice = selected === null ? "0.00" : selected.price;
  const gstPrice = selected === null ? "0.00" : Math.round(selected.price*0.18);
  const totalPrice = selected === null ? "0.00" : packagePrice + gstPrice;
  const PriceId = selected === null ? "0" : selected.id;

  const [paymentStatusData, setPaymentStatusData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    async function getUserData() {
      getPaymentDetail().then((response) => {
        setPaymentStatusData(response.data.result);
      });
    }
    getUserData();
  }, []);

  const studentMarksStore = getStudentMarksStore();

  const successPaymentStatus = paymentStatusData.filter(
    (c) => c.order_status === "Success"
  );

  const selectedStateStore = getSelectedStateStore();
  const selectedStateConverted = JSON.parse(selectedStateStore);
  const selectedStateValue = selectedStateConverted.map((book) => book.stateId);
  const selectedStateValueNormal = selectedStateValue
    .sort((a, b) => a - b)
    .join(", ");

  const checkPaymentStatus = (obj) => {
    if (!obj) return false;
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear;
    // Split the date string into its components
    const dateString = obj?.trans_date;
    if (!dateString) return false;
    const [day, month, yearTime] = dateString?.split("/");
    const [year, time] = yearTime?.split(" ");
    // Split the time into its components
    const [hour, minute, second] = time?.split(":");
    // Create a new Date object
    // Note: Months are 0-indexed in JavaScript Date, so subtract 1 from the month
    const date = new Date(year, month - 1, day, hour, minute, second);
    // Get the year
    const extractedYear = date.getFullYear();
    const transDateYear = extractedYear;

    console.log("Trans Date Year:", transDateYear);
    console.log("Trans obj.trans_date:", obj.trans_date);
    console.log("Previous Year:", previousYear);

    return (
      transDateYear == previousYear &&
      ((obj.merchant_param3 == 3 && obj.merchant_param4 == studentMarksStore) ||
        (obj.merchant_param5 == selectedStateValueNormal &&
          obj.merchant_param4 == studentMarksStore) ||
        (obj.merchant_param5 == selectedStateValueNormal &&
          obj.merchant_param3 == PriceId &&
          obj.merchant_param4 == studentMarksStore))
    );
  };

  const paymentSuccessStatus = successPaymentStatus.some(checkPaymentStatus);

  const handleCheckOutForm = async (event) => {
    event.preventDefault();
    if (!selected) {
      setHelperText("* Please Select Any State From Above Table");
      setError(true);
    } else if (paymentSuccessStatus === true) {
      navigate("/college-list", {
        replace: navigate("/"),
        state: {
          paymentSuccessStatus: true,
          orderType: selected.id
        }
      });
    } else {
      newOrder({
        amount: totalPrice,
        billing_name: name,
        redirect_url: redirectUrl,
      })
        .then((response) => {
          if (!response.data.error) {
            const id = response.data.result.order.id;
            const order_id = response.data.result.order.order_id;
            const tid = response.data.result.order.tid;
            document.getElementById("order_id").value = order_id;
            document.getElementById("tid").value = tid;
            
            // Do not modify "Your Order Id is ". If you do, then please modify in the Success and Failure files as well for getting the paymentID.
            document.getElementById("merchant_param1").value = "Your Order Id is " + id;
            document.getElementById("merchant_param3").value = selected.id;
            document.getElementById("merchant_param4").value =
              studentMarksStore;
            document.getElementById("merchant_param5").value =
              selectedStateValueNormal;

            document.getElementById("merchant_param2").value =
              process.env.NODE_ENV === "development" ? "localhost" : "Live";

            if (
              /^(8750001881|9212234090|9910375900|9811937572)$/.test(mobile)
            ) {
              navigate("/college-list", {
                replace: navigate("/"),
                state: {
                  paymentSuccessStatus: true,
                  orderType: selected.id
                }
              });
            } else {
              document.forms["marks-form"].submit();
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <Grid container sx={{ flexGrow: 1 }} mb={5} mt={2} spacing={2}>
      <Grid item xs={12} md={12}>
        <Box>
          <form
            name="marks-form"
            method="POST"
            action={`https://neetnavigator.com/CCAvenuePayment/ccavRequestHandler.php`}
          >
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan={3}>Cart Summary</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow style={{ backgroundColor: "#fff" }}>
                    <StyledTableCell>
                      <Radio
                        checked={selected?.id === 1}
                        onChange={() =>
                          handleChange({
                            id: 1,
                            price: 299,
                            attempt: 1,
                            state: 1,
                          })
                        }
                        value={1}
                        id={`check-box-${1}`}
                        name={`radio-buttons-${1}`}
                        inputProps={{ "aria-label": 299 }}
                        disabled={Count !== 1}
                      />
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row">
                      {`1 State`}
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row">
                      ₹299
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>
                      <Radio
                        value={2}
                        checked={selected?.id === 2}
                        onChange={() =>
                          handleChange({
                            id: 2,
                            price: 499,
                            attempt: 1,
                            state: 2,
                          })
                        }
                        id={`check-box-${2}`}
                        name={`radio-buttons-${2}`}
                        inputProps={{ "aria-label": 499 }}
                        disabled={Count !== 2}
                      />
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row">
                      {`2 States`}
                    </StyledTableCell>
                    <StyledTableCell>
                      ₹499
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>
                      <Radio
                        checked={selected?.id === 3}
                        onChange={() =>
                          handleChange({
                            id: 3,
                            price: 699,
                            attempt: 1,
                            state: 36,
                          })
                        }
                        value={1}
                        id={`check-box-${3}`}
                        name={`radio-buttons-${3}`}
                        inputProps={{ "aria-label": 699 }}
                        disabled={Count < 3}
                      />
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row">
                      {`All States`}<span style={{fontSize: 16, color: "red"}}>**</span>
                    </StyledTableCell>
                    <StyledTableCell>
                      ₹699
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <StyledTableCell colSpan={2}>SubTotal</StyledTableCell>
                    <StyledTableCell>₹ {packagePrice}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell colSpan={2}>GST *</StyledTableCell>
                    <StyledTableCell>₹ {gstPrice}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell colSpan={2}>Order Total</StyledTableCell>
                    <StyledTableCell>₹ {totalPrice}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell>
                      <Typography
                        variant="p"
                        component="p"
                        sx={{ fontSize: "16px", color: "#c45500" }}
                      >
                        Have Promo Code ?
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell colSpan={2}>
                      <Stack direction="row">
                        <TextField
                          label="Promo Code"
                          fullWidth
                          variant="outlined"
                          id="promo_code"
                          size="small"
                          name="promo_code"
                          value={promoCode}
                          onChange={(e) => setPromoCode(e.target.value)}
                          InputProps={{
                            readOnly: false,
                          }}
                        />
                      </Stack>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell colSpan={3}>
                      <Stack md={12} sm={12} lg={12} xs={12}>
                        {error && (
                          <p className="term-condition-error-msg">
                            {helperText}
                          </p>
                        )}
                        <Button
                          type="submit"
                          className="search-btn"
                          onClick={handleCheckOutForm}
                          sx={{
                            fontSize: "25px",
                            padding: "22px",
                            border: "1px solid #1366ce",
                            outline: "none",
                            cursor: "pointer",
                            mt: 2,
                            mb: 4,
                            color: "#fff",
                            backgroundColor: "#1366ce",
                            height: "50px",
                          }}
                        >
                          Checkout
                        </Button>
                        <span style={{ fontSize: 12, color: "black" }}>
                          * MBBS Lighthouse plays no role in taxes and charges levied by the government.
                        </span>
                        <br/>
                        {/* <span style={{ fontSize: 16, color: "red" }}>
                          ** Fare usage policy applied. The report has been truncated to have top 50 colleges from all states in India based on the entered marks. If you have any query, kindly contact us at +91 99103 75900.
                        </span> */}
                      </Stack>
                    </StyledTableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <input type="hidden" name="tid" id="tid" readOnly />
            <input type="hidden" name="merchant_id" value={2342262} readOnly />
            <input type="hidden" name="order_id" id="order_id" readOnly />
            <input type="hidden" name="amount" value={totalPrice} readOnly />
            <input type="hidden" name="currency" value="INR" readOnly />
            <input
              type="hidden"
              name="redirect_url"
              value={`https://neetnavigator.com/CCAvenuePayment/ccavResponseHandler.php`}
              readOnly
            />
            <input
              type="hidden"
              name="cancel_url"
              value="https://neetnavigator.com/CCAvenuePayment/ccavResponseHandler.php"
              readOnly
            />
            <input type="hidden" name="language" value="EN" readOnly />
            <input type="hidden" name="billing_name" value={name} readOnly />
            <input
              type="hidden"
              name="billing_address"
              value={confirmAddress}
              readOnly
            />
            <input
              type="hidden"
              name="billing_city"
              value={city.name}
              readOnly
            />
            <input
              type="hidden"
              name="billing_state"
              value={state.name}
              readOnly
            />
            <input type="hidden" name="billing_zip" value={pincode} readOnly />
            <input
              type="hidden"
              name="billing_country"
              value="India"
              readOnly
            />
            <input type="hidden" name="billing_tel" value={mobile} readOnly />
            <input type="hidden" name="billing_email" value={email} readOnly />
            <input
              type="hidden"
              name="merchant_param1"
              id="merchant_param1"
              readOnly
            />
            <input
              type="hidden"
              name="merchant_param2"
              id="merchant_param2"
              readOnly
            />
            <input
              type="hidden"
              name="merchant_param3"
              id="merchant_param3"
              readOnly
            />
            <input
              type="hidden"
              name="merchant_param4"
              id="merchant_param4"
              readOnly
            />
            <input
              type="hidden"
              name="merchant_param5"
              id="merchant_param5"
              readOnly
            />
            <input type="hidden" name="promo_code" value={promoCode} />

            <input
              type="hidden"
              name="integration_type"
              value="iframe_normal"
              readOnly
            />
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CheckoutForm;
