import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getInitData } from "../services/init-data";
import { getState } from "../services/states";
import { getCities } from "../services/city";
import { getUserId } from "../services/serviceToken";
import { findStudent, updateStudent } from "../services/student";
import { updateAddress } from "../services/address";
import { useNavigate } from "react-router-dom";
import { pinCode } from "../components/Regex";
import { gender } from "../constants";
import { getAreaOfState } from "../services/areaOfState";
import { getStateCategoryOfState } from "../services/stateCategoryOfState";
import { findPincode } from "../services/pincodeApi";
import { errorMessage } from "../utils/alertMessage";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { findUser, updateUser } from "../services/user";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import YourGpsMbbs from "../components/HomePageComponents/YourGpsMbbs";
import SlickSlider from "../components/HomePageComponents/SlickSlider";
import { getNeetCategory } from "../services/neetCategory";
import { getCollegeCount } from "../services/colleges";

const StudentDomicileEdit = () => {
  // Edit Student fields

  const navigate = useNavigate();

  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };

  const [userDetail, setUserDetail] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const [studentDetail, setStudentDetail] = useState({
    gender: "",
    stateId12th: "",
    stateId10th: "",
    year12th: "",
    year10th: "",
    isPhCandidate: "",
    areaId: "",
    area: { area: "" },
    quota: { quota: "" },
    quotaId: "",
    state10th: { name: "" },
    state12th: { name: "" },
    minority: { minority: "" },
    minorityId: "",
    armedForceId: "",
    armed_force: { armedForce: "" },
    neet_category: { category: "" },
    neetCategoryId: "",
    stateCategoryId: "",
    state_category: { category: "" },
    address: {
      address: "",
      pincode: "",
      stateId: "",
      cityId: "",
      state: { name: "" },
      city: { name: "" },
    },
  });

  const [data, setData] = useState({
    areaId: "",
    address: {
      address: "",
      pincode: "",
      stateId: "",
      cityId: "",
      state: { name: "" },
      city: { name: "" },
    },
    gender: "",
    cityId: "",
    dob: "",
    year12th: "",
    year10th: "",
    neetYear: "",
    pincode: "",
    stateId: "",
    quotaId: "",
    minorityId: "",
    stateId12th: "",
    stateId10th: "",
    armedForceId: "",
    isPhCandidate: "",
    neetCategoryId: "",
    stateCategoryId: "",
  });

   const [collegeDataCount, setCollegeDataCount] = useState("");
     const [stateDisabled, setStateDisabled] = useState(false);


  const userId = getUserId();
  useEffect(() => {
     window.scrollTo(0, 0);
    async function getUserData() {
      findUser(userId)
        .then((response) => {
          setUserDetail(response.data.result);
          if (response.data.result.error !== false) {
            const studentId = response.data.result.student.id;
            findStudent(studentId)
              .then((response) => {
                setStudentDetail(response.data.result);
                setData(response.data.result);
              })
              .catch((err) => {
                console.error("error", err);
              });
          }
        })
        .catch((err) => {
          console.error("error", err);
        });
    }
    getUserData();

    
       async function CollegeCount() {
         getCollegeCount()
           .then((response) => {
             if (!response.data.error) {
               setCollegeDataCount(response.data.result);
             }
           })
           .catch((err) => {
             console.error("error", err);
           });
       }
       CollegeCount();
  }, []);

  const pincode = studentDetail.address.pincode;

  const [state, setState] = useState([]);
  const [cities, setCities] = useState([]);

  const [pincodeData, setPincodeData] = useState([]);
  const [areaOfState, setAreaOfState] = useState([]);
  const [stateCategoryOfState, setStateCategoryOfState] = useState([]);
  const [searchedCity, setSearchedCity] = useState([]);
  const [searchedStateCategoryOfState, setSearchedStateCategoryOfState] =
    useState([{ state_category: { category: "" } }]);
  const [searchedAreaOfState, setSearchedAreaOfState] = useState([
    { area: { area: "" } },
  ]);

  const [initData, setInitData] = useState({
    area: [],
    neetCategory: [],
    stateCategory: [],
    armedForce: [],
    minority: [],
    quota: [],
  });
  const [error, setError] = useState({
    areaId: false,
    gender: false,
    cityId: false,
    pincode: false,
    stateId: false,
    quotaId: false,
    quotaYesNo: false,
    minorityId: false,
    minorityYesNo: false,
    stateId12th: false,
    stateId10th: false,
    armedForceId: false,
    armedForceYesNo: false,
    isPhCandidate: false,
    neetCategoryId: false,
    stateCategoryId: false,
  });
  const [errorHelperText, setErrorHelperText] = useState({
    areaId: "",
    gender: "",
    cityId: "",
    pincode: "",
    stateId: "",
    quotaId: "",
    quotaYesNo: "",
    minorityId: "",
    minorityYesNo: "",
    stateId12th: "",
    stateId10th: "",
    armedForceId: "",
    armedForceYesNo: "",
    isPhCandidate: "",
    neetCategoryId: "",
    stateCategoryId: "",
  });

  useEffect(() => {
    async function getData() {
      getState()
        .then((response) => {
          setState(response.data.result);
        })
        .catch((err) => {
          console.error("error", err);
        });
      getCities()
        .then((response) => {
          setCities(response.data.result);
        })
        .catch((err) => {
          console.error("error", err);
        });
      getAreaOfState().then((response) => {
        if (response.data.error === false) {
          setAreaOfState(response.data.result);
        }
      });
      getStateCategoryOfState().then((response) => {
        if (response.data.error === false) {
          setStateCategoryOfState(response.data.result);
        }
      });
      getInitData()
        .then((response) => {
          if (!response.data.error) {
            setInitData(response.data.result);
          }
        })
        .catch((err) => {
          console.error("error", err);
        });
        // getNeetCategory()
        //   .then((response) => {
        //   })
        //   .catch((err) => {
        //     console.error("error", err);
        //   });
    }
    getData();
  }, []);

  function getPincodeData() {
    if (
      (data.address.pincode || pincode) &&
      pinCode.test(data.address.pincode || pincode)
    ) {
      findPincode(data.address.pincode || pincode)
        .then((response) => response.json())
        .then((data) => {
          setPincodeData(data[0].PostOffice[0].State);
          setError({ pincode: false });
          setErrorHelperText({
            pincode: "",
          });
        })
        .catch((err) => {
          setStateDisabled(false);
          // console.error("error", err);
          // setError({ pincode: true });
          // setErrorHelperText({
          //   pincode: "Please enter correct pincode",
          // });
        });
    } else {
      setPincodeData([]);
      setData({ ...data, stateId: "", cityId: "", areaId: "" });
      setSearchedCity([]);
    }
  }

  useEffect(() => {
    getPincodeData();
  }, [data.address.pincode]);

  useEffect(() => {
    if (pincodeData.length) {
      const availablePincodeState = state.find((s) => s.name === pincodeData);
      if (pincodeData.length && availablePincodeState) {
        setData({ ...data, stateId: availablePincodeState.id });
        setStateDisabled(true);
      } else {
        setData({ ...data, stateId: "" });
      }
      const availableStateOfArea = areaOfState.filter(
        (s) => s.stateId === availablePincodeState.id
      );
      const availableStateCategoryOfState = stateCategoryOfState.filter(
        (s) => s.stateId === availablePincodeState.id
      );
      const filterAvailableStateCategoryOfState =
        availableStateCategoryOfState.filter((element) => {
          return (
            element.state_category.category !== "Unreserved" &&
            element.state_category.category !== "Open"
          );
        });
      if (availablePincodeState) {
        const searchCity = cities.filter(
          (c) => c.stateCode === availablePincodeState.code
        );
        setSearchedCity(searchCity);
      }
      setSearchedAreaOfState(availableStateOfArea);
      setSearchedStateCategoryOfState(filterAvailableStateCategoryOfState);
    }
  }, [pincodeData]);

  useEffect(() => {
    if (data.stateId) {
      const availablePincodeState = state.find((s) => s.id === data.stateId);
      // if (pincodeData.length && availablePincodeState) {
      //   setData({ ...data, stateId: availablePincodeState.id });
      // } else {
      //   setData({ ...data, stateId: "" });
      // }
      const availableStateOfArea = areaOfState.filter(
        (s) => s.stateId === availablePincodeState.id
      );
      const availableStateCategoryOfState = stateCategoryOfState.filter(
        (s) => s.stateId === availablePincodeState.id
      );
      const filterAvailableStateCategoryOfState =
        availableStateCategoryOfState.filter((element) => {
          return (
            element.state_category.category !== "Unreserved" &&
            element.state_category.category !== "Open"
          );
        });
      if (availablePincodeState) {
        const searchCity = cities.filter(
          (c) => c.stateCode === availablePincodeState.code
        );
        setSearchedCity(searchCity);
      }
      setSearchedAreaOfState(availableStateOfArea);
      setSearchedStateCategoryOfState(filterAvailableStateCategoryOfState);
    }
  }, [data.stateId]);

  useEffect(() => {
    if (studentDetail?.address?.stateId) {
      const availablePincodeState = state.find(
        (s) => s.id === studentDetail?.address?.stateId
      );
      // if (pincodeData.length && availablePincodeState) {
      //   setData({ ...data, stateId: availablePincodeState.id });
      // } else {
      //   setData({ ...data, stateId: "" });
      // }
      const availableStateOfArea = areaOfState.filter(
        (s) => s.stateId === availablePincodeState.id
      );
      const availableStateCategoryOfState = stateCategoryOfState.filter(
        (s) => s.stateId === availablePincodeState.id
      );
      const filterAvailableStateCategoryOfState =
        availableStateCategoryOfState.filter((element) => {
          return (
            element.state_category.category !== "Unreserved" &&
            element.state_category.category !== "Open"
          );
        });
      if (availablePincodeState) {
        const searchCity = cities.filter(
          (c) => c.stateCode === availablePincodeState.code
        );
        setSearchedCity(searchCity);
      }
      setSearchedAreaOfState(availableStateOfArea);
      setSearchedStateCategoryOfState(filterAvailableStateCategoryOfState);
    }
  }, [studentDetail?.address?.stateId]);

  const filterArea = searchedAreaOfState.filter((element) => {
    return element.area.area !== "All";
  });

  const neetCat = initData.neetCategory.filter((element) => {
    return element.category !== "Unreserved" && element.category !== "Open";
  });

  const handleStudentUpdateForm = async (event) => {
    event.preventDefault();
    if (!data?.address.cityId) {
      setError({ cityId: true });
      setErrorHelperText({
        cityId: "Please select any one of these",
      });
    } else if (filterArea.length !== 0 && data.areaId === "") {
      setError({ areaId: true });
      setErrorHelperText({
        areaId: "Please select your area",
      });
    } else {
      setError({
        areaId: false,
        cityId: false,
      });
      setErrorHelperText({
        areaId: "",
        cityId: "",
      });
      updateUser(userId, {
        ...userDetail,
        name: userDetail.name,
        email: userDetail.email,
      })
        .then((response) => {
          if (response.data.error === false) {
            updateStudent(data.id, {
              ...data,
              areaId: data.areaId === "" ? null : data.areaId,
              quotaId: data.quotaId === "NA" ? null : data.quotaId,
              minorityId: data.minorityId === "NA" ? null : data.minorityId,
              armedForceId:
                data.armedForceId === "NA" ? null : data.armedForceId,
            })
              .then((response) => {
                if (!response.data.error) {
                  updateAddress(studentDetail.address.id, {
                    address: data.address.address,
                    stateId: data.stateId,
                    cityId: data.cityId,
                    pincode: data.address.pincode,
                  })
                    .then((response) => {
                      if (!response.data.error) {
                        navigate("/");
                      }
                    })
                    .catch((err) => {
                      errorMessage(err.errors.message);
                    });
                } else {
                  if (
                    response.data.errors.errors[0].message ===
                    "userId must be unique"
                  ) {
                    errorMessage("User Data is already present");
                  } else {
                    errorMessage();
                  }
                }
              })
              .catch((err) => {
                errorMessage(err.errors.message);
              });
          } else {
            errorMessage(response.data.errors.errors[0].message);
          }
        })
        .catch((err) => {
          errorMessage();
        });
    }
  };

  return (
    <Container maxWidth="xl">
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            background: "linear-gradient(135deg, #4D22E0 0%, #356EF5 100%)",
            color: "#fff",
          }}
        >
          {" "}
          <Stack
            sx={{
              padding: {
                lg: "0px 30px 10px 20px",
                md: "0px 30px 10px 20px",
                xs: "0px 30px 10px 20px",
              },
            }}
          >
            <Grid item xs={12} md={12}>
              <Stack sx={{ textAlign: "center" }}>
                <Typography
                  className="CollegeCount"
                  variant="h4"
                  component="h1"
                  mt={1}
                  sx={{
                    fontSize: { md: "42px", xs: "21px" },
                    fontWeight: 600,
                    color: "red",
                  }}
                >
                  {collegeDataCount}
                </Typography>
                <Typography
                  variant="h4"
                  component="h2"
                  mt={1}
                  sx={{
                    fontSize: {
                      lg: "38px",
                      md: "38px",
                      xs: "21px",
                      sm: "21px",
                    },
                    fontWeight: 600,
                  }}
                >
                  Medical Colleges in{" "}
                  <strong
                    sx={{
                      fontSize: { md: "48px", xs: "21px" },
                      fontWeight: 600,
                      color: "#1366ce",
                    }}
                  >
                    India
                  </strong>
                </Typography>
              </Stack>
            </Grid>
            <Stack style={{ alignSelf: "center" }}>
              <Typography
                variant="h4"
                component="h1"
                mt={2}
                mb={2}
                sx={{
                  fontSize: { md: "24px", xs: "18px" },
                  fontWeight: "normal",
                  alignSelf: "center",
                }}
              >
                Your GPS to MBBS Admission
              </Typography>
              <Typography
                variant="p"
                component="p"
                mb={2}
                textAlign="justify"
                sx={{
                  fontSize: { md: "14px", xs: "11px" },
                  fontWeight: "300",
                  alignSelf: "center",
                }}
              >
                Expand your horizon of MBBS admissions with MBBS Lighthouse -
                India’s most advanced web app for MBBS admission possibility
                estimation
              </Typography>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={12}>
          <Box
            component="form"
            noValidate
            onSubmit={handleStudentUpdateForm}
            sx={{ mt: 0 }}
          >
            <Grid
              item
              sx={{
                display: { md: "flex", xs: "column" },
                mt: "10px",
              }}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  mr: { md: "20px", xs: "0" },
                }}
              >
                <Stack
                  className="college-form-heading"
                  display="inline-block"
                  spacing={2}
                  mb={2}
                >
                  <Typography
                    variant="h3"
                    component="h2"
                    sx={{
                      fontSize: { md: "30px", xs: "20px" },
                      color: "#1e439b",
                      lineHeight: 1.5,
                      display: "inline-block",
                      borderBottom: "3px solid #ff5501",
                    }}
                  >
                    Edit your Domicile
                  </Typography>
                </Stack>
                <Stack mt={1} mb={2}>
                  <TextField
                    autoComplete="given-name"
                    id="stateId10th"
                    select
                    name="stateId10th"
                    label="10th Passing State"
                    className="input-field"
                    required
                    fullWidth
                    error={error.stateId10th}
                    helperText={errorHelperText.stateId10th}
                    value={data.stateId10th}
                    onChange={(e) =>
                      setData({ ...data, stateId10th: e.target.value })
                    }
                    variant="outlined"
                  >
                    {state.map((states) => (
                      <MenuItem key={states.name + states.id} value={states.id}>
                        {states.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                <Stack mt={1} mb={2}>
                  <TextField
                    autoComplete="given-name"
                    id="stateId12th"
                    select
                    name="stateId12th"
                    className="input-field"
                    label="12th Passing State"
                    error={error.stateId12th}
                    helperText={errorHelperText.stateId12th}
                    value={data.stateId12th}
                    onChange={(e) =>
                      setData({ ...data, stateId12th: e.target.value })
                    }
                    variant="outlined"
                    required
                    fullWidth
                  >
                    {state.map((states) => (
                      <MenuItem key={states.name + states.id} value={states.id}>
                        {states.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                <Stack mt={1} mb={2}>
                  <TextField
                    autoComplete="given-name"
                    name="pincode"
                    id="pincode"
                    label="Pincode Based on Aadhaar"
                    className="input-field"
                    fullWidth
                    type="number"
                    required
                    error={error.pincode}
                    helperText={errorHelperText.pincode}
                    value={data.address.pincode}
                    onChange={(e) => {
                      const newPincode = e.target.value.toString();
                      if (newPincode.length <= 6) {
                        setData({
                          ...data,
                          address: {
                            ...data.address,
                            pincode: newPincode,
                          },
                        });
                      }
                    }}
                    // onChange={(e) => {
                    //   if (e.target.value.toString().length <= 6) {
                    //     setData({
                    //       ...data,
                    //       address: { pincode: e.target.value },
                    //     });
                    //   }
                    // }}
                    // if (e.target.value.toString().length <= 6) {
                    //   setData({ ...data, pincode: e.target.value });
                    // }
                    variant="outlined"
                  />
                </Stack>
                <Stack direction="row" spacing={2} mt={1} mb={2}>
                  {/*<TextField
                autoComplete="given-name"
                name="stateId"
                id="stateId"
                label="State based on area pincode"
                className="input-field"
                value={pincodeData}
                variant="outlined"
                fullWidth
                readOnly
                required
              ></TextField>*/}
                  <TextField
                    autoComplete="given-name"
                    name="stateId"
                    required
                    select
                    fullWidth
                    id="stateId"
                    error={error.stateId}
                    helperText={errorHelperText.stateId}
                    label="Domicile State"
                    className="input-field"
                    value={data?.stateId || studentDetail.address.stateId}
                    onChange={(e) => {
                      setData({ ...data, stateId: e.target.value });
                    }}
                    variant="outlined"
                    disabled={stateDisabled}
                  >
                    {state.length > 0 ? (
                      state.map((states) => (
                        <MenuItem
                          key={states.name + states.id}
                          value={states.id}
                        >
                          {states.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem key="a1" value="">
                        No State found
                      </MenuItem>
                    )}
                  </TextField>
                  <TextField
                    autoComplete="given-name"
                    name="cityId"
                    required
                    select
                    fullWidth
                    id="cityId"
                    error={error.cityId}
                    helperText={errorHelperText.cityId}
                    label="Select Your City"
                    className="input-field"
                    value={data?.cityId || studentDetail.address.cityId}
                    onChange={(e) => {
                      setData({ ...data, cityId: e.target.value });
                    }}
                    variant="outlined"
                  >
                    {searchedCity.map((city) => (
                      <MenuItem key={city.name + city.id} value={city.id}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                {filterArea.length !== 0 && (
                  <Stack direction="row" spacing={2} mt={1} mb={1}>
                    <TextField
                      autoComplete="given-name"
                      id="areaId"
                      select
                      name="areaId"
                      label="Area"
                      className="input-field"
                      required
                      fullWidth
                      error={error.areaId}
                      helperText={errorHelperText.areaId}
                      variant="outlined"
                      value={data.areaId}
                      onChange={(e) => {
                        setData({ ...data, areaId: e.target.value });
                      }}
                    >
                      {filterArea.map((item) => (
                        <MenuItem
                          key={item.area.area + item.area.id}
                          value={item.area.id}
                        >
                          {item.area.area}
                        </MenuItem>
                      ))}
                      <MenuItem key="area1" value="12" selected>
                        None
                      </MenuItem>
                    </TextField>
                  </Stack>
                )}
                <Typography
                  variant="h3"
                  component="h2"
                  onClick={handleToggle}
                  sx={{
                    fontSize: { md: "30px", xs: "20px" },
                    color: "#1e439b",
                    lineHeight: 1.5,
                    display: "inline-block",
                    borderBottom: "3px solid #ff5501",
                    marginBottom: "10px",
                    cursor: "pointer",
                  }}
                >
                  Edit your Personal Details
                </Typography>
                {isToggled && (
                  <Stack>
                    <Stack mt={1} mb={2}>
                      <TextField
                        autoComplete="given-name"
                        name="name"
                        id="name"
                        label="Name"
                        className="input-field"
                        fullWidth
                        value={userDetail.name}
                        onChange={(e) =>
                          setUserDetail({ ...userDetail, name: e.target.value })
                        }
                        variant="outlined"
                      />
                    </Stack>

                    <Stack mt={1} mb={2}>
                      <TextField
                        autoComplete="given-name"
                        name="email"
                        id="email"
                        label="Email"
                        className="input-field"
                        fullWidth
                        value={userDetail.email}
                        onChange={(e) =>
                          setUserDetail({
                            ...userDetail,
                            email: e.target.value,
                          })
                        }
                        variant="outlined"
                      />
                    </Stack>
                    <Stack mt={1} mb={2}>
                      <TextField
                        autoComplete="given-name"
                        name="mobile-number"
                        id="mobile-number"
                        label="Mobile Number"
                        className="input-field"
                        fullWidth
                        value={userDetail.mobile}
                        onChange={(e) =>
                          setUserDetail({
                            ...userDetail,
                            mobile: e.target.value,
                          })
                        }
                        variant="outlined"
                        disabled={true}
                      />
                    </Stack>
                    <Stack mt={1} mb={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          openTo="year"
                          views={["year", "month", "day"]}
                          label="D.O.B"
                          value={data.dob}
                          onChange={(dob) => {
                            setData({
                              ...data,
                              dob: dob,
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="dob"
                              fullWidth
                              variant="outlined"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Stack>
                    <Stack mt={1} mb={2}>
                      <TextField
                        autoComplete="given-name"
                        name="address"
                        id="address"
                        label="Address"
                        className="input-field"
                        fullWidth
                        value={data.address.address}
                        onChange={(e) =>
                          setData({
                            ...data,
                            address: {
                              ...data.address,
                              address: e.target.value,
                            },
                          })
                        }
                        variant="outlined"
                      />
                    </Stack>
                    <Stack mt={1} mb={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          openTo="year"
                          views={["year"]}
                          label="12th Passing Year"
                          value={data.year12th}
                          onChange={(year12th) => {
                            setData({
                              ...data,
                              year12th: year12th,
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="year12th"
                              fullWidth
                              variant="outlined"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Stack>
                    <Stack mt={1} mb={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          openTo="year"
                          views={["year"]}
                          label="10th Passing Year"
                          value={data.year10th}
                          onChange={(year10th) => {
                            setData({
                              ...data,
                              year10th: year10th,
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="year10th"
                              fullWidth
                              variant="outlined"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Stack>
                    <Stack mt={1} mb={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          openTo="year"
                          views={["year"]}
                          label="NEET YEAR - in which student want to qualify"
                          value={data.neetYear}
                          onChange={(neetYear) => {
                            setData({
                              ...data,
                              neetYear: neetYear,
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="neetYear"
                              fullWidth
                              variant="outlined"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack
                  className="college-form-heading"
                  display="inline-block"
                  spacing={2}
                  mb={2}
                >
                  <Typography
                    variant="h3"
                    component="h2"
                    sx={{
                      fontSize: { md: "30px", xs: "20px" },
                      fontWeight: 500,
                      color: "#1e439b",
                      lineHeight: 1.5,
                      display: "inline-block",
                      borderBottom: "3px solid #ff5501",
                    }}
                  >
                    Edit your Category
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={2} mt={1} mb={2}>
                  <TextField
                    autoComplete="given-name"
                    id="neetCategoryId"
                    select
                    name="neetCategoryId"
                    label="NEET Category"
                    className="input-field"
                    required
                    fullWidth
                    error={error.neetCategoryId}
                    helperText={errorHelperText.neetCategoryId}
                    variant="outlined"
                    value={data.neetCategoryId}
                    onChange={(e) => {
                      setData({ ...data, neetCategoryId: e.target.value });
                    }}
                  >
                    {neetCat.map((neet) => (
                      <MenuItem key={neet.category + neet.id} value={neet.id}>
                        {neet.category}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                <Stack direction="row" spacing={2} mt={1} mb={2}>
                  <TextField
                    autoComplete="given-name"
                    id="stateCategoryId"
                    select
                    name="stateCategoryId"
                    label="State Category"
                    className="input-field"
                    required
                    fullWidth
                    error={error.stateCategoryId}
                    helperText={errorHelperText.stateCategoryId}
                    value={data.stateCategoryId}
                    onChange={(e) =>
                      setData({ ...data, stateCategoryId: e.target.value })
                    }
                    variant="outlined"
                  >
                    {searchedStateCategoryOfState.map((item) => (
                      <MenuItem
                        key={
                          item.state_category.category + item.state_category.id
                        }
                        value={item.state_category.id}
                        selected
                      >
                        {item.state_category.category}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                <Stack direction="row" spacing={2} mt={1} mb={2}>
                  <TextField
                    autoComplete="gender"
                    id="gender"
                    select
                    name="gender"
                    label="Gender"
                    className="input-field"
                    required
                    fullWidth
                    error={error.gender}
                    helperText={errorHelperText.gender}
                    value={data.gender}
                    onChange={(e) =>
                      setData({ ...data, gender: e.target.value })
                    }
                    variant="outlined"
                  >
                    {gender.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                <Stack direction="row" spacing={2} mt={1} mb={2}>
                  <TextField
                    autoComplete="isPhCandidate"
                    id="isPhCandidate"
                    select
                    name="isPhCandidate"
                    label="Whether Physically Handicapped Candidate ?"
                    className="input-field"
                    required
                    fullWidth
                    error={error.isPhCandidate}
                    helperText={errorHelperText.isPhCandidate}
                    value={data.isPhCandidate}
                    onChange={(e) => {
                      setData({ ...data, isPhCandidate: e.target.value });
                    }}
                    variant="outlined"
                  >
                    <MenuItem key="1" value="true">
                      Yes
                    </MenuItem>
                    <MenuItem key="0" value="false">
                      No
                    </MenuItem>
                  </TextField>
                </Stack>
                <Stack direction="row" mt={1} mb={2} spacing={2}>
                  <TextField
                    autoComplete="armedForceId"
                    id="armedForceId"
                    select
                    name="armedForceId"
                    label="Whether Belongs To Armed Force ?"
                    className="input-field"
                    fullWidth
                    error={error.armedForceId}
                    helperText={errorHelperText.armedForceId}
                    value={data.armedForceId}
                    onChange={(e) => {
                      setData({ ...data, armedForceId: e.target.value });
                    }}
                    variant="outlined"
                  >
                    {initData.armedForce.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.armedForce}
                      </MenuItem>
                    ))}
                    <MenuItem key="a1" value={"NA"}>
                      NA
                    </MenuItem>
                  </TextField>
                </Stack>
                <Stack direction="row" mt={1} mb={2} spacing={2}>
                  <TextField
                    autoComplete="minorityId"
                    id="minorityId"
                    select
                    name="minorityId"
                    label="Whether Belongs To Minority ?"
                    className="input-field"
                    fullWidth
                    error={error.minorityId}
                    helperText={errorHelperText.minorityId}
                    value={data.minorityId}
                    onChange={(e) => {
                      setData({ ...data, minorityId: e.target.value });
                    }}
                    variant="outlined"
                  >
                    {initData.minority.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.minority}
                      </MenuItem>
                    ))}
                    <MenuItem key="a1" value={"NA"}>
                      NA
                    </MenuItem>
                  </TextField>
                </Stack>
                <Stack direction="row" mt={1} mb={2} spacing={2}>
                  <TextField
                    autoComplete="quotaId"
                    id="quotaId"
                    select
                    name="quotaId"
                    label="Any quota (NRI/ESI/NCC/Sports)?"
                    className="input-field"
                    fullWidth
                    error={error.quotaId}
                    helperText={errorHelperText.quotaId}
                    value={data.quotaId}
                    onChange={(e) => {
                      setData({ ...data, quotaId: e.target.value });
                    }}
                    variant="outlined"
                  >
                    {initData.quota.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.quota}
                      </MenuItem>
                    ))}
                    <MenuItem key="a1" value={"NA"}>
                      NA
                    </MenuItem>
                  </TextField>
                </Stack>
                {error.areaId || error.cityId ? (
                  <Stack direction="row" mt={1} mb={2} spacing={2}>
                    <Typography
                      variant="p"
                      component="p"
                      style={{ color: "red" }}
                    >
                      Please Check Above Data
                    </Typography>
                  </Stack>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Stack md={12} sm={12} lg={12} xs={12}>
              <Button
                type="submit"
                className="search-btn"
                sx={{
                  fontSize: "25px",
                  padding: "22px",
                  border: "1px solid #1366ce",
                  outline: "none",
                  cursor: "pointer",
                  mt: 2,
                  mb: 4,
                  color: "#fff",
                  backgroundColor: "#1366ce",
                  height: "50px",
                }}
              >
                Update
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default StudentDomicileEdit;
