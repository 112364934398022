import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";

export const sendOTP = ({ mobile }) => {
  return axios.post(BASE_URL + "auth/send-otp", { mobile });
};

export const verifyOTP = ({ mobile, otp }) => {
  return axios.post(BASE_URL + "auth/verify-otp", { mobile, code: otp });
};

export const verifyPassword = ({ username, password }) => {
  return axios.post(BASE_URL + "auth/verify-password", { username, password });
};

export const resetPassword = ({ username, password }) => {
  return axios({
    method: "post",
    url: BASE_URL + "auth/reset-password",
    headers: getHeaders(),
    data: JSON.stringify({ username, password }),
  });
};