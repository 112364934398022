import React from "react";
import { Stack, Typography } from "@mui/material";
import Faq from "react-faq-component";

const data = {
  // title: "FAQ (How it works)",
  rows: [
    {
      title: "What is MBBS Lighthouse?",
      content:
        "MBBS Lighthouse is India’s first advanced data analytics based online tool to guide students on their MBBS admission possibility based on NEET Score, Domicile and Category.",
    },
    {
      title: "How does MBBS Lighthouse work?",
      content:
        "MBBS Lighthouse is designed on historical round wise, category wise, counselling wise admission data of 702 medical colleges in India. This algorithm also includes counselling rules and regulations to show eligible colleges along with available medical colleges.",
    },
    {
      title:
        "How accurate are the admission probability reports generated by MBBS Lighthouse?",
      content:
        "MBBS Lighthouse is built on most comprehensive and authentic admission counselling data of 702 medical colleges. The data has been curated to retain uniformity in search operations. The reports are tested for six sigma levels of correctness and completeness.",
    },
    {
      title:
        "What factors are considered when predicting NEET ranks and MBBS college admission probabilities?",
      content:
        "Medical admissions depend on 4 factors: NEET score, domicile, category and fee budget. MBBS Lighthouse has been designed to calculate the most accurate MBBS admission probability based on these factors.",
    },
    // {
    //   title: "Is MBBS Lighthouse available for use in all states in India?",
    //   content:
    //     "Yes, MBBS Lighthouse is designed to include MCC and all state medical admission counselling data. Therefore MBBS Lighthouse can be used by students across India. MBBS Lighthouse is the only such application which offers all India usability. ",
    // },
    {
      title:
        "Can MBBS Lighthouse be used to predict admission probabilities for medical colleges outside of India?",
      content:
        "No. MBBS Lighthouse has been designed for predicting the admission probabilities for medical colleges in India only. The admission process for medical colleges outside of India is very different from medical admission counselling process in India.",
    },
    // {
    //   title:
    //     "How often are the admission probability reports and NEET rank predictions updated?",
    //   content:
    //     "MBBS Lighthouse data is updated on yearly basis. NEET Navigator is pioneer in the field of integrating advanced data analytics into the field of medical admission counselling guidance in India.",
    // },
    // {
    //   title:
    //     "Is there a limit to the number of times I can use MBBS Lighthouse to predict admission probabilities or NEET ranks?",
    //   content:
    //     "You can use MBBS Lighthouse as many times as you may wish. All your previous search reports are archived in the student’s account section. Most students use MBBS Lighthouse on 4 occasions: at time of full course practice tests, after NEET UG, after publishing of your OMR and official answer keys and on receipt of their NEET Rank.",
    // },
    // {
    //   title:
    //     "Can MBBS Lighthouse predict my chances of getting into a specific medical college?",
    //   content:
    //     "You can use MBBS Lighthouse for Free Rank prediction, Marks & NEET Rank based MBBS admission possibility estimation and also use college specific query in student account section to check chances into a specific medical college. ",
    // },
    // {
    //   title:
    //     "How can I contact customer support if I have any questions or concerns about using MBBS Lighthouse?",
    //   content:
    //     "MBBS Lighthouse customer support can be reached at +91-9717366344 on all days between 1000-1800 Hrs. You can also contact customer support through email at lighthouse@neetnavigator.com",
    // },
    // {
    //   title: "How does MBBS Lighthouse predict NEET rank?",
    //   content:
    //     "Rank prediction is based on all rank data of 2022 NEET result. This rank data has been derived from machine learning by feeding in about 1,50,000 data entries. The rank predictor has been tested for over 95% accuracy for NEET scores less than 680.",
    // },
    {
      title: "Can I trust MBBS Lighthouse's predictions?",
      content: `Yes. MBBS Lighthouse predictions are useful tool for admission strategy planning process. The reports are based on 92% counselling data of ${
        new Date().getFullYear() - 1
      } admissions. But you must always be aware of the fact that counselling outcomes are likely to change in ${new Date().getFullYear()}. `,
    },
    {
      title: "Can I download my admission possibility report?",
      content:
        "Yes. MBBS Lighthouse offers the functionality to download and save the generated reports in PDF format.",
    },
  ],
};


const styles = {
  titleTextColor: "#000",
  rowTitleColor: "#052F5F",
  rowTitleTextSize: "18px",
  rowContentTextSize: "14px",
  rowContentColor: "#1e439b",
  arrowColor: "#052F5F",
  rowContentPaddingTop: "10px",
  rowContentPaddingBottom: "10px",
};

const config = {
  animate: true,
  arrowIcon: "V",
  openOnload: 0,
  expandIcon: "+",
  collapseIcon: "-",
};

const FaqAnswer = () => {
  return (
      <Stack sx={{ fontFamily: ["Poppins", "sans-serif"].join(",") }}>
        <Faq data={data} styles={styles} config={config} />
      </Stack>
  );
}
export default FaqAnswer;
