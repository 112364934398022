import axios from "axios";
import { BASE_URL } from "../constants";
import  getHeaders from "../constants/headers";

export const getCollege = () => {
    
   return axios({
      method: "get",
      url: BASE_URL + "college",
      headers:  getHeaders(),
    });
}


const serialize = (params) =>
  Object.keys(params)
    .map((key) => key + "=" + encodeURIComponent(params[key]))
    .join("&");


export const getCollegeGraph = (payload) => {
  return axios({
    method: "get",
    url: BASE_URL + "college?" + serialize(payload),
    headers: getHeaders(),
  });
};

export const getUserCollegeCount = (payload) => {
  return axios({
    method: "get",
    url: BASE_URL + "college/user?" + serialize(payload),
    headers: getHeaders(),
  });
};

export const getCollegeCount = () => {
  return axios({
    method: "get",
    url: BASE_URL + "college/count",
    headers: getHeaders(),
  });
};

export const getCollegeState = () => {
  return axios({
    method: "get",
    url: BASE_URL + "college/state",
    headers: getHeaders(),
  });
};

export const getSearchCollegeCount = () => {
  return axios({
    method: "get",
    url: BASE_URL + "college/search-college-institute",
    headers: getHeaders(),
  });
};

export const getSearchCollege = () => {
  return axios({
    method: "get",
    url: BASE_URL + "college/search",
    headers: getHeaders(),
  });
};

export const getFindByMarks = (marks, states) => {
  return axios({
    method: "get",
    url: BASE_URL + "/college-rank/find-by-marks?marks=" + marks + (states ? "&states=" + states : ""),
    headers: getHeaders(),
  });
};

export const getFindByRank = (rank) => {
  return axios({
    method: "get",
    url: BASE_URL + "/college-rank/find-by-rank?rank=" + rank,
    headers: getHeaders(),
  });
};

export const getCountByRank = (rank) => {
  return axios({
    method: "get",
    url: BASE_URL + "/college-rank/count?rank=" + rank,
    headers: getHeaders(),
  });
};

export const getCountByMarks = (marks) => {
  return axios({
    method: "get",
    url: BASE_URL + "/college-rank/count?marks=" + marks,
    headers: getHeaders(),
  });
};

export const getCountByMarksGroupByState = (marks) => {
  return axios({
    method: "get",
    url: BASE_URL + "/college-rank/count?state=" + marks,
    headers: getHeaders(),
  });
};

export const getCollegeCountByInstitutionType = () => {
  return axios({
    method: "get",
    url: BASE_URL + "college/institution-type",
    headers: getHeaders(),
  });
};