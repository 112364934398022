export const getAccessToken = () => {
  return localStorage.getItem("token");
}

export const setAccessToken = (token) => {
  localStorage.setItem("token", token);
};

export const removeAccessToken = () => {
  localStorage.removeItem("token");
};
//  access token end
export const getUserId = () => {
  return localStorage.getItem("id");
};

export const setUserId = (id) => {
  localStorage.setItem("id", id);
};

// userId end
export const getStudentId = () => {
  return localStorage.getItem("studentId");
};

export const setStudentId = (id) => {
  localStorage.setItem("studentId", id);
};

export const removeLoginData = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("id");
  localStorage.removeItem("studentId");
};

// studentID end
export const setStudentMarksStore = (marks) => {
  localStorage.setItem("marks", marks);
};

export const getStudentMarksStore = () => {
  return localStorage.getItem("marks");
};

export const removeStudentMarksStore = () => {
  localStorage.removeItem("marks");
};
// student marks store end
export const setStudentRankStore = (rank) => {
  localStorage.setItem("rank", rank);
};

export const getStudentRankStore = () => {
  return localStorage.getItem("rank");
};

export const removeStudentRankStore = () => {
  localStorage.removeItem("rank");
};

// student rank store end
export const setSelectedStateStore = (selectedState) => {
  localStorage.setItem("selectedState", selectedState);
};

export const getSelectedStateStore = () => {
  return localStorage.getItem("selectedState");
};

export const removeSelectedStateStore = () => {
  localStorage.removeItem("selectedState");
};
// selectedState end
export const setStudentFindRankStore = (studentFindRank) => {
  localStorage.setItem("studentFindRank", studentFindRank);
};

export const getStudentFindRankStore = () => {
  return localStorage.getItem("studentFindRank");
};

export const removeStudentFindRankStore = () => {
  localStorage.removeItem("studentFindRank");
};
// studentFindRank end
