import underline from "../../assets/line.svg";
import {getAccessToken} from "../../services/serviceToken";
export default function HeroBanner({collegeDataCount}) {
    const tokenValue = getAccessToken();

    let newCount = tokenValue ? collegeDataCount.length : collegeDataCount;
    return (
        <>
            <div className="font-inter flex flex-col items-center mt-[94px]">
                <div className="flex flex-col items-center mt-3 relative"  style={{marginBottom:30}}>
                    <h1 className="text-xl md:text-4xl sm:text-2xl font-semibold text-center">Start Your<span
                        className="text-[#1366cf]"> MBBS Admission </span>Journey
                        Today</h1>
                    <h1 className="text-xl md:text-4xl sm:text-2xl font-semibold text-center">Find Your Perfect Fit Among <span
                        className="text-xl md:text-4xl sm:text-2xl text-center text-[#1366cf]"> {newCount}</span> Indian Colleges</h1>
                    {/* <div className="md:absolute top-24 right-52">
                        <img src={underline} alt="underline" className="w-[90%]"/>
                    </div> */}
                </div>
            </div> 
        </>
    )
}