import React, { useEffect, useState } from "react";
import StudentData from "../components/StudentData/StudentData";
import { findUser } from "../services/user";
import { getUserId } from "../services/serviceToken";
import { findStudent } from "../services/student";
import { useLocation } from "react-router-dom";
import { getSuccessPaymentDetail } from "../services/paymentOrder";

export default function StudentAccount() {
  const location = useLocation().state;
  const [userDetail, setUserDetail] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const [studentDetail, setStudentDetail] = useState({
    gender: "",
    stateId12th: "",
    stateId10th: "",
    isPhCandidate: "",
    area: { area: "" },
    quota: { quota: "" },
    state10th: { name: "" },
    state12th: { name: "" },
    minority: { minority: "" },
    armed_force: { armedForce: "" },
    neet_category: { category: "" },
    state_category: { category: "" },
    address: {
      address: "",
      pincode: "",
      state: { name: "" },
      city: { name: "" },
    },
  });

  const [paymentStatusData, setPaymentStatusData] = useState([]);
  const [showEdit, setShowEdit] = useState(false);

  const userId = getUserId();
  useEffect(() => {
    window.scrollTo(0, 0);
    async function getUserData() {
      findUser(userId)
        .then((response) => {
          setUserDetail(response.data.result);
          if (!response.data.result.error) {
            const studentId = response.data.result.student.id;
            findStudent(studentId)
              .then((response) => {
                setStudentDetail(response.data.result);
                if (!response.data.result.error) {
                  getSuccessPaymentDetail()
                    .then((response) => {
                      const paymentStatusData = response.data.result.filter(
                        (c) =>
                          c.userId === parseInt(userId) &&
                          c.order_status === "Success"
                      );
                      if (!paymentStatusData.length) {
                        setShowEdit(true);
                      }
                    })
                    .catch((err) => {
                      console.error("error", err);
                    });
                }
              })
              .catch((err) => {
                console.error("error", err);
              });
          }
        })
        .catch((err) => {
          console.error("error", err);
        });
    }
    getUserData();
  }, []);

  return (
    <StudentData
      studentDetail={studentDetail}
      userDetail={userDetail}
      location={location}
      showEdit={showEdit}
    />
  );
}
