import React from 'react'
import { Box, Card, Fade, Grid, Modal, Stack, styled, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "700px", md: "700px", sm: "340px", xs: "340px" },
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,

  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "0em",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: "1em",
  },
};

const MessagePopupModel = ({ modalOpen, setModalOpen, title, message }) => {
  return (
    <>
      {
        <StyledModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          closeAfterTransition={false}
        >
          <Fade in={modalOpen}>
            <Box sx={style}>
              <Stack
                sx={{
                  right: 5,
                  top: 10,
                  position: "absolute",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{ color: "red", fontSize: 20 }}
                  onClick={() => setModalOpen(false)}
                >
                  <CloseIcon />
                </span>
              </Stack>
              <Grid item xs={12} md={12}>
                <Card
                  sx={{
                    width: { lg: 700, md: 700, sm: 340, xs: 340 },
                  }}
                >
                  <Typography
                    variant="h3"
                    component="h2"
                    lineHeight="27px"
                    textAlign="center"
                    m="0 0 10px"
                    sx={{
                      fontSize: {
                        lg: "16px",
                        md: "16px",
                        sm: "14px",
                        xs: "14px",
                      },
                      border: "#e5e5e5",
                      backgroundColor: "#f2f2f2",
                      padding: "16px",
                      color: "#333333",
                      fontWeight: 600,
                    }}
                  >
                    {title}
                  </Typography>
                  <Container maxWidth="xl">
                    <Grid
                        container
                        spacing={3}
                        sx={{
                          flexGrow: 1, 
                          padding: {
                              lg: "15px 100px",
                              md: "15px 100px",
                              xs: "10px 10px",
                          },
                          borderRadius: '20px',
                          margin: 'auto',
                        }}
                        xs={12} md={18}
                    >
                        {message}
                    </Grid>
                </Container>
                </Card>
              </Grid>
            </Box>
          </Fade>
        </StyledModal>
      }
    </>
  );
};

export default MessagePopupModel;