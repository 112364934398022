import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";


// export const axiosRes =  () => {
//   return axios.create({
//     url: BASE_URL,
//   });
// }
  


export const createAddress = ({studentId, address, stateId, cityId, pincode }) => {
  return axios({
    method: "post",
    url: BASE_URL + "address",
    headers: getHeaders(),
    data: JSON.stringify({ studentId, address, stateId, cityId, pincode }),
  });
};

export const updateAddress = (id,{
  address,
  stateId,
  cityId,
  pincode,
}) => {
  return axios({
    method: "put",
    url: BASE_URL + "address/" + id,
    headers: getHeaders(),
    data: JSON.stringify({ address, stateId, cityId, pincode }),
  });
};
