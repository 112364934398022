import React, { useEffect } from "react";
import {
    Button,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import FaqAnswer from "../components/HomePageComponents/FaqAnswer";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";
import image from "../pgBannerBackground.png"; 
import { getCollegeCount } from "../services/colleges";
import { useState } from "react";


const Overview = () => {
    const navigate = useNavigate();
    const [collegeDataCount, setCollegeDataCount] = useState("")

     useEffect(() => {
       window.scrollTo(0, 0);
       async function CollegeCount() {
         getCollegeCount()
           .then((response) => {
             if (!response.data.error) {
               setCollegeDataCount(response.data.result);
             }
           })
           .catch((err) => {
             console.error("error", err);
           });
       }
       CollegeCount();
     }, []);
  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
        sx={{
          flexGrow: 1,
          width: "calc(100% + 48px)",
          backgroundImage: `url(${image})`,
        }}
        mt={2}
        mb={5}
      >
        <Grid item>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h3"
              component="h2"
              sx={{
                fontSize: { md: "30px", xs: "17px" },
                color: "#1e439b",
                lineHeight: 1.5,
                display: "inline-block",
                borderBottom: "3px solid #ff5501",
              }}
            >
              Myopia in Medical Admissions
            </Typography>
            <Typography
              p={3}
              variant="p"
              component="p"
              textAlign="justify"
              sx={{
                fontSize: { md: "18px", xs: "14px" },
                lineHeight: 1.5,
              }}
            >
              In India, there are more than 700 medical colleges, however
              students and parents have a narrow perspective on their medical
              admissions. People underestimate their chances of admission to
              medical schools outside of their city or state.
            </Typography>
            <Typography
              pb={3}
              pl={3}
              pr={3}
              variant="p"
              textAlign="justify"
              component="p"
              sx={{
                fontSize: { md: "18px", xs: "14px" },
                lineHeight: 1.5,
              }}
            >
              In order for a student to get admitted to The Best Medical College
              based on AIR, Domicile, Category, and Fee Budget, MBBS Lighthouse
              delivers the most thorough and authentic intelligence on medical
              admissions.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h3"
              component="h2"
              sx={{
                fontSize: { md: "30px", xs: "17px" },
                color: "#1e439b",
                lineHeight: 1.5,
                display: "inline-block",
                borderBottom: "3px solid #ff5501",
              }}
            >
              Features
            </Typography>

            <List
              sx={{
                fontFamily: ["Poppins", "sans-serif"].join(","),
                listStyleType: "disc",
                pl: 2,
                ml: 3,
                "& .MuiListItem-root": {
                  display: "list-item",
                },
              }}
            >
              <ListItem sx={{ textAlign: "justify" }}>
                Authentic: MBBS Lighthouse is based on most authentic medical
                admission data of Government, Private and Deemed medical
                institutions of All India.
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Updated: MBBS Lighthouse is based on latest{" "}
                {new Date().getFullYear() - 1} medical admission counselling
                data from MCC and all state medical admission counselling.
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Complete: MBBS Lighthouse incorporates all counselling rules and
                regulations related to eligibility conditions, counselling
                rounds and college information.
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                User Specific: MBBS Lighthouse analyzes user specific data such
                as NEET score, domicile, category and fee budget to give best
                medical college recommendations.
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h3"
              component="h2"
              sx={{
                fontSize: { md: "30px", xs: "17px" },
                color: "#1e439b",
                lineHeight: 1.5,
                display: "inline-block",
                borderBottom: "3px solid #ff5501",
              }}
            >
              Advantages
            </Typography>

            <List
              sx={{
                fontFamily: ["Poppins", "sans-serif"].join(","),
                listStyleType: "disc",
                pl: 2,
                ml: 3,
                "& .MuiListItem-root": {
                  display: "list-item",
                },
              }}
            >
              <ListItem sx={{ textAlign: "justify" }}>
                Comprehensive: You won’t miss your MBBS admission possibility in
                any college of India. MBBS Lighthouse is designed on admission
                data of {collegeDataCount} medical colleges.
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Convenient: MBBS Lighthouse runs on cloud based mobile friendly
                web interface which can be accessed from any device, anytime.
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Customized: Unlike many college predictors, MBBS Lighthouse
                offers specific advice on basis of your NEET score, domicile,
                NEET and State category and Fee budget.
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Cost effective: MBBS Lighthouse saves on huge direct and
                indirect costs of searching for information on internet. You can
                get authentic data in few clicks.
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h3"
              component="h2"
              sx={{
                fontSize: { md: "30px", xs: "17px" },
                color: "#1e439b",
                lineHeight: 1.5,
                display: "inline-block",
                borderBottom: "3px solid #ff5501",
              }}
            >
              Benefits
            </Typography>

            <List
              sx={{
                fontFamily: ["Poppins", "sans-serif"].join(","),
                listStyleType: "disc",
                pl: 2,
                ml: 3,
                "& .MuiListItem-root": {
                  display: "list-item",
                },
              }}
            >
              <ListItem sx={{ textAlign: "justify" }}>
                Expanded horizon: With few clicks, MBBS Lighthouse gives you
                MBBS admission possibility estimation for {collegeDataCount}{" "}
                medical colleges.
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Enhanced Possibilities: MBBS Lighthouse enhances your MBBS
                admission possibilities for all government, private and deemed
                medical institutions.
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Competitive advantage: By knowing your realistic MBBS admission
                possibilities, you can be in better control of the medical
                admission counselling process.
              </ListItem>
              <ListItem sx={{ textAlign: "justify" }}>
                Stress reduction: MBBS Lighthouse eliminates the guess work from
                MBBS admission planning. It offers right motivation and
                psychological safety for students.
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Grid item sx={{ paddingRight: "24px" }}>
          <Grid mt={4} mb={3}>
            <FaqAnswer />
          </Grid>
          <Grid item md={12} sm={12} lg={12} xs={12}>
            <Stack md={12} sm={12} lg={12} xs={12}>
              <Button
                className="search-btn"
                size={"small"}
                fullWidth
                sx={{
                  fontSize: { lg: "25px", md: "25px", xs: "16px" },
                  border: "1px solid #1366ce",
                  outline: "none",
                  cursor: "pointer",
                  mt: 1,
                  mb: 1,
                  color: "#fff",
                  backgroundColor: "#1366ce",
                }}
                onClick={(e) => {
                  navigate("/student-marks");
                }}
              >
                {`Click here to check your MBBS admission possibility in NEET ${new Date().getFullYear()}`}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Overview;
