import { Typography } from '@mui/material';
import React from 'react'

const RightSideCollegeHeading = () => {
  return (
    <div>
      <Typography
        variant="h2"
        component="h1"
        mt={1}
        sx={{
          fontSize: { md: "24px", xs: "16px" },
          fontWeight: 700,
          color: "#052F5F",
        }}
      >
        <span>Know your chances of MBBS Admission</span>
      </Typography>
      <Typography
        variant="h2"
        component="h2"
        mt={1}
        textAlign="justify"
        sx={{
          fontSize: { md: "16px", xs: "12px" },
          fontWeight: 500,
          lineHeight:"1.4"
        }}
      >
        Please enter your Domicile, Category and NEET details to predict your chances of MBBS admission in NEET {new Date().getFullYear()}
      </Typography>
    </div>
  );
}

export default RightSideCollegeHeading