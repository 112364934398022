import React from "react";
import ReactApexChart from "react-apexcharts";

const CollegeGraph = ({ graphData = [0, 0, 0] }) => {
  const graph = {
    series: [
      {
        name: "Available Colleges",
        data: graphData,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      noData: {
        text: "Loading...",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#000000",
          fontSize: "14px",
          fontFamily: "Poppins sans-serif",
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "";
        },
        offsetY: 1,
        style: {
          fontSize: "12px",
          colors: ["#000"],
          fontWeight: 700,
        },
      },
      grid: {
        show: false, // you can either change hear to disable all grids
        xaxis: {
          lines: {
            show: false, //or just here to disable only x axis grids
          },
        },
        yaxis: {
          lines: {
            show: false, //or just here to disable only y axis
          },
        },
      },

      xaxis: {
        categories: ["Government", "Private", "Deemed"],
        position: "bottom",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + "";
          },
        },
      },
      // colors: [
      //   function ({ value, seriesIndex, dataPointIndex, w }) {
      //     if (dataPointIndex == 0) {
      //       return "#f59042";
      //     } else if (dataPointIndex == 1) {
      //       return "#f54242";
      //     } else {
      //       return "#f54281";
      //     }
      //   },
      // ],
    },
  };
  // height={{ sm: 200, md: 500 }}
  //       width={{ sm: 200, md: 500 }}
  return (
    <div id="chart">
      <ReactApexChart
        options={graph.options}
        series={graph.series}
        type="bar"
        height={500}
        width={370}
      />
    </div>
  );
};

export default CollegeGraph;
