import FaqAnswer from "../HomePageComponents/FaqAnswer";

export default function FAQ (){
    return (
        <div className="bg-[#eaf1ff] p-6 lg:p-16">
            <div className="bg-white p-6 lg:p-16 grid grid-cols-1 lg:grid-cols-4 rounded-lg">
            <div className="flex items-center lg:order-2 order-1">
                <h2 className="text-wrap text-xl lg:text-4xl md:text-2xl font-semibold my-8 ml-4 text-[#1366cf]">Frequently <span className="text-black">Asked</span> Questions</h2>
                </div>
                <div className="p-5 lg:col-span-3 lg:order-1 order-2">
                <FaqAnswer />
                </div>
                
            </div>
        </div>
    )
}