import React from 'react';
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Nav from "../../src/components/home/Nav";


const Root = () => (
  <>
    <Nav/>
    <Outlet />
    <Footer />
  </>
);

export default Root;
