import React from "react";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useState } from "react";
import { validatePhone } from "../components/Regex";
import TermConditionModal from "../components/TermConditionModal";
import { Stack } from "@mui/system";
import { resetPassword, sendOTP, verifyOTP } from "../services/auth";
import {
  MobileInfo,
  OtpInfo,
  PasswordInfo,
  UsernameInfo,
} from "../components/PopOverInfo/RegistrationInfo";
import {
  removeAccessToken,
  setAccessToken,
  setUserId,
} from "../services/serviceToken";
import { clearToken } from "../redux/authSlice";
import { Link, useNavigate } from "react-router-dom";
import CollegesHeading from "../components/HomePageComponents/CollegesHeading";
import SlickSlider from "../components/HomePageComponents/SlickSlider";
import FaqAnswer from "../components/HomePageComponents/FaqAnswer";
import FourImageCard from "../components/FourImageCard";
import { ResetPasswordMessage } from "../utils/alertMessage";
import RightSideCollegeHeading from "../components/HomePageComponents/RightSideCollegeHeading";
import YourGpsMbbs from "../components/HomePageComponents/YourGpsMbbs";
import { useEffect } from "react";
import { getCollegeCount } from "../services/colleges";
import VideoSection from "../components/HomePageComponents/VideoSection";

const PasswordReset = (props) => {
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState("");
  const [tokenKey, setTokenKey] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [errorMobile, setErrorMobile] = useState(false);
  const [errorOTP, setErrorOTP] = useState(false);
  const [errorUsername, setErrorUsername] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorHelperTextMobile, setErrorHelperTextMobile] = useState("");
  const [errorHelperTextOTP, setErrorHelperTextOTP] = useState("");
  const [errorHelperTextUsername, setErrorHelperTextUsername] = useState("");
  const [checked, setChecked] = useState(false);
  const [checkBoxErr, setCheckBoxErr] = useState(false);
  const [checkBoxErrText, setCheckBoxErrText] = useState("");

  const navigate = useNavigate();

  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);
    if (!checked) {
      // handleTcModalOpen();
      setCheckBoxErr(false);
      setCheckBoxErrText("");
    }
  };

  const [tcModalOpen, setTcModalOpen] = useState(false);
  const handleTcModalOpen = () => setTcModalOpen(true);

  const handleSendOTP = async (event) => {
    event.preventDefault();
    if (!mobile) {
      setErrorMobile(true);
      setErrorHelperTextMobile("Mobile Number is required");
    } else if (!validatePhone.test(mobile)) {
      setErrorMobile(true);
      setErrorHelperTextMobile("Mobile Number should be of 10 digit");
    } else if (!checked) {
      setCheckBoxErr(true);
      setCheckBoxErrText("Please Accept Terms & Conditions");
    } else {
      setErrorMobile(false);
      setErrorHelperTextMobile("");
      sendOTP({ mobile: mobile })
        .then((response) => {
          if (!response.data.error) {
            setIsMobile(true);
          } else {
            setErrorMobile(true);
            setErrorHelperTextMobile(response.data.message);
          }
        })
        .catch((err) => {
          setErrorMobile(true);
          setErrorHelperTextMobile(err.errors.message);
        });
    }
  };

  const verifySendOTP = async (event) => {
    event.preventDefault();
    if (!otp) {
      setErrorOTP(true);
      setErrorHelperTextOTP("OTP is required");
    } else if (otp.length !== 6) {
      setErrorOTP(true);
      setErrorHelperTextOTP("OTP should be of 6 digit");
    } else {
      setErrorOTP(false);
      setErrorHelperTextOTP("");
      verifyOTP({ mobile: mobile, otp: otp })
        .then((response) => {
          if (!response.data.error) {
            const token = response.data.result.token;
            const id = response.data.result.id;
            // successMessage("User Login Successfully ");
            setAccessToken(token);
            setUserId(id);
            // dispatch(setToken(token));
            setTokenKey(token);
            setUsername(mobile);
          } else {
            setErrorOTP(true);
            setErrorHelperTextOTP(response.data.message);
          }
        })
        .catch((err) => {
          // errorMessage(err.errors.message);
          setErrorOTP(true);
          setErrorHelperTextOTP(err.errors.message);
        });
    }
  };

  const ResetPassword = async (event) => {
    event.preventDefault();
    if (!username) {
      setErrorUsername(true);
      setErrorHelperTextUsername("Username is required");
    } else if (username.length < 2) {
      setErrorUsername(true);
      setErrorHelperTextUsername("Username should be greater than 2 alphabet");
    } else if (!password) {
      setErrorPassword(true);
    } else if (password.length < 6) {
      setErrorPassword(true);
    } else if (!checked) {
      setCheckBoxErr(true);
      setCheckBoxErrText("Please Accept Terms & Conditions");
    } else {
      setErrorUsername(false);
      setErrorHelperTextUsername("");
      setErrorPassword(false);
      resetPassword({ username, password })
        .then((response) => {
          if (!response.data.error) {
            ResetPasswordMessage();
            removeAccessToken();
            dispatch(clearToken());
            navigate("/login");
          } else {
            console.log("err ", response.data.message);
            setErrorUsername(true);
            setErrorHelperTextUsername("Username is not correct");
            setErrorPassword(true);
          }
        })
        .catch((err) => {
          console.log("err ", err.errors.message);
          setErrorUsername(true);
          setErrorHelperTextUsername("Username is not correct");
          setErrorPassword(true);
        });
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [collegeDataCount, setCollegeDataCount] = useState(0);

  useEffect(() => {
     window.scrollTo(0, 0);
    async function CollegeCount() {
      getCollegeCount()
        .then((response) => {
          if (!response.data.error) {
            setCollegeDataCount(response.data.result);
          }
        })
        .catch((err) => {
          console.error("error", err);
        });
    }
    CollegeCount();
  }, []);

  return (
    <>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={3}
          sx={{ width: "calc(100% + 48px)", marginTop: "0px", flexGrow: 1 }}
        >
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              background: "linear-gradient(135deg, #4D22E0 0%, #356EF5 100%)",
              color: "#fff",
              paddingLeft: "0px",
            }}
          >
            <Stack
              sx={{
                padding: {
                  lg: "0px 30px 10px 20px",
                  md: "0px 30px 10px 20px",
                  xs: "0px 30px 10px 20px",
                },
              }}
            >
              <CollegesHeading collegeDataCount={collegeDataCount} />
              <YourGpsMbbs />
              <Stack mt={2} mb={2}>
                <SlickSlider />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={7}>
            <Stack
              sx={{
                padding: {
                  lg: "15px 100px",
                  md: "15px 100px",
                  xs: "10px 10px",
                },
              }}
            >
              <Grid item xs={12} md={12}>
                <Stack className="college-heading" spacing={2}>
                  <RightSideCollegeHeading />
                </Stack>
              </Grid>
              <Stack
                className="college-form-heading"
                display="inline-block"
                spacing={2}
                mt={2}
                mb={2}
              >
                <Typography
                  variant="h3"
                  component="h2"
                  sx={{
                    fontSize: { md: "30px", xs: "20px" },
                    color: "#1e439b",
                    lineHeight: 1.5,
                    display: "inline-block",
                    borderBottom: "3px solid #ff5501",
                  }}
                >
                  Reset Password
                </Typography>
              </Stack>
              {!tokenKey && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  component="form"
                  onSubmit={!isMobile ? handleSendOTP : verifySendOTP}
                  noValidate
                  mt={1}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Stack direction="row-reverse">
                        <Box sx={{display: "flex", alignItems: "center", width: "5%"}}><MobileInfo /></Box>
                        <Box sx={{width: "95%"}}>
                        <TextField
                          label="Mobile Number"
                          fullWidth
                          variant="outlined"
                          id="mobileNumber"
                          name="mobileNumber"
                          autoFocus
                          error={errorMobile}
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                          helperText={errorHelperTextMobile}
                          InputProps={{
                            readOnly: false,
                          }}
                        />
                        </Box>
                      </Stack>
                      {!isMobile && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checked}
                              onChange={handleChangeCheck}
                              color="success"
                            />
                          }
                          className={
                            checkBoxErr && checkBoxErr
                              ? "term-condition"
                              : "term-condition-accept"
                          }
                          label="Agree to Terms & Conditions"
                        />
                      )}
                      {checkBoxErr && (
                        <p className="term-condition-error-msg">
                          {checkBoxErrText}
                        </p>
                      )}
                    </Grid>
                    {isMobile && (
                      <Grid item xs={12} md={12}>
                        <Stack direction="row-reverse">
                          <Box sx={{display: "flex", alignItems: "center", width: "5%"}}><OtpInfo /></Box>
                          <Box sx={{width: "95%"}}>
                          <TextField
                            label="OTP"
                            required
                            fullWidth
                            variant="outlined"
                            id="otp"
                            name="otp"
                            autoFocus
                            error={errorOTP}
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            helperText={errorHelperTextOTP}
                          />
                          </Box>
                        </Stack>
                      </Grid>
                    )}
                    <Grid item md={12} sm={12} lg={12} xs={12}>
                      <Stack md={12} sm={12} lg={12} xs={12}>
                        <Box sx={{width: "95%"}}>
                        <Button
                          type="submit"
                          className="search-btn"
                          size={"small"}
                          fullWidth
                          sx={{
                            fontSize: { lg: "25px", md: "25px", xs: "16px" },
                            border: "1px solid #1366ce",
                            outline: "none",
                            cursor: "pointer",
                            mt: 1,
                            mb: 1,
                            color: "#fff",
                            backgroundColor: "#1366ce",
                          }}
                        >
                          {!isMobile ? "Get OTP" : "Submit OTP"}
                        </Button>
                        </Box>
                        <Box sx={{width: "5%"}}></Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {tokenKey && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  component="form"
                  onSubmit={ResetPassword}
                  noValidate
                  mt={1}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Stack direction="row-reverse">
                        <Box sx={{display: "flex", alignItems: "center", width: "5%"}}><UsernameInfo /></Box>
                        <Box sx={{width: "95%"}}>
                        <TextField
                          label="Mobile Number"
                          fullWidth
                          variant="outlined"
                          id="username"
                          name="username"
                          autoFocus
                          error={errorUsername}
                          helperText={errorHelperTextUsername}
                          value={mobile}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Stack direction="row-reverse">
                        <Box sx={{display: "flex", alignItems: "center", width: "5%"}}><PasswordInfo /></Box>
                        <Box sx={{width: "95%"}}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          autoComplete="given-name"
                          required
                          name="password"
                          error={errorPassword}
                          onChange={(e) => setPassword(e.target.value)}
                        >
                          <InputLabel htmlFor="password">Password</InputLabel>
                          <OutlinedInput
                            name="password"
                            id="password"
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                          />
                        </FormControl>
                        </Box>
                      </Stack>

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked}
                            onChange={handleChangeCheck}
                            color="success"
                          />
                        }
                        className={
                          checkBoxErr && checkBoxErr
                            ? "term-condition"
                            : "term-condition-accept"
                        }
                        label="Agree to Terms & Conditions"
                      />
                      {checkBoxErr && (
                        <p className="term-condition-error-msg">
                          {checkBoxErrText}
                        </p>
                      )}
                    </Grid>
                    <Grid item md={12} sm={12} lg={12} xs={12}>
                      <Stack md={12} sm={12} lg={12} xs={12}>
                        <Box sx={{width: "95%"}}>
                        <Button
                          type="submit"
                          className="search-btn"
                          size={"small"}
                          fullWidth
                          sx={{
                            fontSize: { lg: "25px", md: "25px", xs: "16px" },
                            border: "1px solid #1366ce",
                            outline: "none",
                            cursor: "pointer",
                            mt: 1,
                            mb: 1,
                            color: "#fff",
                            backgroundColor: "#1366ce",
                          }}
                        >
                          {"Submit"}
                        </Button>
                        </Box>
                        <Box sx={{width: "5%"}}></Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              )}
              <Box sx={{width: "95%"}}>
                <Grid container justifyContent="flex-end">
                <Grid
                  item
                  sx={{
                    color: "black",
                    fontFamily: ["Poppins", "sans-serif"].join(","),
                  }}
                >
                  Don't have an account?{" "}
                  <Link
                    to={"/register"}
                    style={{ color: "#1f75cb", textDecoration: "none" }}
                  >
                    Register Now
                  </Link>
                </Grid>
              </Grid>
              </Box>
              <Box sx={{width: "5%"}}></Box>
            </Stack>
          </Grid>
        </Grid>
        <TermConditionModal
          tcModalOpen={tcModalOpen}
          setTcModalOpen={setTcModalOpen}
        />
      </Container>
      <Container>
        <FourImageCard />
        <VideoSection />
        <FaqAnswer />
      </Container>
    </>
  );
};

export default PasswordReset;
