import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { getStudentRankStore, setStudentFindRankStore, setStudentRankStore } from "../services/serviceToken";
import { getPaymentDetail, newOrder } from "../services/paymentOrder";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: 0,
    fontSize: 24,
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: 0,
    fontSize: 14,
    fontWeight: 500,
  },
  [`&.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: 0,
    fontSize: 16,
    fontWeight: 600,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
    border: 0,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CheckoutFormRank = ({ rank, findRank, userData }) => {
  const navigate = useNavigate();

  const { name, email, mobile, student: { address } } = userData;
  const {city,state,pincode} = address;

  const confirmAddress = address.address !== null ? address.address : "NA";

  const [selected, setSelected] = useState(null);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [promoCode, setPromoCode] = useState("");

  const handleChange = React.useCallback((value) => {
    setSelected(value);
    setHelperText(" ");
    setError(false);
  }, []);

  const findRankValue = JSON.stringify(findRank);
  setStudentRankStore(rank);
  setStudentFindRankStore(findRankValue);

  const redirectUrl =
    "https://neetnavigator.com/CCAvenuePayment/ccavResponseHandler.php";
  const packagePrice = selected === null ? "0.00" : selected.price;
  const gstPrice = selected === null ? "0.00" : Math.round(selected.price*0.18);
  const totalPrice = selected === null ? "0.00" : packagePrice + gstPrice;
  const PriceId = selected === null ? "0" : selected.id;

  const [paymentStatusData, setPaymentStatusData] = useState([]);

  useEffect(() => {
     window.scrollTo(0, 0);
    async function getUserData() {
      getPaymentDetail().then((response) => {
        setPaymentStatusData(response.data.result);
      });
    }
    getUserData();
  }, []);
 
   const studentRankStore = getStudentRankStore();
   const successPaymentStatus = paymentStatusData.filter(
     (c) => c.order_status === "Success"
   );
   const checkPaymentStatus = (obj) =>
     obj.merchant_param3 == PriceId && obj.merchant_param4 == studentRankStore;
   const paymentSuccessStatus = successPaymentStatus.some(checkPaymentStatus);

  const handleCheckOutFormRank = async (event) => {
    event.preventDefault();
    if (!selected) {
      setHelperText("* Please Select Cart Option");
      setError(true);
    } else if (paymentSuccessStatus === true) {
      navigate("/college-list", {
        replace: navigate("/"),
        state: {
          paymentSuccessStatus: true,
          orderType: selected.id
        }
      });
    } else {
      newOrder({
        amount: totalPrice,
        billing_name: name,
        redirect_url: redirectUrl,
      })
        .then((response) => {
          if (!response.data.error) {
            const id = response.data.result.order.id;
            const order_id = response.data.result.order.order_id;
            const tid = response.data.result.order.tid;
            document.getElementById("order_id").value = order_id;
            document.getElementById("tid").value = tid;

            // Do not modify "Your Order Id is ". If you do, then please modify in the Success and Failure files as well for getting the paymentID.
            document.getElementById("merchant_param1").value = "Your Order Id is " + id;
            document.getElementById("merchant_param3").value = selected.id;
            document.getElementById("merchant_param4").value = studentRankStore;

            document.getElementById("merchant_param2").value =
              process.env.NODE_ENV === "development" ? "localhost" : "Live";
            
            
            if (/^(8750001881|9212234090|9910375900|9811937572)$/.test(mobile)) {
              navigate("/college-list", {
                replace: navigate("/"),
                state: {
                  paymentSuccessStatus: true,
                  orderType: selected.id
                }
              });
            } else {
              document.forms["rank-form"].submit();
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  
  
  return (
    <Grid
      container
      sx={{
        margin: {
          lg: "180px 0",
          md: "180px 0",
          sm: "20px 0",
          xs: "20px 0",
        },
      }}
    >
      <Grid item xs={12} md={12}>
        <Box>
          <form
            name="rank-form"
            method="POST"
            action={`https://neetnavigator.com/CCAvenuePayment/ccavRequestHandler.php`}
          >
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan={3}>Cart Summary</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>
                      <Radio
                        checked={selected?.id === 999}
                        onChange={() =>
                          handleChange({
                            id: 999,
                            price: 999,
                            attempt: 1,
                            state: 36,
                          })
                        }
                        value={999}
                        id={`check-box-${999}`}
                        name={`radio-buttons-${999}`}
                        inputProps={{ "aria-label": 999 }}
                        disabled={false}
                      />
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row">
                      {`All States`}<span style={{fontSize: 16, color: "red"}}>**</span>
                    </StyledTableCell>
                    <StyledTableCell>₹ 999</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <StyledTableCell colSpan={2}>SubTotal</StyledTableCell>
                    <StyledTableCell>₹ {packagePrice}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell colSpan={2}>GST *</StyledTableCell>
                    <StyledTableCell>₹ {gstPrice}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell colSpan={2}>Order Total</StyledTableCell>
                    <StyledTableCell>₹ {totalPrice}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell>
                      <Typography
                        variant="p"
                        component="p"
                        sx={{ fontSize: "16px", color: "#c45500" }}
                      >
                        Have Promo Code ?
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell colSpan={2}>
                      <Stack direction="row">
                        <TextField
                          label="Promo Code"
                          fullWidth
                          variant="outlined"
                          id="promo_code"
                          size="small"
                          name="promo_code"
                          value={promoCode}
                          onChange={(e) => setPromoCode(e.target.value)}
                          InputProps={{
                            readOnly: false,
                          }}
                        />
                      </Stack>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell colSpan={3}>
                      <Stack md={12} sm={12} lg={12} xs={12}>
                        {error && (
                          <p className="term-condition-error-msg">
                            {helperText}
                          </p>
                        )}
                        <Button
                          type="submit"
                          className="search-btn"
                          onClick={handleCheckOutFormRank}
                          sx={{
                            fontSize: "25px",
                            padding: "22px",
                            border: "1px solid #1366ce",
                            outline: "none",
                            cursor: "pointer",
                            mt: 2,
                            mb: 4,
                            color: "#fff",
                            backgroundColor: "#1366ce",
                            height: "50px",
                          }}
                        >
                          Checkout
                        </Button>
                        <span style={{ fontSize: 12, color: "black" }}>
                          * MBBS Lighthouse plays no role in taxes and charges levied by the government.
                        </span>
                        <br/>
                        {/* <span style={{ fontSize: 16, color: "red" }}>
                          ** Fare usage policy applied. The report has been truncated to have top 50 colleges from all states in India based on the entered marks. If you have any query, kindly contact us at +91 99103 75900.
                        </span> */}
                      </Stack>
                    </StyledTableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <input type="hidden" name="tid" id="tid" readOnly />
            <input type="hidden" name="merchant_id" value={2342262} readOnly />
            <input type="hidden" name="order_id" id="order_id" readOnly />
            <input type="hidden" name="amount" value={totalPrice} readOnly />
            <input type="hidden" name="currency" value="INR" readOnly />
            <input
              type="hidden"
              name="redirect_url"
              value={`https://neetnavigator.com/CCAvenuePayment/ccavResponseHandler.php`}
              readOnly
            />
            <input
              type="hidden"
              name="cancel_url"
              value="https://neetnavigator.com/CCAvenuePayment/ccavResponseHandler.php"
              readOnly
            />
            <input type="hidden" name="language" value="EN" readOnly />
            <input type="hidden" name="billing_name" value={name} readOnly />
            <input
              type="hidden"
              name="billing_address"
              value={confirmAddress}
              readOnly
            />
            <input
              type="hidden"
              name="billing_city"
              value={city.name}
              readOnly
            />
            <input
              type="hidden"
              name="billing_state"
              value={state.name}
              readOnly
            />
            <input type="hidden" name="billing_zip" value={pincode} readOnly />
            <input
              type="hidden"
              name="billing_country"
              value="India"
              readOnly
            />
            <input type="hidden" name="billing_tel" value={mobile} readOnly />
            <input type="hidden" name="billing_email" value={email} readOnly />
            <input
              type="hidden"
              name="merchant_param1"
              id="merchant_param1"
              readOnly
            />
            <input
              type="hidden"
              name="merchant_param2"
              id="merchant_param2"
              readOnly
            />
            <input
              type="hidden"
              name="merchant_param3"
              id="merchant_param3"
              readOnly
            />
            <input
              type="hidden"
              name="merchant_param4"
              id="merchant_param4"
              readOnly
            />

            <input type="hidden" name="promo_code" value={promoCode} />

            <input
              type="hidden"
              name="integration_type"
              value="iframe_normal"
              readOnly
            />
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CheckoutFormRank;