import React, { useState, useEffect } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import { Grid, Typography } from "@mui/material";

function UserReview() {
    const data = [
      {
        id: 1,
        image: "https://neetnavigator.com/img/students/shruti%20jain.jpg",
        name: "Shruti Jain ",
        title: "Student",
        quote: "MBBS LightHouse by NEET Navigator give me my dream college.",
      },
      {
        id: 2,
        image: "https://neetnavigator.com/img/students/tanya.jpg",
        name: "Tanya",
        title: "Student",
        quote:
          "I tried so may ways to get Admission in Neet College, MBBS LightHouse by NEET Navigator.",
      },
      {
        id: 3,
        image: "https://neetnavigator.com/img/students/diksha%20jain.jpg",
        name: "Diksha Jain",
        title: "Student",
        quote:
          "MBBS LightHouse by NEET Navigator help me get Admission in my Dream College",
      },
      {
        id: 4,
        image: "https://neetnavigator.com/img/students/vasudha.jpg",
        name: "Vasudha",
        title: "Student",
        quote:
          "MBBS LightHouse by NEET Navigator is best ways to get Admission in Neet College",
      },
    ];

  const [people, setPeople] = useState(data);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const lastIndex = people.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, people]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 3000);
    return () => clearInterval(slider);
  }, [index]);
  return (
    <section
      className="section"
      style={{
        fontFamily: ["Poppins", "sans-serif"].join(","),
      }}
    >
      <Grid item xs={12} md={12} textAlign="center">
        <Typography
          variant="h3"
          component="h2"
          m="15px 0px 25px 15px"
          sx={{
            fontSize: {
              lg: "30px",
              md: "30px",
              sm: "22px",
              xs: "22px",
            },
            color: "#1e439b",
            lineHeight: 1.5,
            display: "inline-block",
            borderBottom: "3px solid #ff5501",
          }}
        >
          Reviews
        </Typography>
      </Grid>

      <div className="section-center">
        {people.map((person, personIndex) => {
          const { id, image, name, title, quote } = person;

          let position = "nextSlide";
          if (personIndex === index) {
            position = "activeSlide";
          }
          if (
            personIndex === index - 1 ||
            (index === 0 && personIndex === people.length - 1)
          ) {
            position = "lastSlide";
          }
          return (
            <article className={position} key={id}>
              <img src={image} alt={name} className="person-img" />
              <h4>{name}</h4>
              <p className="title">{title}</p>
              <p className="text">{quote}</p>
              <FormatQuoteIcon className="icon" />
            </article>
          );
        })}
        <button className="prev" onClick={() => setIndex(index - 1)}>
          <ChevronLeftIcon />
        </button>
        <button className="next" onClick={() => setIndex(index + 1)}>
          <ChevronRightIcon />
        </button>
      </div>
    </section>
  );
}

export default UserReview;
