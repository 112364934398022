import avatar from "../../assets/avatar.svg";
import student1 from "../../assets/taniya.jpg";
import student2 from "../../assets/vasudha.jpg";
import student3 from "../../assets/palak.jpg";
import Carousel from "react-material-ui-carousel";
import "../../App.css";
import YtVideo from "./YtVideo";

const Card1 = () => {
    return (

        <div className="p-4 bg-white rounded-lg w-[600px] h-172 drop-shadow-md">
            <div className="flex items-center justify-center mb-4 ">
                <div className="flex flex-col items-center justify-center">
                    <img src={student1} alt={"name"} className="w-20 h-20 rounded-full"/>
                    <div className="ml-2 text-center">
                        <p className="text-base font-semibold">Taniya</p>
                        <p className="text-sm text-gray-500">MAMC Agroha</p>
                    </div>
                </div>
            </div>
            <div className="overflow-hidden pr-10 pl-10 pb-8">
                <p className="text-lg text-gray-700 text-center">
                Taniya had spent three long years preparing for her MBBS admission. Her family was desperate for her to secure a MBBS seat this year, but they had resigned themselves to the fact that they may have to settle for admission in a private medical college. <a className="text-[#1366CF]" target="_blank" href={'https://neetnavigator.com/testimonials/tanya'}>Know more.</a>
                </p>
            </div>
        </div>

    )
}
const Card2 = () => {
    return (
        <div className="p-4 bg-white rounded-lg w-[600px] h-172 drop-shadow-md">
            <div className="flex items-center justify-center mb-4 ">
                <div className="flex flex-col items-center">
                    <img src={student2} alt={"name"} className="w-20 h-20 rounded-full"/>
                    <div className="ml-2 text-center">
                        <p className="text-base font-semibold">Vasudha</p>
                        <p className="text-sm text-gray-500">Sharda University, Noida</p>
                    </div>
                </div>
            </div>
            <div className="overflow-hidden pr-10 pl-10 pb-8">
                <p className="text-lg text-gray-700 text-center">
                The journey of Vasudha is a story of confusion, despair, and ultimately, triumph. She had a clear goal in mind - to study BDS from Sharda University, Noida - and had hoped to manage the medical admission counselling process on her own. <a className="text-[#1366CF]" target="_blank" href={'https://neetnavigator.com/testimonials/vasudha'}>Know more.</a>
                </p>
            </div>
        </div>
    )
}
const Card3 = () => {
    return (
        <div className="p-4 bg-white rounded-lg w-[600px] h-172 drop-shadow-md">
            <div className="flex items-center justify-center mb-4 ">
                <div className="flex flex-col items-center">
                    <img src={student3} alt={"name"} className="w-20 h-20 rounded-full"/>
                    <div className="ml-2 text-center">
                        <p className="text-base font-semibold">Palak Rathi</p>
                        <p className="text-sm text-gray-500">BPSMCW, Sonepat</p>
                    </div>
                </div>
            </div>
            <div className="overflow-hidden pr-10 pl-10 pb-8">
                <p className="text-lg text-gray-700 text-center">
                Palak Rathi had a dream of becoming a doctor, but she was disappointed when she saw her NEET 2022 score card. Her All India Rank was 18109, and she knew that at that rank, there was no hope of admission in a good government medical college anywhere in North India. <a className="text-[#1366CF]" target="_blank" href={'https://neetnavigator.com/testimonials/palak-rathi'}>Know more.</a>
                </p>
            </div>
        </div>
    )
}

export default function Testimonial() {
    return (
        <div className="flex flex-col items-center justify-center pb-5 bg-[#eaf1ff] p-6 lg:p-16">
        <h2 className="text-xl lg:text-4xl md:text-2xl font-semibold my-8 ml-4">Your Success, <span className="text-[#1366CF]">Our Priority</span></h2>
            <Carousel navButtonsAlwaysVisible="true" className="custom-carousel">
                <Card1/>
                <Card2/>
                <Card3/>
            </Carousel>
        </div>
    );
}