import React from "react";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import moment from "moment/moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import image from "../../pgBannerBackground.png"; 
import ProgressBar from "../ProgressBar/ProgressBar";


const StudentData = ({
  studentDetail,
  userDetail,
  location,
  showEdit,
}) => {
  const { name, email, mobile } = userDetail;
  const {
    area,
    quota,
    minority,
    armed_force,
    dob,
    gender,
    year12th,
    neetYear,
    isPhCandidate,
    address,
    neet_category,
    state_category,
    state10th,
    state12th,
  } = studentDetail;

  const format_dob = dob ? moment(dob).format("DD/MM/YYYY") : "NA";
  const format_year12th = year12th ? moment(year12th).format("YYYY") : "NA";
  const format_neetYear = neetYear ? moment(neetYear).format("YYYY") : "NA";
  const isPhCandidateData = isPhCandidate === true ? "Yes" : "No";
  const areaValue = area === null ? "NA" : area.area;
  const quotaValue = quota === null ? "No" : quota.quota;
  const minorityValue = minority === null ? "No" : minority.minority;
  const armedForceValue = armed_force === null ? "No" : armed_force.armedForce;

  const themeStudentData = createTheme();

  themeStudentData.typography.h3 = {
    fontSize: "16px",
    "@media (max-width:600px)": {
      fontSize: "16px",
    },

    fontFamily: ["Poppins", "sans-serif"].join(","),
  };
  themeStudentData.typography.p = {
    fontSize: "16px",
    "@media (max-width:600px)": {
      fontSize: "16px",
    },
    fontFamily: ["Poppins", "sans-serif"].join(","),
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={themeStudentData}>
        <Container
          maxWidth="xl"
          sx={{ padding: `16px`, backgroundImage: `url(${image})` }}
        >
          <ProgressBar/>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h3"
              component="h3"
              color="#1366ce"
              align="center"
              mb={1}
              sx={{
                fontSize: { lg: "30px", md: "30px", xs: "20px" },
                display: "inline-block",
                borderBottom: "3px solid #ff5501",
              }}
            >
              PROFILE
            </Typography>
          </Stack>
          <Card
            sx={{
              borderBottom: "2px solid #1366ce",
              borderRight: "2px solid #1366ce",
              padding: {
                lg: "0px 20px",
                md: "0px 20px",
                xs: "0px 20px",
              },
            }}
          >
            <Grid container spacing={2} mt="1px" mb={1}>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="h3" component="h3">
                  Name
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="p" component="p">
                  {name}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography
                  variant="h3"
                  component="h3"
                >
                  Email
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important",wordWrap: "break-word" }}
              >
                <Typography variant="p" component="p">
                  {email}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="h3" component="h3">
                  Gender
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="p" component="p">
                  {gender}
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="h3" component="h3">
                  Mobile
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="p" component="p">
                  {mobile}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="h3" component="h3">
                  Date of Birth
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="p" component="p">
                  {format_dob}
                </Typography>
              </Grid>
            </Grid>
          </Card>

          <Card
            sx={{
              margin: "10px 0px",
              borderBottom: "2px solid #1366ce",
              borderRight: "2px solid #1366ce",
              padding: {
                lg: "0px 20px",
                md: "0px 20px",
                xs: "0px 20px",
              },
            }}
          >
            <Grid container spacing={2} mt="1px" mb={1}>
              {/** address */}
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="h3" component="h3">
                  Address
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="p" component="p">
                  {address.address}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="h3" component="h3">
                  Pin Code
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="p" component="p">
                  {address.pincode}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="h3" component="h3">
                  12th Passing state
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="p" component="p">
                  {state12th.name}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="h3" component="h3">
                  10th Passing state
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="p" component="p">
                  {state10th.name}
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="h3" component="h3">
                  Area
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="p" component="p">
                  {areaValue}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="h3" component="h3">
                  City
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="p" component="p">
                  {address.city.name}
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="h3" component="h3">
                  State
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="p" component="p">
                  {address.state.name}
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Typography
            variant="h4"
            component="h3"
            color="#1366ce"
            gutterBottom
            sx={{
              fontSize: { lg: "25px", md: "25px", xs: "21px" },
              display: "inline-block",
              borderBottom: "3px solid #ff5501",
            }}
          >
            NEET Details
          </Typography>

          <Card
            sx={{
              margin: "10px 0px",
              borderBottom: "2px solid #1366ce",
              borderRight: "2px solid #1366ce",
              padding: {
                lg: "0px 20px",
                md: "0px 20px",
                xs: "0px 20px",
              },
            }}
          >
            <Grid container spacing={2} mt="1px" mb={1}>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="h3" component="h3">
                  12th Passing year
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="p" component="p">
                  {format_year12th}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="h3" component="h3">
                  State Category
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="p" component="p">
                  {state_category.category}
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="h3" component="h3">
                  NEET YEAR - in which student want top qualify
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="p" component="p">
                  {format_neetYear}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="h3" component="h3">
                  NEET Category
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="p" component="p">
                  {neet_category.category}
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Typography
            variant="h4"
            component="h3"
            color="#1366ce"
            gutterBottom
            sx={{
              fontSize: { lg: "25px", md: "25px", xs: "21px" },
              display: "inline-block",
              borderBottom: "3px solid #ff5501",
            }}
          >
            Other Details
          </Typography>

          <Card
            sx={{
              margin: "10px 0px",
              borderBottom: "2px solid #1366ce",
              borderRight: "2px solid #1366ce",
              padding: {
                lg: "0px 20px",
                md: "0px 20px",
                xs: "0px 20px",
              },
            }}
          >
            <Grid container spacing={2} mt="1px" mb={1}>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="h3" component="h3">
                  Whether Physically Handicap Candidate?
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="p" component="p">
                  {isPhCandidateData}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="h3" component="h3">
                  Whether belongs to minority?
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="p" component="p">
                  {minorityValue}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="h3" component="h3">
                  Whether belongs to armed forces?
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="p" component="p">
                  {armedForceValue}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="h3" component="h3">
                  Whether Eligible for ESIC quota seats?
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                sx={{ paddingTop: "8px !important" }}
              >
                <Typography variant="p" component="p">
                  {quotaValue}
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Grid
            display={"flex"}
            textAlign={"center"}
            justifyContent={"space-around"}
            mb={1}
          >
            {location && (
              <Link
                style={{ color: "#1f75cb", textDecoration: "none" }}
                to={"/student-services"}
                state={{ marks: location.marks, rank: location.rank }}
              >
                <Button variant="contained">Confirm</Button>
              </Link>
            )}
            {showEdit && (
              <Link
                style={{ color: "#1f75cb", textDecoration: "none" }}
                to={"/domicile-edit"}
              >
                <Button variant="contained">Edit</Button>
              </Link>
            )}
          </Grid>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default StudentData;
