import logo from "../assets/logo-round.png";
import React from "react";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useState } from "react";
import { validatePhone } from "../components/Regex";
import TermConditionModal from "../components/TermConditionModal";
import { Stack } from "@mui/system";
import { sendOTP, verifyOTP, verifyPassword } from "../services/auth";
import {
  MobileInfo,
  OtpInfo,
  PasswordInfo,
  UsernameInfo,
} from "../components/PopOverInfo/RegistrationInfo";
import { setAccessToken, setStudentId, setUserId } from "../services/serviceToken";
import { setToken } from "../redux/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { findUser } from "../services/user";
import { useEffect } from "react";
import { getCollegeCount } from "../services/colleges";
import HeroBanner from "../components/home/HeroBanner";
import HeroBannerSlider from "../components/home/HeroBannerSlider";
import ExpandYourHorizon from "../components/home/ExpandYourHorizon";
import heroPattern from "../pgBannerBackground.png";
import WhatMBBSLightHouseDo from "../components/home/WhatMBBSLightHouseDo";
import BenefitsOfMBBSLightHouse from "../components/home/BenefitsOfMBBSLightHouse";
import ServicesPackage from "../components/home/ServicesPackage";
import ServicesList from "../components/home/ServicesList";
import Testimonial from "../components/home/Testimonial";
import FAQ from "../components/home/FAQ";
import BenefitsOfMBBSCounseling from "../components/home/BenefitsOfMBBSCounseling";
import WhatMBBSCounseling from "../components/home/WhatMBBSCounseling";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import ProgressBar from "../components/ProgressBar/ProgressBar";

const Login = (props) => {
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [errorMobile, setErrorMobile] = useState(false);
  const [errorOTP, setErrorOTP] = useState(false);
  const [errorUsername, setErrorUsername] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorHelperTextMobile, setErrorHelperTextMobile] = useState("");
  const [errorHelperTextOTP, setErrorHelperTextOTP] = useState("");
  const [errorHelperTextUsername, setErrorHelperTextUsername] = useState("");
  const [checked, setChecked] = useState(false);
  const [checkBoxErr, setCheckBoxErr] = useState(false);
  const [checkBoxErrText, setCheckBoxErrText] = useState("");
  const [collegeDataCount, setCollegeDataCount] = useState(0);

  const [toggle, setToggle] = useState(true);


  // const [otp, setOtp] = useState("");
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const navigate = useNavigate();

  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);
    if (!checked) {
      // handleTcModalOpen();
      setCheckBoxErr(false);
      setCheckBoxErrText("");
    }
  };

  const [tcModalOpen, setTcModalOpen] = useState(false);
  const handleTcModalOpen = () => setTcModalOpen(true);

  const handleSendOTP = async (event) => {
    event.preventDefault();
    if (!mobile) {
      setErrorMobile(true);
      setErrorHelperTextMobile("Mobile Number is required");
    } else if (!validatePhone.test(mobile)) {
      setErrorMobile(true);
      setErrorHelperTextMobile("Mobile Number should be of 10 digit");
    } else if (!checked) {
      setCheckBoxErr(true);
      setCheckBoxErrText("Please Accept Terms & Conditions");
    } else {
      setErrorMobile(false);
      setErrorHelperTextMobile("");
      sendOTP({ mobile: mobile })
        .then((response) => {
          if (!response.data.error) {
            setIsMobile(true);
            setMinutes(1);
            setSeconds(30);
          } else {
            setErrorMobile(true);
             if (response.data.message === "User does not exist") {
               setErrorHelperTextMobile("User does not exist");
             }
          }
        })
        .catch((err) => {
          setErrorMobile(true);
          setErrorHelperTextMobile(err.errors.message);
        });
    }
  };

  const verifySendOTP = async (event) => {
    event.preventDefault();
    if (!otp) {
      setErrorOTP(true);
      setErrorHelperTextOTP("OTP is required");
    } else if (otp.length !== 6) {
      setErrorOTP(true);
      setErrorHelperTextOTP("OTP should be of 6 digit");
    } else {
      setErrorOTP(false);
      setErrorHelperTextOTP("");
      verifyOTP({ mobile: mobile, otp: otp })
        .then((response) => {
          if (!response.data.error) {
            const token = response.data.result.token;
            const id = response.data.result.id;
            setAccessToken(token);
            setUserId(id);
            dispatch(setToken(token));
            findUser(id)
              .then((response) => {
                if (!response.data.error) {
                  const studentId = response.data.result.student.id;
                  if (studentId) {
                    setStudentId(studentId);
                    navigate("/", {
                      replace: true
                    });
                  }
                } else {
                  console.error("error");
                }
              })
              .catch((err) => {
                console.error("error", err);
              });

          } else {
            setErrorOTP(true);
            setErrorHelperTextOTP(response.data.message);
          }
        })
        .catch((err) => {
          // errorMessage(err.errors.message);
          setErrorOTP(true);
          setErrorHelperTextOTP(err.errors.message);
        });
    }
  };

  const VerifyPassword = async (event) => {
    event.preventDefault();
    if (!username) {
      setErrorUsername(true);
      setErrorHelperTextUsername("Username is required");
    } else if (username.length < 2) {
      setErrorUsername(true);
      setErrorHelperTextUsername("Username should be greater than 2 alphabet");
    } else if (!password) {
      setErrorPassword(true);

    } else if (password.length < 6) {
      setErrorPassword(true);
    } else if (!checked) {
      setCheckBoxErr(true);
      setCheckBoxErrText("Please Accept Terms & Conditions");
    } else {
      setErrorUsername(false);
      setErrorHelperTextUsername("");
      setErrorPassword(false);
      verifyPassword({ username, password })
        .then((response) => {
          if (!response.data.error) {
            const token = response.data.result.token;
            const id = response.data.result.id;
            // successMessage("User Login Successfully ");
            setAccessToken(token);
            setUserId(id);
            dispatch(setToken(token));
            findUser(id)
              .then((response) => {
                if (!response.data.error) {
                  const studentId = response.data.result.student.id;
                  if (studentId) {
                    setStudentId(studentId);
                     navigate("/", {
                       replace: true,
                     });
                  }
                } else {
                  console.error("error");
                }
              })
              .catch((err) => {
                console.error("error", err);
              });
          } else {
            console.log("err ", response.data.message);
            setErrorUsername(true);
            setErrorHelperTextUsername("Username is not correct");
            setErrorPassword(true);
          }
        })
        .catch((err) => {
          console.log("err ", err.errors.message);
          setErrorUsername(true);
          setErrorHelperTextUsername("Username is not correct");
          setErrorPassword(true);
        });
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [ytModalOpen, setYtModalOpen] = useState(false);
  useEffect(() => {
     window.scrollTo(0, 0);
    async function CollegeCount() {
      getCollegeCount()
        .then((response) => {
          if (!response.data.error) {
            setCollegeDataCount(response.data.result);
          }
        })
        .catch((err) => {
          console.error("error", err);
        });
    }
    CollegeCount();
    setYtModalOpen(true);
  }, []);

  return (
    <>
      {/*ytModalOpen && (
        <YoutubeVideoModal
          ytModalOpen={ytModalOpen}
          setYtModalOpen={setYtModalOpen}
        />
      )*/}
      {/* <ProgressBar/> */}
      <div style={{backgroundImage : `url(${heroPattern}`}}>
      <HeroBanner collegeDataCount={collegeDataCount}/>
      <div className="w-[70%] m-auto">
        <HeroBannerSlider/>
      </div>
      </div>
      <div id="overview">
        <WhatMBBSCounseling />
      </div>
      <BenefitsOfMBBSCounseling />
      <ServicesList />
      <WhatMBBSLightHouseDo/>
      <BenefitsOfMBBSLightHouse />
      <div id="service">
      <ServicesPackage />
      </div>
      <ServicesList />

      <div id="signup" className="bg-[#eaf1ff] flex flex-col items-center pb-10 pt-10">
      <Container maxWidth="xl">
        <Grid
          container
          spacing={3}
          sx={{flexGrow: 1 }}
        >
          <Grid
            item
            xs={12}
            md={5}

          >
            <Stack
              sx={{
                padding: {
                  lg: "0px 30px 10px 20px",
                  md: "0px 30px 10px 20px",
                  xs: "0px 30px 10px 20px",
                },
              }}
            >
              <ExpandYourHorizon />
            </Stack>
          </Grid>
          <Grid item xs={12} md={7}>
            <Stack
              sx={{
                backgroundColor: '#ffffff',
                padding: {
                  lg: "15px 100px",
                  md: "15px 100px",
                  xs: "10px 10px",
                },
                borderRadius: '20px',
              }}
            >
              <Stack
                className="college-form-heading"
                display="inline-block"
                spacing={2}
                mt={2}
                mb={2}
              >
                <Typography
                  variant="h3"
                  component="h2"
                  sx={{
                    fontSize: { md: "30px", xs: "20px" },
                    color: "#1e439b",
                    lineHeight: 1.5,
                    display: "inline-block",
                    borderBottom: "3px solid #ff5501",
                  }}
                >
                  Student Login
                </Typography>
              </Stack>
              {toggle && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  component="form"
                  onSubmit={!isMobile ? handleSendOTP : verifySendOTP}
                  noValidate
                  mt={1}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Stack direction="row-reverse">
                        <Box sx={{width: "5%", display: "flex", alignItems: "center"}}> <MobileInfo /></Box>
                        <Box sx={{width: "95%"}}>
                          <TextField
                          label="Mobile Number"
                          fullWidth
                          variant="outlined"
                          id="mobileNumber"
                          name="mobileNumber"
                          autoFocus
                          error={errorMobile}
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                          helperText={errorHelperTextMobile}
                          InputProps={{
                            readOnly: false,
                          }}
                        /></Box>
                      </Stack>
                      {!isMobile && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checked}
                              onChange={handleChangeCheck}
                              color="success"
                            />
                          }
                          className={
                            checkBoxErr && checkBoxErr
                              ? "term-condition"
                              : "term-condition-accept"
                          }
                          label="Agree to Terms & Conditions"
                        />
                      )}
                      {checkBoxErr && (
                        <p className="term-condition-error-msg">
                          {checkBoxErrText}
                        </p>
                      )}
                    </Grid>
                    {isMobile && (
                      <Grid item xs={12} md={12}>
                        <Stack direction="row-reverse">
                          <Box sx={{display: "flex", width: "5%", alignItems: "center"}}><OtpInfo /></Box>
                          <Box sx={{width: "95%"}}>
                          <TextField
                            label="OTP"
                            required
                            fullWidth
                            variant="outlined"
                            id="otp"
                            name="otp"
                            autoFocus
                            error={errorOTP}
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            helperText={errorHelperTextOTP}
                          />
                          </Box>
                        </Stack>
                        <Box sx={{width: "95%"}}>
                        <Stack
                          direction="row"
                          justifyContent={"space-between"}
                          mt={2}
                        >
                          {seconds > 0 || minutes > 0 ? (
                            <Typography
                              variant="p"
                              color="inherit"
                              sx={{
                                textAlign: "center",
                                fontSize: {
                                  lg: "16px",
                                  md: "16px",
                                  xs: "12px",
                                },
                              }}
                              pt={1}
                              pb={1}
                            >
                              Time Remaining:{" "}
                              {minutes < 10 ? `0${minutes}` : minutes}:
                              {seconds < 10 ? `0${seconds}` : seconds}
                            </Typography>
                          ) : (
                            <Typography
                              variant="p"
                              color="inherit"
                              sx={{
                                textAlign: "center",
                                fontSize: {
                                  lg: "16px",
                                  md: "16px",
                                  xs: "12px",
                                },
                              }}
                              pt={1}
                              pb={1}
                            >
                              Didn't Get OTP?
                            </Typography>
                          )}

                          <Button
                            disabled={seconds > 0 || minutes > 0}
                            className="search-btn"
                            sx={{
                              fontSize: { lg: "16px", md: "16px", xs: "12px" },
                              border: "1px solid #1366ce",
                              outline: "none",
                              cursor: "pointer",

                              color: "#fff",
                              backgroundColor: "#1366ce",
                            }}
                            onClick={handleSendOTP}
                          >
                            Resend OTP
                          </Button>
                        </Stack>
                        </Box>
                        <Box sx={{width: "5%"}}></Box>
                      </Grid>
                    )}
                    <Grid item md={12} sm={12} lg={12} xs={12}>
                      <Stack direction="row" md={12} sm={12} lg={12} xs={12}>
                        <Box sx={{width: "95%"}}>
                        <Button
                          type="submit"
                          className="search-btn"
                          size={"small"}
                          fullWidth
                          sx={{
                            fontSize: { lg: "25px", md: "25px", xs: "16px" },
                            border: "1px solid #1366ce",
                            outline: "none",
                            cursor: "pointer",
                            mt: 1,
                            mb: 1,
                            color: "#fff",
                            backgroundColor: "#1366ce",
                          }}
                        >
                          {!isMobile ? "Get OTP" : "Submit OTP"}
                        </Button>
                        </Box>
                        <Box sx={{width: "5%"}}></Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {!toggle && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  component="form"
                  onSubmit={VerifyPassword}
                  noValidate
                  mt={1}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Stack direction="row-reverse">
                        <Box sx={{display: "flex", alignItems: "center", width: "5%"}}><UsernameInfo /></Box>
                        <Box sx={{width: "95%"}}>
                        <TextField
                          label="Mobile Number/ Email Id"
                          fullWidth
                          variant="outlined"
                          id="username"
                          name="username"
                          autoFocus
                          error={errorUsername}
                          helperText={errorHelperTextUsername}
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          InputProps={{
                            readOnly: false,
                          }}
                        />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Stack direction="row-reverse">
                        <Box sx = {{display: "flex", alignItems: "center", width: "5%"}}><PasswordInfo /></Box>
                        <Box sx={{width: "95%"}}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          autoComplete="given-name"
                          required
                          name="password"
                          error={errorPassword}
                          onChange={(e) => setPassword(e.target.value)}
                        >
                          <InputLabel htmlFor="password">Password</InputLabel>
                          <OutlinedInput
                            name="password"
                            id="password"
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                          />
                        </FormControl>
                        </Box>
                      </Stack>
                      {/*<Stack direction="row-reverse">
                        <PasswordInfo />
                        <TextField
                          label="Password"
                          fullWidth
                          variant="outlined"
                          id="password"
                          name="password"
                          error={errorPassword}
                          helperText={errorHelperTextPassword}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          InputProps={{
                            readOnly: false,
                          }}
                        />
                        </Stack>*/}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked}
                            onChange={handleChangeCheck}
                            color="success"
                          />
                        }
                        className={
                          checkBoxErr && checkBoxErr
                            ? "term-condition"
                            : "term-condition-accept"
                        }
                        label="Agree to Terms & Conditions"
                      />
                      {checkBoxErr && (
                        <p className="term-condition-error-msg">
                          {checkBoxErrText}
                        </p>
                      )}
                    </Grid>
                    <Grid item md={12} sm={12} lg={12} xs={12}>
                      <Stack md={12} sm={12} lg={12} xs={12}>
                        <Box sx={{width: "95%"}}>
                        <Button
                          type="submit"
                          className="search-btn"
                          size={"small"}
                          fullWidth
                          sx={{
                            fontSize: { lg: "25px", md: "25px", xs: "16px" },
                            border: "1px solid #1366ce",
                            outline: "none",
                            cursor: "pointer",
                            mt: 1,
                            mb: 1,
                            color: "#fff",
                            backgroundColor: "#1366ce",
                          }}
                        >
                          {"Submit"}
                        </Button>
                        </Box>
                        <Box sx={{width: "5%"}}></Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              )}
              <Box sx={{display: "flex", flexDirection: "column", width: "95%"}}>
              <Grid container justifyContent="flex-end">
                <Grid
                  item
                  sx={{
                    color: "black",
                    fontFamily: ["Poppins", "sans-serif"].join(","),
                  }}
                >
                  Don't have an account?{" "}
                  <Link
                    to={"/register"}
                    style={{ color: "#1f75cb", textDecoration: "none" }}
                  >
                    Register Now
                  </Link>
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Grid
                  item
                  sx={{
                    fontFamily: ["Poppins", "sans-serif"].join(","),
                  }}
                >
                  <Link
                    onClick={() => setToggle(!toggle)}
                    style={{
                      color: "#1f75cb",
                      textDecoration: "none",
                    }}
                  >
                    {!toggle ? "Login with OTP" : "Login with Password"}
                  </Link>
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Grid
                  item
                  sx={{
                    fontFamily: ["Poppins", "sans-serif"].join(","),
                    display: !toggle ? "flex" : "none"
                  }}
                >
                  <Link
                    to={"/password/reset"}
                    style={{
                      color: "#1f75cb",
                      textDecoration: "none",
                    }}
                  >
                    Forgot Password
                    {/* {!toggle ? "Forgot Password" : "" } */}
                  </Link>
                </Grid>
              </Grid>
              </Box>
              <Box sx={{width: "5%"}}></Box>
            </Stack>
          </Grid>
        </Grid>
        <TermConditionModal
          tcModalOpen={tcModalOpen}
          setTcModalOpen={setTcModalOpen}
        />
      </Container>
      </div>

      <Testimonial />
      <FAQ />
      <FloatingWhatsApp
          phoneNumber="+91 99103 75900"
          accountName="MBBS Lighthouse"
          avatar={logo}
          statusMessage="online" 
          chatMessage="Hi, Welcome to MBBS Lighthouse. How can we help you?"
          allowClickAway="true"
          allowEsc="true" />
    </>
  );
};

export default Login;
