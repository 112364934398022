import axios from "axios";

// export const findPincode = (pincode) => {
//   return axios({
//     method: "get",
//     url: "https://api.postalpincode.in/pincode/" + pincode,
//   });
// };

 export const findPincode = (pincode) => {
   return fetch("https://api.postalpincode.in/pincode/" + pincode)
 };