import React, { useEffect, useState } from "react";
import { findUser } from "../services/user";
import { getUserId } from "../services/serviceToken";
import { findStudent } from "../services/student";
import { useLocation } from "react-router-dom";
import { getPaymentDetail } from "../services/paymentOrder";
import StudentOrderData from "../components/StudentData/StudentOrdersData";
import { getAllUserFiles } from "../services/userFile";

export default function StudentOrders() {
  const location = useLocation().state;
  const [userDetail, setUserDetail] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const [studentDetail, setStudentDetail] = useState({
    gender: "",
    stateId12th: "",
    stateId10th: "",
    isPhCandidate: "",
    area: { area: "" },
    quota: { quota: "" },
    state10th: { name: "" },
    state12th: { name: "" },
    minority: { minority: "" },
    armed_force: { armedForce: "" },
    neet_category: { category: "" },
    state_category: { category: "" },
    address: {
      address: "",
      pincode: "",
      state: { name: "" },
      city: { name: "" },
    },
  });
   
  const [paymentStatusData, setPaymentStatusData] = useState([]);
  const [studentPdfReport, setStudentPdfReport] = useState([]);

  const userId = getUserId();
  useEffect(() => {
     window.scrollTo(0, 0);
    async function getUserData() {
      findUser(userId)
        .then((response) => {
          setUserDetail(response.data.result);
          if (response.data.result.error !== false) {
             const studentId = response.data.result.student.id;
            findStudent(studentId)
            .then((response) => {
              setStudentDetail(response.data.result);
              if(response.data.result.error !== false) {
                getPaymentDetail()
                  .then((response) => {
                    setPaymentStatusData(response.data.result);
                    if (response.data.result.error !== false) {
                      getAllUserFiles()
                        .then((response) => {
                          setStudentPdfReport(response.data.result);
                        })
                        .catch((err) => {
                          console.error("error", err);
                        });
                    }
                  })
                  .catch((err) => {
                    console.error("error", err);
                  });
              }
            })
            .catch((err) => {
              console.error("error", err);
            });
          }
        })
        .catch((err) => {
          console.error("error", err);
        });
    }
    getUserData();
  }, []);

  const successPaymentStatus = paymentStatusData.filter(
    (c) => c.tracking_id !== null
  );

  return (
    <StudentOrderData
      studentDetail={studentDetail}
      userDetail={userDetail}
      location={location}
      successPaymentStatus={successPaymentStatus}
    />
  );
}
