export const IS_DEV_ENV = process.env.NODE_ENV === "development";
export const BASE_URL = IS_DEV_ENV
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_PROD_URL;

// Google tag constants
export const MEASUREMENT_ID = "AW-16448973039";
export const SIGNUP_LABEL = "AW-16448973039/a1ZXCLOnsawZEO_RvaM9"
export const ENQUIRY_LABEL = "AW-16448973039/5hNyCIazsawZEO_RvaM9"

export const gender = [
  {
    value: "MALE",
    label: "MALE",
  },
  {
    value: "FEMALE",
    label: "FEMALE",
  },
];