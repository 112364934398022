export const setUserData = ({ token,id, studentId }) => {
  localStorage.setItem("token", token);
  localStorage.setItem("id", id);
  localStorage.setItem("studentId", studentId);
};

export const getUserData = () => {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("id");
  const studentId = localStorage.getItem("studentId");
  return { token, id, studentId };
};

export const removeUserData = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("id");
  localStorage.removeItem("studentId");
};
