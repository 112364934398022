import React, { useEffect } from "react";
import {
  Button,
  Card,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link, useNavigate } from "react-router-dom";
import { updateOrder } from "../../services/paymentOrder";

const Failure = () => {

  const navigate = useNavigate();

  const params = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );

  // if there is need to remove = operator in url
  
  // const key = Object.keys(params);
  // const keyValue = key.toString();

  // var obj = keyValue.split("&").reduce(function (prev, curr, i, arr) {
  //   var p = curr.split("=");
  //   prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
  //   return prev;
  // }, {});

  const order_status = params.order_status;
  const order_id = params.order_id;
  const tracking_id = params.tracking_id;
  const payment_mode = params.payment_mode;
  
  const parseDate = (parseInt(order_id));
  var date = new Date(parseDate); // create Date object
  const newDate = date.toLocaleString();

  const bank_ref_no = params.bank_ref_no;
  const failure_message = params.failure_message;
  const card_name = params.card_name;
  const status_code = params.status_code;
  const status_message = params.status_message;
  const currency = params.currency;
  const billing_name = params.billing_name;
  const billing_address = params.billing_address;
  const billing_city = params.billing_city;
  const billing_state = params.billing_state;
  const billing_zip = params.billing_zip;
  const billing_country = params.billing_country;
  const billing_tel = params.billing_tel;
  const billing_email = params.billing_email;
  const delivery_name = params.delivery_name;
  const delivery_address = params.delivery_address;
  const delivery_city = params.delivery_city;
  const delivery_state = params.delivery_state;
  const delivery_zip = params.delivery_zip;
  const delivery_country = params.delivery_country;
  const delivery_tel = params.delivery_tel;
  const merchant_param1 = params.merchant_param1;
  const merchant_param2 = params.merchant_param2;
  const merchant_param3 = params.merchant_param3;
  const merchant_param4 = params.merchant_param4;
  const merchant_param5 = params.merchant_param5;
  const vault = params.vault;
  const offer_type = params.offer_type;
  const offer_code = params.offer_code;
  const discount_value = params.discount_value;
  const mer_amount = params.mer_amount;
  const eci_value = params.eci_value;
  const retry = params.retry;
  const response_code = params.response_code;
  const billing_notes = params.billing_notes;
  const trans_date = params.trans_date;
  const bin_country = params.bin_country;

  // The prefix has been added in the CheckoutFormRank and CheckoutForm files.
  const paymentIdPrefix = "Your Order Id is ";
  const PaymentID = merchant_param1 && merchant_param1.length > paymentIdPrefix.length ? merchant_param1.substring(paymentIdPrefix.length) : null;

  useEffect(() => {
    PaymentID && 
    updateOrder(PaymentID, {
      tracking_id: tracking_id,
      bank_ref_no: bank_ref_no,
      order_status: order_status,
      failure_message: failure_message,
      payment_mode: payment_mode,
      card_name: card_name,
      status_code: status_code,
      status_message: status_message,
      currency: currency,
      billing_name: billing_name,
      billing_address: billing_address,
      billing_city: billing_city,
      billing_state: billing_state,
      billing_zip: billing_zip,
      billing_country: billing_country,
      billing_tel: billing_tel,
      billing_email: billing_email,
      delivery_name: delivery_name,
      delivery_address: delivery_address,
      delivery_city: delivery_city,
      delivery_state: delivery_state,
      delivery_zip: delivery_zip,
      delivery_country: delivery_country,
      delivery_tel: delivery_tel,
      merchant_param1: merchant_param1,
      merchant_param2: merchant_param2,
      merchant_param3: merchant_param3,
      merchant_param4: merchant_param4,
      merchant_param5: merchant_param5,
      vault: vault,
      offer_type: offer_type,
      offer_code: offer_code,
      discount_value: discount_value,
      mer_amount: mer_amount,
      eci_value: eci_value,
      retry: retry,
      response_code: response_code,
      billing_notes: billing_notes,
      trans_date: trans_date,
      bin_country: bin_country,
    })
      .then((response) => {
        if (!response.data.error) {
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [PaymentID]); 

const handleFailureData = async (event) => {
  event.preventDefault();
    navigate("/");
};

  return (
    <Container maxWidth="xl">
      <Grid
        container
        display="flex"
        justifyContent="center"
        textAlign="center"
        spacing={3}
        sx={{
          width: "calc(100% + 48px)",
          marginTop: "0px",
          paddingBottom: "30px",
          flexGrow: 1,
          backgroundColor: "#F2F2F2",
        }}
      >
        <Card
          sx={{
            margin: "35px 15px 15px 15px",
            padding: "30px 30px 30px",
            width: {
              lg: "400px",
              md: "400px",
            },
          }}
        >
          <CancelIcon sx={{ fontSize: "75px", color: "#ea1212" }} />
          <Typography
            variant="h3"
            component="h2"
            textAlign="center"
            mb={1}
            sx={{
              fontSize: {
                lg: "24px",
                md: "24px",
                sm: "22px",
                xs: "22px",
              },
              fontWeight: 600,
              color: "#565351",
            }}
          >
            Payment Failed!
          </Typography>
          <hr
            style={{
              margin: "30px 0",
              color: "#D3D3D3",
            }}
          />
          <Grid container>
            <Grid item xs={6} sm={6} md={6} lg={6} padding="0 0 5px">
              <Typography
                variant="p"
                component="p"
                sx={{ fontSize: "16px", fontWeight: "600", textAlign: "left" }}
              >
                Order Id
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} padding="0 0 5px">
              <Typography
                variant="p"
                component="p"
                sx={{ fontSize: "16px", fontWeight: "500", textAlign: "right" }}
              >
                {order_id}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} padding="5px 0">
              <Typography
                variant="p"
                component="p"
                sx={{ fontSize: "16px", fontWeight: "600", textAlign: "left" }}
              >
                Order Status
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} padding="5px 0">
              <Typography
                variant="p"
                component="p"
                sx={{ fontSize: "16px", fontWeight: "500", textAlign: "right" }}
              >
                {order_status}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} padding="5px 0">
              <Typography
                variant="p"
                component="p"
                sx={{ fontSize: "16px", fontWeight: "600", textAlign: "left" }}
              >
                Tracking Id
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} padding="5px 0">
              <Typography
                variant="p"
                component="p"
                sx={{ fontSize: "16px", fontWeight: "500", textAlign: "right" }}
              >
                {tracking_id}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} padding="5px 0">
              <Typography
                variant="p"
                component="p"
                sx={{ fontSize: "16px", fontWeight: "600", textAlign: "left" }}
              >
                Payment Method
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} padding="5px 0">
              <Typography
                variant="p"
                component="p"
                sx={{ fontSize: "16px", fontWeight: "500", textAlign: "right" }}
              >
                {payment_mode}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} padding="5px 0 20px">
              <Typography
                variant="p"
                component="p"
                sx={{ fontSize: "16px", fontWeight: "600", textAlign: "left" }}
              >
                Payment Time
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} padding="5px 0 20px">
              <Typography
                variant="p"
                component="p"
                sx={{ fontSize: "16px", fontWeight: "500", textAlign: "right" }}
              >
                {newDate}
              </Typography>
            </Grid>
          </Grid>
          <Grid mt={1} textAlign="center">
            <Link
              style={{
                fontSize: {
                  lg: "18px",
                  md: "18px",
                  sm: "16px",
                  xs: "16px",
                },
                color: "#1f75cb",
                textDecoration: "none",
              }}
            >
              <Button
                variant="contained"
                onClick={handleFailureData}
                sx={{
                  fontSize: {
                    lg: "18px",
                    md: "18px",
                    sm: "16px",
                    xs: "16px",
                  },
                }}
              >
                Continue
              </Button>
            </Link>
          </Grid>
        </Card>
      </Grid>
    </Container>
  );
};

export default Failure;
