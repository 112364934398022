import {useState} from "react";
import image from "../../assets/sliderImg/4.svg";


let shortText = `MBBS Lighthouse in collaboration with NEET Navigator offers India’s most trustworthy NEET UG MBBS admission counselling services.
</br></br>Our E-Counselling services are designed especially for those MBBS aspirants who want support of advanced data analytics to navigate their admission into The Best Medical College.`

let longText = shortText + 
`</br></br>The services include options of non-mentored as well as mentored counselling guidance. The mentors on the platform are well trained and certified by the NEET Navigator, India’s most trusted medical admission counselling services. Those who are looking only for data analytics support, the platform offers non-mentored services.
</br></br>In E-counselling services, the user gets comprehensive support about MBBS admission strategy, counselling registration, college choice list, decision support system and guidance on documents needed for counselling and admission. The E-counselling platform offers a cost effective solution for those who don’t want personalized services.`

export function Expandable() {
    let [expanded, setExpanded] = useState(false);

    let text = expanded ? longText : shortText;

    return (
        <>
            <div className="px-6">
                <p className="text-sm md:text-base text-gray-600 text-left"  dangerouslySetInnerHTML={{__html:text}}></p>
                <button className="text-white text-sm md:text-base lg:text-lg mt-4 border-solid rounded bg-[#1366CF] px-4 py-3 md:py-2 hover:bg-blue-600 hover:ring-2 hover:ring-blue-600 hover:ring-offset-2"
                    onClick={() => setExpanded(!expanded)}>
                        {expanded ? "Read Less ↑" : "Read More →" }
                    </button>
            </div>
        </>
    )
}
export default function WhatMBBSCounselling() {
    return (
        <>
            <div className="flex flex-col items-center mb-8">
                <h2 className="text-xl lg:text-4xl md:text-2xl font-semibold my-8 ml-4">India’s Most Trustworthy MBBS Admission <span className="text-[#1366CF]">E-Counselling</span> Services</h2>
                <div className="flex flex-col items-center space-y-4 lg:flex-row lg:space-x-4  lg:mx-16 mx-4">
                    <img src={image} alt="E-Counselling Banner" className="md:w-1/2"/>
                    <div className="flex items-center">
                        <Expandable />
                    </div>
                </div>
            </div>
        </>
    )
}

