import React, { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Container } from "@mui/material";
import { useState } from "react";
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BuildIcon from '@mui/icons-material/Build';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import DoneIcon from '@mui/icons-material/Done';
const ProgressBar = () => {
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    { id: 1, label: 'Basic details', icon: <HomeIcon />, path: '/domicile' },
    { id: 2, label: 'Choose predictor', icon: <SchoolIcon />, path: '/' },
    { id: 3, label: 'Enter score', icon: <AccountCircleIcon />, path: '/student-rank' },
    { id: 4, label: 'Confirm details', icon: <BuildIcon />, path: '/student-profile' },
    { id: 5, label: 'Select cart', icon: <CheckCircleIcon />, path: '/student-services' },
    { id: 6, label: 'Payment', icon: <LocalLibraryIcon />, path: '/payment' },
    { id: 7, label: 'Report', icon: <DoneIcon />, path: '/college-list' }
  ];

  useEffect(() => {
    const currentPath = location.pathname;
    const step = steps.find(step => step.path === currentPath);
    if (step) {
      setCurrentStep(step.id);
    }
  }, [location, steps]);

  return (
    <Container maxWidth="xl" className="bar-container" style={{position:"relative"}}>
      <div className="progress-bar">
        <div className="main-progress-bar">
          <ul className="ul-pb">
            {steps.map((step, index) => (
              <li
                key={step.id}
                className={`li-pb ${index === 0 ? 'last-li' : ''}`}
              >
                <div className="main-step-pb">
                <div className={`step-pb ${index < currentStep ? 'active' : ''}`}>
                  <div className="icon-pb-top">{step.icon}</div>
                </div>
                </div>
                <p className={`label-pb ${step.id === currentStep ? 'show-label' : ''}`}>{step.label}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Container>
  );
};
export default ProgressBar;
