import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Provider } from 'react-redux';
import { store } from './redux/store';
import applyInterceptor from "./utils/interceptor";
import "./tailwind.css";
import ReactGA from 'react-ga4';
import {IS_DEV_ENV, MEASUREMENT_ID} from './constants/index'

if (IS_DEV_ENV != true) {
  ReactGA.initialize(MEASUREMENT_ID);
}

const theme = createTheme({
  typography: {
    p: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
    },
    a: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
    },
    allVariants: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
);

applyInterceptor();
