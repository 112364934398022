// export const validatePhone = new RegExp(/^(\+91-|\+91|0)?\d{10}$/);
export const validatePhone = new RegExp(/^\d{10}$/);

export const validateFullName = new RegExp(/^[a-zA-Z ]{2,40}$/);

export const validEmail = new RegExp(
  "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
);

export const pinCode = new RegExp(/^[1-9]{1}[0-9]{2}[0-9]{3}$/);


// export const validPassword = new RegExp("^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$");