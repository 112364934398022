import { Typography } from '@mui/material';
import React from 'react'

const YourGpsMbbs = () => {
  return (
    <div>
      <Typography
        variant="h4"
        component="h1"
        mt={2}
        mb={2}
        sx={{
          fontSize: { md: "24px", xs: "18px" },
          fontWeight: "normal",
        }}
      >
        Your GPS to MBBS Admission
      </Typography>
      <Typography
        variant="p"
        component="p"
        mb={2}
        textAlign="justify"
        sx={{ fontSize: { md: "14px", xs: "11px" }, fontWeight: "300" }}
      >
        Expand your horizon of MBBS admissions with MBBS Lighthouse- India’s
        most advanced web app for MBBS admission possibility estimation
      </Typography>
    </div>
  );
}

export default YourGpsMbbs