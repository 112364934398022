import { getAccessToken } from "../services/serviceToken"

const getHeadersFile = () => {
  const accessToken = getAccessToken();
  return {
    "app-access-token": "Bearer " + accessToken,
  };
};

export default getHeadersFile;
